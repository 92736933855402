import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux'


// controllers
// import _actions from './redux/actions';
// import { _log, _timeout } from './Helpers'
import history from './History';


// components
import Loading from '../components/Loading';

// pages
import PageLogin from '../pages/Login';
import PageHome from '../pages/Homepage';
import PageSpotify from '../pages/Spotify';
import PageOffSalles from '../pages/OffSales.js'


// default - public
import Page404 from '../pages/404';
import Page403 from '../pages/403';



// 
// 
function RequireAuth({ children }) {
  const auth  = useSelector(state => state.auth.response) 
  let location = useLocation();

  

  if (!auth) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}


//
//
function AppRouter() {

  // const dispatch = useDispatch()
  // const auth  = useSelector(state => state.auth) 
  const [ connected, setConnected ] = React.useState(false)


  //
  //
  // const _init = async () => {

  //   return true

  // }


  //
  //
  React.useEffect(()=>{

    const getStatus = async () =>{
      // console.log(auth)
      setConnected(true)
    }
    getStatus()

    return (() => [getStatus])
  },[])


  //
  //
  return (
    connected ? 
    <Router basename={'/'} history={history}>
      <Suspense fallback={<Loading status={true}/>}>
            <Routes>

              {/* PUBLIC ROUTES */}
              <Route exact path="/login" element={<PageLogin />}/>

              {/* PRIVATE ROUTES */}
              <Route
                path="/"
                element={
                  <RequireAuth>
                    <PageHome />
                  </RequireAuth>
                }
              />
              <Route
                path="/offsales"
                element={
                  <RequireAuth>
                    <PageOffSalles />
                  </RequireAuth>
                }
              />
              <Route
                path="/spotify"
                element={
                  <RequireAuth>
                    <PageSpotify />
                  </RequireAuth>
                }
              />

              {/* ERROR ROUTES */}
              <Route exact path="/404" element={<Page404 />}/>
              <Route exact path="/403" element={<Page403 />}/>
              <Route path="*" element={<Page404 />}/>

            </Routes>
              
            <ToastContainer
              position="top-right"
            />
      </Suspense>
    </Router>
    :
    <Loading 
      status={true}
    />
  );
}

export default AppRouter;
