import React, { Fragment } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { X, MoreVertical, Menu } from 'react-feather'
import dayjs from 'dayjs';


//
//
export default function Header(props) {

    const location = useLocation()
    const nodeMenu = React.useRef(null);
    const [ isMenuOpen , setIsMenuOpen ] = React.useState(false);
    const [ mobileFilterState, setMobileFilterState ] = React.useState(false)


    //
    //
    const _setMobileFilterState = (state) => {
        setMobileFilterState(state)
        props.mobileStateAction(state)
    }


    //
    //
    const handleClickMenu = async(e) => {
        if (nodeMenu.current.contains(e.target)) {
            return;
        }
        if(isMenuOpen){
            setIsMenuOpen(false);
        }
    };


    //
    //
    React.useEffect(() => {
        document.addEventListener("mousedown", handleClickMenu);
        return () => {
            document.removeEventListener("mousedown", handleClickMenu);
        };
    });


  //
  //
  return (
    <Fragment>
        <div className={`filter ${mobileFilterState ? '' : 'overflow-y-auto md:overflow-visible'}`}>
            <div className='w-full lg:w-40 lg:max-w-[150px] flex flex-row justify-between items-center'>
                <div>
                    <div className={`flex flex-start items-center ${mobileFilterState ? 'pt-0' : 'pt-6'}`}>
                        <div className='relative' ref={nodeMenu}>
                            <button className={`bg-opacity-20 flex justify-end items-center rounded-r-md py-1 mr-2 h-14 w-12 -ml-4 ${isMenuOpen ? 'bg-white' : 'bg-black'}`} type="button" onClick={()=> setIsMenuOpen(!isMenuOpen)}><MoreVertical size={28} className="text-white" /></button>
                            <div className={`menu ${isMenuOpen ? 'active' : ''}`}>
                                <ul>
                                    <li>
                                        <Link to={'/'}>OnSales</Link>
                                    </li>
                                    <li>
                                        <Link to={'/offsales'}>OffSales</Link>
                                    </li>
                                    <li>
                                        <Link to={'/spotify'}>Spotify</Link>
                                    </li>
                                    <li>
                                        <Link to={'/'}>AXS</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className={`logo`}>Rocket<strong>88</strong></div>
                            <div className='text-xs pt-1 uppercase text-white'>{location.pathname === '/' ? 'OnSales' : location.pathname.substr(1)}</div>
                        </div>
                    </div>
                    <hr className={`my-5 opacity-30 ${!mobileFilterState ? 'block' : 'hidden'}`}/>
                    <p className={`text-white text-xxs opacity-50 ${!mobileFilterState ? 'block' : 'hidden'}`}>Copyright &copy; {dayjs().format('YYYY')}</p>
                    <p className={`text-white text-xxs opacity-50 ${!mobileFilterState ? 'block' : 'hidden'}`}>All rights reserved.</p>
                    {props.switchComponent &&
                        <div className={`switch mt-5 lg:mt-11 ${!mobileFilterState ? 'block' : 'hidden'}`}>
                            {props.switchComponent}
                        </div>
                    }
                </div>

                <button className='text-white block lg:hidden' onClick={()=>_setMobileFilterState(!mobileFilterState)}>{mobileFilterState ? <Menu size={28} /> : <X size={28} />}</button>
            </div>
            {props.children}

            <button className={`text-white hidden lg:block ${mobileFilterState ? 'my-2' : 'my-5'}`} onClick={()=>_setMobileFilterState(!mobileFilterState)}>{mobileFilterState ? <Menu size={28} /> : <X size={28} />}</button>
        </div>
    </Fragment>
  );
};
