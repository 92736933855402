import axios from 'axios';
// import {store} from './redux/Store'


export const API = (restricted=true, headers, cancel=null, config={}) => {
  // const user = store.getState();

  let headersConfig = {
    // 'Cache-Control': 'no-cache, no-store, must-revalidate',
    // 'Pragma': 'no-cache',
    // 'Expires': 0,
    // 'Content-Type': 'application/json',
    ...headers
  };
  // if(restricted){
  //   headersConfig = {...headersConfig, 'X_API_KEY': process.env.REACT_APP_X_API_KEY}
  // }

  let axiosConfig = {
    baseURL: process.env.REACT_APP_APIURL2,
    headers: headersConfig,
    cancelToken: cancel?.token,
    ...config
  }

  // console.log('axiosConfig',axiosConfig)

  return axios.create(axiosConfig);
}


export const APICancel = () => {
  return axios.CancelToken.source()
}



//
//
export const _apiPOST = async (route, data, restricted=true, header={}, config={}) =>{

  const cancel = APICancel()

  return await API(restricted, header, cancel, config)
  .post(route, data)
  .then(response => {
    return response.data
  }).catch(error => {
    return {error: error};
  });

}



//
//
export const _apiPUT = async (route, data, restricted=true, header={}, config={}) =>{

  const cancel = APICancel()

  return await API(restricted, header, cancel, config)
  .put(route, data)
  .then(response => {
    return response.data
  }).catch(error => {
    return {error: error};
  });

}



//
//
export const _apiPATCH = async (route, data, restricted=true, header={}, config={}) =>{

  const cancel = APICancel()

  return await API(restricted, header, cancel, config)
  .patch(route, data)
  .then(response => {
    return response.data
  }).catch(error => {
    return {error: error};
  });

}



//
//
export const _apiDELETE = async (route, data={}, header={}, restricted=true, config={}) =>{

  const cancel = APICancel()

  return await API(restricted, header, cancel, config)
  .delete(route, data)
  .then(response => {
    return response.data
  }).catch(error => {
    return {error: error};
  });

}


//
//
export const _apiGETone = async (route, header={}, restricted=true, config={}) =>{

  const cancel = APICancel()

  return await API(restricted, header, cancel, config)
  .get(route)
  .then(response => {
    return response.data
  }).catch(error => {
    return {error: error};
  });

}


//
//
export const _apiGETmulti = async (route, data, restricted=true, config={}) =>{

  const cancel = APICancel()

  return await API(restricted, {
    'X-Meta': JSON.stringify(data)
  }, cancel, config)
  .get(route)
  .then(response => {
    return response.data
  }).catch(error => {
    return {error: error};
  });

}