import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async'
import { useNavigate } from "react-router-dom";
import dayjs from 'dayjs';
import { X, Loader, ExternalLink, MoreVertical, Menu, ArrowUp, ArrowDown, ArrowUpRight, Star } from 'react-feather'
import DataTable from 'react-data-table-component';
import 'react-dates/initialize'
import { DateRangePicker } from 'react-dates';
import Hotkeys from 'react-hot-keys'
import { MeiliSearch } from 'meilisearch'
import moment from 'moment';


// controllers
import useQuery from '../controllers/hooks/useQuery';
import { _apiPUT, _apiPOST, _apiGETone, _apiPATCH } from '../controllers/Api'
import { _toaster, _pad, _timeout, _calcChangeValue, _calcChangePercent, _rank, _updateMin, _updateMax } from '../controllers/Helpers'


// components
import Layout from '../components/Layout'
import Header from '../components/Header'
import Modal from '../components/Modal'
import DetailsOnSale from '../components/Details/onSale';
import FormMultiSelect from '../components/Forms/multiSelect';
import Settings from '../components/Settings/offsale';


// dayjs
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
const weekday = require('dayjs/plugin/weekday')
dayjs.extend(weekday)
const advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)


export default function Offsales(props){

    const venueCapacityMaxValue = 250000
    const [ initDate, setInitDate ] = React.useState(dayjs()) 

    const [ reload,setReload ] = React.useState(1)

    const defaultRequestData = {
        attributesToHighlight: ['*'],
        facets: ["venue_name","venue_city","venue_state"],
        filter:['sold_out_GA = "false"', 'is_sold_out = "false"', "event_date_time >= "+dayjs().endOf('day').unix()],
        limit:20,
        offset:0,
        // sort: ['sale_start_date_time:asc']
    }
    const query = useQuery();
    const navigate = useNavigate()
    const mounted1 = React.useRef(null);
    const [ requestInit1, setRequestInit1 ] = React.useState(0);

    const [ isLoading, setLoading ] = React.useState(false)
    const [ mobileFilterState, setMobileFilterState ] = React.useState(false)

    const [ requestSearch, setRequestSearch ] = React.useState('')
    const [ requestData, setRequestData ] = React.useState(defaultRequestData)

    const [ responseData, setResponseData ] = React.useState([]);
    const [ totalResults, setTotalResults ] = React.useState(0)
    const [ filterData, setFilterData] = React.useState({})
    const [ filterDataCounts, setFilterDataCounts] = React.useState({})

    const [ page, setPage ] = React.useState(1);

    const [ isDetailsModalOpen, setDetailsModalIsOpen ] = React.useState(null)

    const [ dateStart, setDateStart ] = React.useState(null)
    const [ dateEnd, setDateEnd ] = React.useState(null)
    const [ focusedDateInput, setFocusedDateInput ] = React.useState(null)

    const [ venueCapacityMin, setVenueCapacityMin ] = React.useState('')
    const [ venueCapacityMax, setVenueCapacityMax ] = React.useState('')

    const [ capRemainingMin, setCapRemainingMin ] = React.useState('')
    const [ capRemainingMax, setCapRemainingMax ] = React.useState('')

    const [ capChange1Min, setCapChange1Min ] = React.useState('')
    const [ capChange1Max, setCapChange1Max ] = React.useState('')

    const [ capChange7Min, setCapChange7Min ] = React.useState('')
    const [ capChange7Max, setCapChange7Max ] = React.useState('')

    const [ capChange14Min, setCapChange14Min ] = React.useState('')
    const [ capChange14Max, setCapChange14Max ] = React.useState('')

    const [ capChange30Min, setCapChange30Min ] = React.useState('')
    const [ capChange30Max, setCapChange30Max ] = React.useState('')

    const [ pace5Min, setPace5Min ] = React.useState('')
    const [ pace5Max, setPace5Max ] = React.useState('')

    const [ pace7Min, setPace7Min ] = React.useState('')
    const [ pace7Max, setPace7Max ] = React.useState('')

    const [ pace10Min, setPace10Min ] = React.useState('')
    const [ pace10Max, setPace10Max ] = React.useState('')

    const [ pace30Min, setPace30Min ] = React.useState('')
    const [ pace30Max, setPace30Max ] = React.useState('')

    const [ spotifyFollowersMin, setSpotifyFollowersMin ] = React.useState('')
    const [ spotifyFollowersMax, setSpotifyFollowersMax ] = React.useState('')

    const [ spotify30dMin, setSpotify30dMin ] = React.useState('')
    const [ spotify30dMax, setSpotify30dMax ] = React.useState('')

    const [ spotify90dMin, setSpotify90dMin ] = React.useState('')
    const [ spotify90dMax, setSpotify90dMax ] = React.useState('')

    const [ soldOutMin, setSoldOutMin ] = React.useState('')
    const [ soldOutMax, setSoldOutMax ] = React.useState('')

    const [ showFavorites, setShowFavorites ] = React.useState(false);
    const [ showSoldOuts, setSoldOuts ] = React.useState(true);
    const [ showSoldOutsGA, setSoldOutsGA ] = React.useState(true);
    const [ showPastEvents, setPastEvents ] = React.useState({status:true, date:dayjs().endOf('day')});


    const client = new MeiliSearch({
        host: process.env.REACT_APP_APIURL_OFFSALE,
        apiKey: process.env.REACT_APP_APIKEY_OFFSALE
    })



    //
    // 
    const ExpandedComponent = ({ data }) => {
        return (
            <div className='bg-black bg-opacity-10'>
            <div className='ml-12 py-4'>{data.sales_name}</div>
            <ul className='flex flex-row justify-start items-center gap-5 ml-12'>
                <li>
                    <button className='text-xs py-4 text-gray-500 hover:text-purple-500 uppercase flex items-center' onClick={()=>setDetailsModalIsOpen(data)}><MoreVertical size={16} className="mr-1"/>Details</button>
                </li>
                <li>
                    <button type="button" className='text-xs py-4 text-gray-500 hover:text-purple-500 uppercase flex items-center'><Star size={16} className="mr-1"/>{data.favorite ? 'Remove from favourite' : 'Add to favourite'}</button>
                </li>
                <li>
                    <a href={data.url} target="_blank" rel="noreferrer" className='text-xs py-4 text-gray-500 hover:text-purple-500 uppercase flex items-center'><ExternalLink size={16} className='mr-1'/>Open link</a>
                </li>
            </ul>
            </div>
        )
    }


    // 
    // 
    const updateState = React.useCallback(state => console.log(state), [responseData]);


    // 
    // table configuration
    const columns = React.useMemo(() => {


        // 
        // 
        const _favouriteAdd = async(event_id, sale_id) => {

            setLoading(true)

            await client.index('offsales').updateDocuments([{
                event_id: event_id,
                favorite: true
            }])

            const add = await _apiPOST('/toggle_event_favourite', {event_id:event_id})
            if(add.error){
                _toaster('Error', 'Detected error in your request, try again later.', 'error')
                // return
            }

            await _timeout(1500)

            _toaster('Success', 'Event added to favorites successfully', 'success')

            // let newArray = [...responseData]
            // const index = newArray.findIndex(x => x.event_id===id)
            // if(index !== -1){
            //     newArray[index].favorite = true
            // }
            // setResponseData(newArray)

            setReload(x => x+1)
            return
        }


        // 
        // 
        const _favouriteDelete = async(event_id, sale_id) => {

            setLoading(true)

            await client.index('offsales').updateDocuments([{
                event_id: event_id,
                favorite: false
            }])

            const remove = await _apiPOST('/toggle_event_favourite', {event_id:event_id})
            if(remove.error){
                _toaster('Error', 'Detected error in your request, try again later.', 'error')
                // return
            }

            await _timeout(1500)

            _toaster('Success', 'Event removed from favorites successfully', 'success')

            // let newArray = [...responseData]
            // const index = newArray.findIndex(x => x.event_id===id)
            // if(index !== -1){
            //     newArray[index].favorite = false
            // }
            // setResponseData(newArray)

            setReload(x => x+1)
            return
        }


        // 
        // 
        const _favoriteButton = (event_id, sale_id, hasFavorite) => {
            return <button className={`w-8 h-8 flex justify-center items-center rounded-full ${!hasFavorite ? 'text-gray-300 ' : 'text-purple-500'}`} onClick={()=> hasFavorite ? _favouriteDelete(event_id, sale_id) : _favouriteAdd(event_id, sale_id)}><Star size={20}/></button>
        }


        //
        //
        Number.prototype.format = function(n, x) {
            var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
            return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
        };


        return [
            {
                name: <Star size={20} className=""/>,
                selector: row => _favoriteButton(row.event_id, row.sale_id, row.favorite),
                field: 'favorite',
                sortable: false,
                reorder: false,
                button: true,
                minWidth:'50px',
                maxWidth:'50px',
                style: {position:'sticky', left:'0', background:'#fff', zIndex:8}
            },
            {
                name: 'Event name',
                selector: row => <button className='font-medium cursor-pointer text-purple-500' onClick={()=>setDetailsModalIsOpen(row)}>{row.name}</button>,
                field: 'name',
                sortable: true,
                reorder: false,
                minWidth:'400px',
                maxWidth:'400px',
                style: {position:'sticky', left:'50px', background:'#fff', zIndex:8}
            },
            {
                name: 'Venue',
                selector: row => <a href={'/offsales/?filter='+JSON.stringify({...requestData, filter:['venue_name="'+row.venue_name+'"']})} target="_blank" rel='noreferrer' className='flex flex-row items-center '>{row.venue_name} <ArrowUpRight size={16} className='text-purple-500 ml-1'/></a>,
                field: 'venue_name',
                sortable: true,
                reorder: true,
                minWidth:'240px',
                maxWidth:'240px',
                style: {position:'sticky', left:'400px', background:'#fff', zIndex:8}
            },
            {
                name: 'Days',
                selector: row => <>{dayjs.unix(row.event_date_time_2).diff(dayjs(), 'day')}</>,
                field: 'event_date_time_2',
                sortable: true,
                reorder: true,
                minWidth:'100px',
                maxWidth:'100px',
                style: {position:'sticky', left:'640px', background:'#fff', zIndex:8}
            },
            {
                name: 'Event Date',
                selector: row => dayjs.unix(row.event_date_time).format('MM/DD/YYYY hh:mm A'),
                field: 'event_date_time',
                sortField: 'event_date_time',
                sortable: true,
                reorder: false,
                minWidth:'170px',
                maxWidth:'170px'
            },
            {
                name: 'City',
                selector: row => row.venue_city,
                field: 'venue_city',
                sortable: true,
                reorder: true,
                minWidth:'150px',
                maxWidth:'150px'
            },
            {
                name: 'State',
                selector: row => row.venue_state,
                field: 'venue_state',
                sortable: true,
                reorder: true,
                minWidth:'80px',
                maxWidth:'80px'
            },
            {
                name: 'Cap',
                selector: row => row.venue_capacity ? row.venue_capacity.format() : 0,
                field: 'venue_capacity',
                sortable: true,
                reorder: true,
                minWidth:'100px',
                maxWidth:'100px'
            },
            {
                name: '∑ Cap',
                selector: row => {
                    const value = parseFloat(row.capacity_remaining)
                    if(value){
                        return <span>{Math.round(value*100)}%</span>
                    }
                    return ''
                },
                field: 'capacity_remaining',
                sortable: true,
                reorder: true,
                minWidth:'100px',
                maxWidth:'100px'
            },
            {
                name: 'Σ Left',
                selector: row => row.total_remaining,
                field: 'total_remaining',
                sortable: true,
                reorder: true,
                minWidth:'100px',
                maxWidth:'100px'
            },
            {
                name: '30d Pace %',
                selector: row => {
                    const value = parseFloat(row.pace_percent_30)
                    return _calcChangePercent(value)
                },
                field: 'pace_percent_30',
                sortable: true,
                reorder: true,
                minWidth:'120px',
                maxWidth:'120px'
            },
            {
                name: '7d Pace %',
                selector: row => {
                    const value = parseFloat(row.pace_percent_7)
                    return _calcChangePercent(value)
                },
                field: 'pace_percent_7',
                sortable: true,
                reorder: true,
                minWidth:'120px',
                maxWidth:'120px'
            },
            {
                name: '|30dPace|',
                selector: row => {
                    const value = parseInt(row.pace_30)
                    return _calcChangeValue(value)
                },
                field: 'pace_30',
                sortable: true,
                reorder: true,
                minWidth:'120px',
                maxWidth:'120px'
            },
            {
                name: '|15dPace|',
                selector: row => {
                    const value = parseInt(row.pace_15)
                    return _calcChangeValue(value)
                },
                field: 'pace_15',
                sortable: true,
                reorder: true,
                minWidth:'120px',
                maxWidth:'120px'
            },
            {
                name: '|7dPace|',
                selector: row => {
                    const value = parseInt(row.pace_7)
                    return _calcChangeValue(value)
                },
                field: 'pace_7',
                sortable: true,
                reorder: true,
                minWidth:'120px',
                maxWidth:'120px'
            },
            {
                name: 'CapΔ 30',
                selector: row => {
                    const value = parseFloat(row.percent_capity_change_30)
                    return _calcChangePercent(value)
                },
                field: 'percent_capity_change_30',
                sortable: true,
                reorder: true,
                minWidth:'120px',
                maxWidth:'120px'
            },
            {
                name: 'CapΔ 14',
                selector: row => {
                    const value = parseFloat(row.percent_capity_change_14)
                    return _calcChangePercent(value)
                },
                field: 'percent_capity_change_14',
                sortable: true,
                reorder: true,
                minWidth:'120px',
                maxWidth:'120px'
            },
            {
                name: 'CapΔ 7',
                selector: row => {
                    const value = parseFloat(row.percent_capity_change_7)
                    return _calcChangePercent(value)
                },
                field: 'percent_capity_change_7',
                sortable: true,
                reorder: true,
                minWidth:'120px',
                maxWidth:'120px'
            },
            {
                name: '30d μ',
                selector: row => {
                    const value = parseInt(row.avg_change_30)
                    return _calcChangeValue(value)
                },
                field: 'avg_change_30',
                sortable: true,
                reorder: true,
                minWidth:'100px',
                maxWidth:'100px'
            },
            {
                name: '7d μ',
                selector: row => {
                    const value = parseInt(row.avg_change_7)
                    return _calcChangeValue(value)
                },
                field: 'avg_change_7',
                sortable: true,
                reorder: true,
                minWidth:'100px',
                maxWidth:'100px'
            },
            {
                name: '|Day Δ|',
                selector: row => {
                    const value = parseInt(row.absolute_change_daily)
                    return _calcChangeValue(value)
                },
                field: 'absolute_change_daily',
                sortable: true,
                reorder: true,
                minWidth:'100px',
                maxWidth:'100px'
            },
            {
                name: '|30d Δ|',
                selector: row => {
                    const value = parseInt(row.absolute_change_30)
                    return _calcChangeValue(value)
                },
                field: 'absolute_change_30',
                sortable: true,
                reorder: true,
                minWidth:'100px',
                maxWidth:'100px'
            },
            {
                name: '|7d Δ|',
                selector: row => {
                    const value = parseInt(row.absolute_change_weekly)
                    return _calcChangeValue(value)
                },
                field: 'absolute_change_weekly',
                sortable: true,
                reorder: true,
                minWidth:'100px',
                maxWidth:'100px'
            },
            // {
            //     name: 'CapΔ 1',
            //     selector: row => {
            //         const value = parseFloat(row.percent_capity_change_1)
            //         return _calcChangePercent(value)
            //     },
            //     field: 'percent_capity_change_1',
            //     sortable: true,
            //     reorder: true,
            //     minWidth:'120px',
            //     maxWidth:'120px'
            // },
            {
                name: 'Σ GA',
                selector: row => row.total_GA_remaining,
                field: 'total_GA_remaining',
                sortable: true,
                reorder: true,
                minWidth:'100px',
                maxWidth:'100px'
            },
            {
                name: 'Σ RES',
                selector: row => row.total_reserved_remaining,
                field: 'total_reserved_remaining',
                sortable: true,
                reorder: true,
                minWidth:'100px',
                maxWidth:'100px'
            },
            {
                name: 'Σ PIT',
                selector: row => row.total_general_pit_remaining,
                field: 'total_general_pit_remaining',
                sortable: true,
                reorder: true,
                minWidth:'100px',
                maxWidth:'100px'
            },
            {
                name: 'Σ LAWN',
                selector: row => row.total_general_lawn_remaining,
                field: 'total_general_lawn_remaining',
                sortable: true,
                reorder: true,
                minWidth:'100px',
                maxWidth:'100px'
            },
            {
                name: 'Σ GA FLOOR',
                selector: row => row.total_general_floor_remaining,
                field: 'total_general_floor_remaining',
                sortable: true,
                reorder: true,
                minWidth:'120px',
                maxWidth:'120px'
            },
            {
                name: 'Min $GA',
                selector: row => {
                    const value = parseFloat(row.min_GA_price)
                    return '$'+value.format(2)
                },
                field: 'min_GA_price',
                sortable: true,
                reorder: true,
                minWidth:'120px',
                maxWidth:'120px'
            },
            {
                name: '$∆ Week',
                selector: row => {
                    const value = parseFloat(row.ppi_weekly)
                    return _calcChangePercent(value)
                },
                field: 'ppi_weekly',
                sortable: true,
                reorder: true,
                minWidth:'100px',
                maxWidth:'100px'
            },
            {
                name: 'Σ Spot',
                selector: row => {
                    const value = parseInt(row.spotify_total_followers)
                    return value ? value.format() : ''
                },
                field: 'spotify_total_followers',
                sortable: true,
                reorder: true,
                minWidth:'120px',
                maxWidth:'120px'
            },
            {
                name: 'FCR',
                selector: row => {
                    const value = parseInt(row.follower_cap_ratio)
                    return value ? value.format() : ''
                },
                field: 'follower_cap_ratio',
                sortable: true,
                reorder: true,
                minWidth:'120px',
                maxWidth:'120px'
            },
            {
                name: '30d Spot',
                selector: row => {
                    const value = parseFloat(row.spotify_30day_growth)
                    return _calcChangePercent(value)
                },
                field: 'spotify_30day_growth',
                sortable: true,
                reorder: true,
                minWidth:'100px',
                maxWidth:'100px'
            },
            {
                name: '90d Spot',
                selector: row => {
                    const value = parseFloat(row.spotify_90day_growth)
                    return _calcChangePercent(value)
                },
                field: 'spotify_90day_growth',
                sortable: true,
                reorder: true,
                minWidth:'100px',
                maxWidth:'100px'
            },
            {
                name: 'Last Pull',
                selector: row => (row.last_offsale_pull) ? dayjs.unix(row.last_offsale_pull).format('MM/DD/YYYY hh:mm A') : <></>,
                field: 'last_offsale_pull',
                sortField: 'last_offsale_pull',
                sortable: true,
                reorder: false,
                minWidth:'170px',
                maxWidth:'170px'
            }
        ]
    }, []);


    // 
    // 
    const onKeyUp = (keyName, e, handle) => {

        const htmlElement = document.getElementsByClassName('tableRef')[0].children[0].children[0];

        if(keyName==='alt+right' && htmlElement){
            htmlElement.scrollIntoView({behavior:'smooth', block: "nearest", inline: "end"})
        }
        if(keyName==='alt+left' && htmlElement){
            htmlElement.scrollIntoView({behavior:'smooth', block: "nearest", inline: "start"})
        }
        if(keyName==='esc'){
            setDetailsModalIsOpen(null)
        }

    }


    //
    //
    const _filterAND = async(field, value, array) => {

        let newFields = array
        const findIndex = newFields.findIndex(x => {
            console.log('findIndex',x.search(field))
            return x.indexOf(field) !== -1
        })
        if(findIndex !== -1){

            const isEqual = newFields.find(x => {

                console.log('isEqual',x.indexOf(field+'="'+value+'"'), field+'="'+value+'"', x)
                return x.indexOf(field+'="'+value+'"') !== -1
            })
            if(isEqual){

                // remove if is equal to current selection
                let newFieldsOR = newFields[findIndex].indexOf(' OR ') !== -1 ? newFields[findIndex].split(' OR ') : [array[findIndex]]
                const isEqualOR = newFieldsOR.find(x => x.indexOf(field+'="'+value+'"') !== -1)
                if(isEqualOR){

                    // remove if is equal to current selection in same field name
                    newFields = newFields.filter(x => x.indexOf(field) === -1)
                    newFieldsOR = newFieldsOR.filter(x => x.indexOf(field+'="'+value+'"') === -1)
                    if(newFieldsOR.length>0){
                        newFields.push(newFieldsOR.join(' OR '))
                    }

                }else{

                    // add new to OR array
                    newFields = newFields.filter(x => x.indexOf(field) === -1)
                    newFieldsOR.push(field+'="'+value+'"')
                    newFields.push(newFieldsOR.join(' OR '))

                }

            }else{

                let newFieldsOR = newFields[findIndex].indexOf(' OR ') !== -1 ? newFields[findIndex].split(' OR ') : [array[findIndex]]
                const isEqualOR = newFieldsOR.find(x => x.indexOf(field+'="'+value+'"') !== -1)
                if(isEqualOR){

                    // remove if is equal to current selection in same fineld name
                    newFieldsOR = newFieldsOR.filter(x => x.indexOf(field+'="'+value+'"') === -1)

                }else{

                    // add new to OR array
                    newFields = newFields.filter(x => x.indexOf(field) === -1)
                    newFieldsOR.push(field+'="'+value+'"')
                    newFields.push(newFieldsOR.join(' OR '))

                }

            }

        }else{

            // if field not fount in current filter
            newFields.push(field+'="'+value+'"')

        }

        return newFields

    }





    //
    //
    const _filterOR = async(field, value, array) => {

        let newFields = array
        const findIndex = newFields.findIndex(x => x.search(field) !== -1)
        if(findIndex !== -1){

            const isEqual = newFields.find(x => x.search(field+'="'+value+'"') !== -1)
            
            if(isEqual){

                // remove if is equal to current selection
                let newFieldsOR = newFields[findIndex].search(' OR ') !== -1 ? newFields[findIndex].split(' OR ') : [array[findIndex]]
                const isEqualOR = newFieldsOR.find(x => x.search(field+'="'+value+'"') !== -1)
                if(isEqualOR){

                    // remove if is equal to current selection in same field name
                    newFields = newFields.filter(x => x.search(field) === -1)
                    newFieldsOR = newFieldsOR.filter(x => x.search(field+'="'+value+'"') === -1)
                    if(newFieldsOR.length>0){
                        newFields.push(newFieldsOR.join(' OR '))
                    }

                }else{

                    // add new to OR array
                    newFields = newFields.filter(x => x.search(field) === -1)
                    newFieldsOR.push(field+'="'+value+'"')
                    newFields.push(newFieldsOR.join(' OR '))

                }

            }else{

                let newFieldsOR = newFields[findIndex].search(' OR ') !== -1 ? newFields[findIndex].split(' OR ') : [array[findIndex]]
                const isEqualOR = newFieldsOR.find(x => x.search(field+'="'+value+'"') !== -1)
                if(isEqualOR){

                    // remove if is equal to current selection in same fineld name
                    newFieldsOR = newFieldsOR.filter(x => x.search(field+'="'+value+'"') === -1)

                }else{

                    // add new to OR array
                    newFields = newFields.filter(x => x.search(field) === -1)
                    newFieldsOR.push(field+'="'+value+'"')
                    newFields.push(newFieldsOR.join(' OR '))

                }

            }

        }else{

            // if field not fount in current filter
            newFields.push(field+'="'+value+'"')

        }

        return newFields

    }


    //
    // update state on change value in field
    const _onFieldChange = async(field, value) => {
        if(value==='none'){
            _resetFields(field)
            return
        }

        let newData = {...requestData}
        let filter = newData.filter
        let newFieldsANDfinal = []
        let newFieldsORfinal = []


        if(!filter || filter.length===0){

            // newFields.push(field+'="'+value+'"')
            newData.filter = [field+'="'+value+'"']
            newData.offset = parseInt(0)
            setPage(1)
            setRequestData(newData)
            return

        }


        if(filter && filter.length !== 0){
            console.log(filter)
            for (let i = 0; i < filter.length; i++) {
                const filterItem = filter[i];
                if(Array.isArray(filterItem)){
                    newFieldsORfinal.push(...filterItem)
                }else{
                    newFieldsANDfinal.push(filterItem)
                }
            }

            

            // newFields = filter
            // const findIndex = newFields.findIndex(x => x.search(field) !== -1)
            // if(findIndex !== -1){

            //     const isEqual = newFields.find(x => x.search(field+'="'+value+'"') !== -1)
            //     if(isEqual){

            //         // remove if is equal to current selection
            //         let newFieldsOR = newFields[findIndex].search(' OR ') !== -1 ? newFields[findIndex].split(' OR ') : [filter[findIndex]]
            //         const isEqualOR = newFieldsOR.find(x => x.search(field+'="'+value+'"') !== -1)
            //         if(isEqualOR){

            //             // remove if is equal to current selection in same field name
            //             newFields = newFields.filter(x => x.search(field) === -1)
            //             newFieldsOR = newFieldsOR.filter(x => x.search(field+'="'+value+'"') === -1)
            //             if(newFieldsOR.length>0){
            //                 newFields.push(newFieldsOR.join(' OR '))
            //             }

            //         }else{

            //             // add new to OR array
            //             newFields = newFields.filter(x => x.search(field) === -1)
            //             newFieldsOR.push(field+'="'+value+'"')
            //             newFields.push(newFieldsOR.join(' OR '))

            //         }

            //     }else{

            //         let newFieldsOR = newFields[findIndex].search(' OR ') !== -1 ? newFields[findIndex].split(' OR ') : [filter[findIndex]]
            //         const isEqualOR = newFieldsOR.find(x => x.search(field+'="'+value+'"') !== -1)
            //         if(isEqualOR){

            //             // remove if is equal to current selection in same fineld name
            //             newFieldsOR = newFieldsOR.filter(x => x.search(field+'="'+value+'"') === -1)

            //         }else{

            //             // add new to OR array
            //             newFields = newFields.filter(x => x.search(field) === -1)
            //             newFieldsOR.push(field+'="'+value+'"')
            //             newFields.push(newFieldsOR.join(' OR '))

            //         }

            //     }

            // }else{

            //     // if field not fount in current filter
            //     newFields.push(field+'="'+value+'"')

            // }

            let _AND = await _filterAND(field,value,newFieldsANDfinal)
            // let _OR  = await _filterAND(field,value,newFieldsORfinal)

            // return....
            console.log('# FINAL',_AND)
            if(_AND.length>0){
                newData.filter = [..._AND]
            }else{
                delete newData.filter
            }
            newData.offset = parseInt(0)
            setPage(1)
            setRequestData(newData)
            return

        }

    }


    //
    // check is field used in filter
    const _isFieldUsed = (field) => {

        if(field==='search'){
            return requestSearch==='' ? false : true
        }

        if(field==='dateStart'){
            return dateStart ? true : false
        }

        if(field==='dateEnd'){
            return dateEnd ? true : false
        }

        if(!requestData.filter && requestData.filter.length !== 0){
            return false;
        }
        
        const find = requestData.filter.filter(x => x.indexOf(field) !== -1)
        if(find.length===0){
            return false;
        }

        return true;
    }


    //
    // reset fields by all or specific field
    const _resetFields = (field) => {

        let newData = {...requestData}
        
        if(field==='all'){
            // delete newData.filter
            // delete newData.sort
            // setRequestData(newData)
            // setRequestSearch('')
            // setDateStart(null)
            // setDateEnd(null)
            // // setVenueCapacity([0,venueCapacityMax])
            // setVenueCapacityMin('')
            // setVenueCapacityMax('')
            window.location.reload(false)
            return
        }

        if(field==='search'){
            setRequestSearch('')
        }

        // if(field==='sale_start_date_time'){
        //     setDateStart(null)
        //     setDateEnd(null)
        // }

        if(field==='venue_capacity'){
            setVenueCapacityMin('')
            setVenueCapacityMax('')
        }

        if(field==='capacity_remaining'){
            setCapRemainingMin('')
            setCapRemainingMax('')
        }

        if(field==='percent_capity_change_1'){
            setCapChange1Min('')
            setCapChange1Max('')
        }
        if(field==='percent_capity_change_7'){
            setCapChange7Min('')
            setCapChange7Max('')
        }
        if(field==='percent_capity_change_14'){
            setCapChange14Min('')
            setCapChange14Max('')
        }
        if(field==='percent_capity_change_30'){
            setCapChange30Min('')
            setCapChange30Max('')
        }

        if(field==='pace_5'){
            setPace5Min('')
            setPace5Max('')
        }
        if(field==='pace_7'){
            setPace7Min('')
            setPace7Max('')
        }
        if(field==='pace_10'){
            setPace10Min('')
            setPace10Max('')
        }
        if(field==='pace_30'){
            setPace30Min('')
            setPace30Max('')
        }


        if(field==='spotify_total_followers'){
            setSpotifyFollowersMin('')
            setSpotifyFollowersMax('')
        }
        if(field==='spotify_30day_growth'){
            setSpotify30dMin('')
            setSpotify30dMax('')
        }
        if(field==='spotify_90day_growth'){
            setSpotify90dMin('')
            setSpotify90dMax('')
        }
        if(field==='event_date_time_2'){
            setSoldOutMin('')
            setSoldOutMax('')
        }


        const find = newData.filter.filter(x => { if(x.indexOf(field) === -1){ return x }})
        newData.filter = find
        newData.offset = parseInt(0)
        setPage(1) 
        setRequestData(newData)
        
    }


    //
    //  sorting action
    const _handleSort = (column, sortDirection) => {
        let newData = {...requestData}
        newData.sort = [columns[column.id-1].field+':'+sortDirection]
        setRequestData(newData)
    }

    //
    // pagination action
    const _handlePageChange = (page, totalRows) => {
      let newRequest = {...requestData}
          newRequest.offset = (page-1)*requestData.limit
      setRequestData(newRequest);
      setPage(page)
    };


    //
    // changing limit per page action
	const _handlePerRowsChange = async (newPerPage, page) => {
		let newRequest = {...requestData}
        newRequest.limit = newPerPage
        newRequest.offset = 0
		setRequestData(newRequest);
	};


    //
    //
    const _isResetVisible = () => {
        if(requestData.filter && requestData.filter.length>0){
            if(requestData.filter.toString()===['sold_out_GA = "false"', 'is_sold_out = "false"', "event_date_time >= "+dayjs().endOf('day').unix()].toString()){
                return false
            }
            return true
        }
        if(requestSearch !== ''){
            return true
        }
        return false
    }


    //
    //
    const _updateRequestByVenueCapacityMin = (value) => {

        if(value){
            setVenueCapacityMin(value)
        }else{
            setVenueCapacityMin('')
        }

        const prepare = _updateMin('venue_capacity',value,venueCapacityMax,requestData)
        let newData = {...prepare}
        newData.offset = parseInt(0)
        setPage(1) 
        setRequestData(newData)

    }
    const _updateRequestByVenueCapacityMax = (value) => {

        if(value){
            setVenueCapacityMax(value)
        }else{
            setVenueCapacityMax('')
        }

        const prepare = _updateMax('venue_capacity',value,venueCapacityMin,requestData)
        let newData = {...prepare}
        newData.offset = parseInt(0)
        setPage(1) 
        setRequestData(newData)

    }


    //
    //
    const _updateRequestByCapRemainingMin = (value) => {

        if(value){
            setCapRemainingMin(value)
        }else{
            setCapRemainingMin('')
        }

        const prepare = _updateMin('capacity_remaining',value,capRemainingMax,requestData)
        let newData = {...prepare}
        newData.offset = parseInt(0)
        setPage(1) 
        setRequestData(newData)

    }
    const _updateRequestByCapRemainingMax = (value) => {

        if(value){
            setCapRemainingMax(value)
        }else{
            setCapRemainingMax('')
        }

        const prepare = _updateMax('capacity_remaining',value,capRemainingMin,requestData)
        let newData = {...prepare}
        newData.offset = parseInt(0)
        setPage(1) 
        setRequestData(newData)

    }


    //
    //
    const _updateRequestByCapChange1Min = (value) => {

        if(value){
            setCapChange1Min(value)
        }else{
            setCapChange1Min('')
        }

        const prepare = _updateMin('percent_capity_change_1',value,capChange1Max,requestData)
        let newData = {...prepare}
        newData.offset = parseInt(0)
        setPage(1) 
        setRequestData(newData)

    }
    const _updateRequestByCapChange1Max = (value) => {

        if(value){
            setCapChange1Max(value)
        }else{
            setCapChange1Max('')
        }

        const prepare = _updateMax('percent_capity_change_1',value,capChange1Min,requestData)
        let newData = {...prepare}
        newData.offset = parseInt(0)
        setPage(1) 
        setRequestData(newData)

    }
    const _updateRequestByCapChange7Min = (value) => {

        if(value){
            setCapChange7Min(value)
        }else{
            setCapChange7Min('')
        }

        const prepare = _updateMin('percent_capity_change_7',value,capChange7Max,requestData)
        let newData = {...prepare}
        newData.offset = parseInt(0)
        setPage(1) 
        setRequestData(newData)

    }
    const _updateRequestByCapChange7Max = (value) => {

        if(value){
            setCapChange7Max(value)
        }else{
            setCapChange7Max('')
        }

        const prepare = _updateMax('percent_capity_change_7',value,capChange7Min,requestData)
        let newData = {...prepare}
        newData.offset = parseInt(0)
        setPage(1) 
        setRequestData(newData)

    }
    const _updateRequestByCapChange14Min = (value) => {

        if(value){
            setCapChange14Min(value)
        }else{
            setCapChange14Min('')
        }

        const prepare = _updateMin('percent_capity_change_14',value,capChange14Max,requestData)
        let newData = {...prepare}
        newData.offset = parseInt(0)
        setPage(1) 
        setRequestData(newData)

    }
    const _updateRequestByCapChange14Max = (value) => {

        if(value){
            setCapChange14Max(value)
        }else{
            setCapChange14Max('')
        }

        const prepare = _updateMax('percent_capity_change_14',value,capChange14Min,requestData)
        let newData = {...prepare}
        newData.offset = parseInt(0)
        setPage(1) 
        setRequestData(newData)

    }
    const _updateRequestByCapChange30Min = (value) => {

        if(value){
            setCapChange30Min(value)
        }else{
            setCapChange30Min('')
        }

        const prepare = _updateMin('percent_capity_change_30',value,capChange30Max,requestData)
        let newData = {...prepare}
        newData.offset = parseInt(0)
        setPage(1) 
        setRequestData(newData)

    }
    const _updateRequestByCapChange30Max = (value) => {

        if(value){
            setCapChange30Max(value)
        }else{
            setCapChange30Max('')
        }

        const prepare = _updateMax('percent_capity_change_30',value,capChange30Min,requestData)
        let newData = {...prepare}
        newData.offset = parseInt(0)
        setPage(1) 
        setRequestData(newData)

    }


    //
    //
    const _updateRequestByPace5Min = (value) => {

        if(value){
            setPace5Min(value)
        }else{
            setPace5Min('')
        }

        const prepare = _updateMin('pace_5',value,pace5Max,requestData)
        let newData = {...prepare}
        newData.offset = parseInt(0)
        setPage(1) 
        setRequestData(newData)

    }
    const _updateRequestByPace5Max = (value) => {

        if(value){
            setPace5Max(value)
        }else{
            setPace5Max('')
        }

        const prepare = _updateMax('pace_5',value,pace5Min,requestData)
        let newData = {...prepare}
        newData.offset = parseInt(0)
        setPage(1) 
        setRequestData(newData)

    }
    const _updateRequestByPace7Min = (value) => {

        if(value){
            setPace7Min(value)
        }else{
            setPace7Min('')
        }

        const prepare = _updateMin('pace_7',value,pace7Max,requestData)
        let newData = {...prepare}
        newData.offset = parseInt(0)
        setPage(1) 
        setRequestData(newData)

    }
    const _updateRequestByPace7Max = (value) => {

        if(value){
            setPace7Max(value)
        }else{
            setPace7Max('')
        }

        const prepare = _updateMax('pace_7',value,pace7Min,requestData)
        let newData = {...prepare}
        newData.offset = parseInt(0)
        setPage(1) 
        setRequestData(newData)

    }
    const _updateRequestByPace10Min = (value) => {

        if(value){
            setPace10Min(value)
        }else{
            setPace10Min('')
        }

        const prepare = _updateMin('pace_10',value,pace10Max,requestData)
        let newData = {...prepare}
        newData.offset = parseInt(0)
        setPage(1) 
        setRequestData(newData)

    }
    const _updateRequestByPace10Max = (value) => {

        if(value){
            setPace10Max(value)
        }else{
            setPace10Max('')
        }

        const prepare = _updateMax('pace_10',value,pace10Min,requestData)
        let newData = {...prepare}
        newData.offset = parseInt(0)
        setPage(1) 
        setRequestData(newData)

    }
    const _updateRequestByPace30Min = (value) => {

        if(value){
            setPace30Min(value)
        }else{
            setPace30Min('')
        }

        const prepare = _updateMin('pace_30',value,pace30Max,requestData)
        let newData = {...prepare}
        newData.offset = parseInt(0)
        setPage(1) 
        setRequestData(newData)

    }
    const _updateRequestByPace30Max = (value) => {

        if(value){
            setPace30Max(value)
        }else{
            setPace30Max('')
        }

        const prepare = _updateMax('pace_30',value,pace30Min,requestData)
        let newData = {...prepare}
        newData.offset = parseInt(0)
        setPage(1) 
        setRequestData(newData)

    }


    //
    //
    const _updateRequestBySpotifyFollowersMin = (value) => {

        if(value){
            setSpotifyFollowersMin(value)
        }else{
            setSpotifyFollowersMin('')
        }

        const prepare = _updateMin('spotify_total_followers',value,spotifyFollowersMax,requestData)
        let newData = {...prepare}
        newData.offset = parseInt(0)
        setPage(1) 
        setRequestData(newData)

    }
    const _updateRequestBySpotifyFollowersMax = (value) => {

        if(value){
            setSpotifyFollowersMax(value)
        }else{
            setSpotifyFollowersMax('')
        }

        const prepare = _updateMax('spotify_total_followers',value,spotifyFollowersMin,requestData)
        let newData = {...prepare}
        newData.offset = parseInt(0)
        setPage(1) 
        setRequestData(newData)

    }
    const _updateRequestBySpotify30dMin = (value) => {

        if(value){
            setSpotify30dMin(value)
        }else{
            setSpotify30dMin('')
        }

        const prepare = _updateMin('spotify_30day_growth',value,spotify30dMax,requestData)
        let newData = {...prepare}
        newData.offset = parseInt(0)
        setPage(1) 
        setRequestData(newData)

    }
    const _updateRequestBySpotify30dMax = (value) => {

        if(value){
            setSpotify30dMax(value)
        }else{
            setSpotify30dMax('')
        }

        const prepare = _updateMax('spotify_30day_growth',value,spotify30dMin,requestData)
        let newData = {...prepare}
        newData.offset = parseInt(0)
        setPage(1) 
        setRequestData(newData)

    }
    const _updateRequestBySpotify90dMin = (value) => {

        if(value){
            setSpotify90dMin(value)
        }else{
            setSpotify90dMin('')
        }

        const prepare = _updateMin('spotify_90day_growth',value,spotify90dMax,requestData)
        let newData = {...prepare}
        newData.offset = parseInt(0)
        setPage(1) 
        setRequestData(newData)

    }
    const _updateRequestBySpotify90dMax = (value) => {

        if(value){
            setSpotify90dMax(value)
        }else{
            setSpotify90dMax('')
        }

        const prepare = _updateMax('spotify_90day_growth',value,spotify90dMin,requestData)
        let newData = {...prepare}
        newData.offset = parseInt(0)
        setPage(1) 
        setRequestData(newData)

    }


    // 
    // 
    const _updateRequestBySoldOutMin = (value) => {

        if(value){
            setSoldOutMin(value)
        }else{
            setSoldOutMin('')
        }

        let newData = {...requestData}
        let range = []

        if(value){
            if(value>0){
                console.log('min +',value)
                range.push('event_date_time_2 >= '+dayjs().add(value, 'day').startOf('day').utc().unix())
            }else{
                console.log('min -',Math.abs(value), value)
                range.push('event_date_time_2 >= '+dayjs().subtract(Math.abs(value), 'day').startOf('day').utc().unix())
            }
        }
        
        if(soldOutMax !== ''){
            if(soldOutMax>0){
                range.push('event_date_time_2 <= '+dayjs().add(soldOutMax, 'day').endOf('day').utc().unix())
            }else{
                range.push('event_date_time_2 <= '+dayjs().subtract(Math.abs(soldOutMax), 'day').endOf('day').utc().unix())
            }
        }

        
        const filteredDatefield = newData.filter ? newData.filter.filter(x => x.search('event_date_time_2') === -1) : []
        if(range.length>0){
            newData.filter = newData.filter ? [...filteredDatefield, ...range] : [...range]
        }else{
            newData.filter = newData.filter ? [...filteredDatefield] : []
        }
        
        
        // return newData

        // const prepare = _updateMax('event_date_time',dayjs().subtract(value, 'day').unix(),dayjs().subtract(soldOutMin, 'day').unix(),requestData)
        console.log('_updateRequestBySoldOutMin', dayjs(dayjs().startOf('day').subtract(value, 'day')).format(), dayjs(dayjs().subtract(soldOutMax, 'day')).fromNow())
        // let newData = {...newData}
        newData.offset = parseInt(0)
        setPage(1) 
        setRequestData(newData)

    }
    const _updateRequestBySoldOutMax = (value) => {

        if(value){
            setSoldOutMax(value)
        }else{
            setSoldOutMax('')
        }

        let newData = {...requestData}
        let range = []

        if(value){
            if(value>0){
                console.log('max +',value)
                range.push('event_date_time_2 <= '+dayjs().add(value, 'day').endOf('day').utc().unix())
            }else{
                console.log('max -',Math.abs(value), value)
                range.push('event_date_time_2 <= '+dayjs().subtract(Math.abs(value), 'day').endOf('day').utc().unix())
            }
        }
        
        if(soldOutMin !== ''){
            if(soldOutMin>0){
                range.push('event_date_time_2 >= '+dayjs().add(soldOutMin, 'day').startOf('day').utc().unix())
            }else{
                range.push('event_date_time_2 >= '+dayjs().subtract(Math.abs(soldOutMin), 'day').startOf('day').utc().unix())
            }
        }

        
        const filteredDatefield = newData.filter ? newData.filter.filter(x => x.search('event_date_time_2') === -1) : []
        if(range.length>0){
            newData.filter = newData.filter ? [...filteredDatefield, ...range] : [...range]
        }else{
            newData.filter = newData.filter ? [...filteredDatefield] : []
        }

        // const prepare = _updateMin('event_date_time',dayjs().subtract(value, 'day').unix(),dayjs().subtract(soldOutMax, 'day').unix(),requestData)
        console.log('_updateRequestBySoldOutMax', dayjs(dayjs().subtract(value, 'day')).fromNow(), dayjs(dayjs().subtract(soldOutMin, 'day')).fromNow())
        // let newData = {...prepare}
        newData.offset = parseInt(0)
        setPage(1) 
        setRequestData(newData)

    }


    //
    //
    const _showFavoritesOnly = (state) => {
        let newData = {...requestData}
        if(state===true){
            newData.filter = newData.filter ? [...newData.filter, 'favorite = "true"'] : ['favorite = "true"']
        }else{
            newData.filter = newData.filter.filter(x => x.search('favorite = "true"') === -1)
        }
        setShowFavorites(state);
        setRequestData(newData)
    }


    //
    //
    const _showSoldOutsGA = (state) => {
        let newData = {...requestData}
        if(state===true){
            newData.filter = newData.filter ? [...newData.filter, 'sold_out_GA = "false"'] : ['sold_out_GA = "false"']
        }else{
            newData.filter = newData.filter.filter(x => x.search('sold_out_GA = "false"') === -1)
        }
        setSoldOutsGA(state);
        setRequestData(newData)
    }


    //
    //
    const _showSoldOuts = (state) => {
        let newData = {...requestData}
        if(state===true){
            newData.filter = newData.filter ? [...newData.filter, 'is_sold_out = "false"'] : ['is_sold_out = "false"']
        }else{
            newData.filter = newData.filter.filter(x => x.search('is_sold_out = "false"') === -1)
        }
        setSoldOuts(state);
        setRequestData(newData)
    }


    //
    //
    const _showPastEvents = (state) => {
        let newData = {...requestData}
        if(state.status){
            newData.filter = newData.filter ? [...newData.filter, 'event_date_time >= '+dayjs(state.date).unix()] : ['event_date_time >= '+dayjs(state.date).unix()]
        }else{
            newData.filter = newData.filter.filter(x => x.search('event_date_time >= '+dayjs(state.date).unix()) === -1)
        }
        setPastEvents(state);
        setRequestData(newData)
    }

    


    
    // 
    // 
    React.useEffect(()=>{
        let newData = {...requestData}
        setRequestData(newData)
        setRequestInit1(requestInit1+1)
        setReload(reload+1)
    },[])


    // 
    // 
    React.useEffect(()=>{
        const isFitlerParams = query.get("filter") && query.get("filter") !== '' ? true : false
        if(isFitlerParams){
            setRequestData(JSON.parse(query.get("filter")))
            navigate({
                filter: '',
                replace: true
            })
        }
    },[])


    // 
    // 
    React.useEffect(()=>{
        const isFitlerParams = query.get("event_id") && query.get("event_id") !== '' ? true : false
        if(isFitlerParams){
            setRequestData({...requestData, filter:['event_id = "'+query.get("event_id")+'"']})
            navigate({
                filter: '',
                replace: true
            })
        }
    },[])



    //
    // fetching data on load and on filter change
    React.useEffect(()=>{
        const _fetching = async() => {

            // console.log('REQUEST', requestSearch, requestData)

            setLoading(true)

            const index = client.index('offsales')
            const search = await index.search(requestSearch, requestData)


            let reindexData = [...search.hits]
            for (let index = 0; index < search.hits.length; index++) {
                const item = search.hits[index];
                item.rank = _rank(item)
            }

            if(requestData.sort){
                setResponseData(reindexData)
            }else{
                setResponseData(reindexData.sort((a,b) => b.rank - a.rank ))
            }
            setFilterDataCounts(search.facetDistribution)
            setTotalResults(search.estimatedTotalHits)
            setInitDate(dayjs())

            // console.log('RESPONSE', search)

            setLoading(false)
        }
        if(requestInit1>0){
            _fetching()
        }
        return () => setRequestInit1(requestInit1+1)
    },[requestSearch, requestData, reload])



    //
    // fetching filter options on load and on filter change
    React.useEffect(()=>{
        const _fetching = async() => {
            const index = client.index('offsales')
            let newRequestData = {...defaultRequestData}
            if(requestData.filter){
                // const find = requestData.filter.filter(x => { if(x.search('sale_start_date_time') !== -1){ return x }})
                // if(find.length>0){
                //     newRequestData = {...newRequestData, filter:find}
                // }
                newRequestData = {...newRequestData, filter:[]}
            }

            const search = await index.search(requestSearch, newRequestData)
            setFilterData(search.facetDistribution)

            // console.log('ATTRIBUTES', search)
        }
        if(requestInit1>0){
            _fetching()
        }
        return () => setRequestInit1(requestInit1+1)
    },[requestSearch, requestData, reload])


    // load venue capacity from saved search
    // React.useEffect(()=>{
    //     if(!venueCapacityMax && !venueCapacityMax && requestData.filter){
            
    //         const findMin = requestData.filter.filter(x => x.search('venue_capacity >=') !== -1)
    //         const findMax = requestData.filter.filter(x => x.search('venue_capacity <=') !== -1)
    //         if(findMin.length>0){
    //             const _readMin = findMin[0].split(' >= ')[1]
    //             setVenueCapacityMin(parseInt(_readMin))
    //         }

    //         if(findMax.length>0){
    //             const _readMax = findMax[0].split(' <= ')[1]
    //             setVenueCapacityMax(parseInt(_readMax))
    //         }
            
    //     }else{

    //         if(requestData.filter){
    //             const findMin = requestData.filter.filter(x => x.search('venue_capacity >=') !== -1)
    //             const findMax = requestData.filter.filter(x => x.search('venue_capacity <=') !== -1)
    //             if(findMin.length>0){
    //                 const _readMin = findMin[0].split(' >= ')[1]
    //                 setVenueCapacityMin(parseInt(_readMin))
    //             }else{
    //                 setVenueCapacityMin('')
    //             }

    //             if(findMax.length>0){
    //                 const _readMax = findMax[0].split(' <= ')[1]
    //                 setVenueCapacityMax(parseInt(_readMax))
    //             }else{
    //                 setVenueCapacityMax('')
    //             }
    //         }else{
    //             setVenueCapacityMin('')
    //             setVenueCapacityMax('')
    //         }
    //     }
    // },[requestData])



    //
    // set / get fitlerable attributes
    // React.useEffect(()=>{
    //     const _init = async() => {

    //         const updateSettings = await _apiPATCH('/indexes/offsales/settings/faceting', {"maxValuesPerFacet": 2000}, false, {'Authorization':'Bearer '+process.env.REACT_APP_APIKEY_OFFSALE,'Content-Type':'application/json'}, {baseURL:process.env.REACT_APP_APIURL_OFFSALE})
            
    //         const searchableAttributes = await _apiPUT('/indexes/offsales/settings/searchable-attributes', ["name","venue_name"], false, {'Authorization':'Bearer '+process.env.REACT_APP_APIKEY_OFFSALE,'Content-Type':'application/json'}, {baseURL:process.env.REACT_APP_APIURL_OFFSALE})
    //         // console.log('searchableAttributes',searchableAttributes)
    //         const getSearchableAttributes = await _apiGETone('/indexes/offsales/settings/searchable-attributes', {'Authorization':'Bearer '+process.env.REACT_APP_APIKEY_OFFSALE,'Content-Type':'application/json'}, false, {baseURL:process.env.REACT_APP_APIURL_OFFSALE})
    //         // console.log('getSearchableAttributes',getSearchableAttributes)


    //         const updateAttributes = await _apiPUT('/indexes/offsales/settings/filterable-attributes', [
    //             "capacity_remaining",
    //             "event_date_time",
    //             "event_date_time_2",
    //             "event_id",
    //             "favorite",
    //             "is_sold_out",
    //             "pace_10",
    //             "pace_30",
    //             "pace_5",
    //             "pace_7",
    //             "percent_capity_change_1",
    //             "percent_capity_change_14",
    //             "percent_capity_change_30",
    //             "percent_capity_change_7",
    //             "public_sale_date_time",
    //             "sold_out_GA",
    //             "spotify_30day_growth",
    //             "spotify_90day_growth",
    //             "spotify_total_followers",
    //             "total_remaining",
    //             "venue_capacity",
    //             "venue_city",
    //             "venue_name",
    //             "venue_state"
    //         ], false, {'Authorization':'Bearer '+process.env.REACT_APP_APIKEY_OFFSALE}, {baseURL:process.env.REACT_APP_APIURL_OFFSALE})
    //             // console.log('updateAttributes',updateAttributes)
    //         const getAttributes = await _apiGETone('/indexes/offsales/settings/filterable-attributes', {'Authorization':'Bearer '+process.env.REACT_APP_APIKEY_OFFSALE,'Content-Type':'application/json'}, false, {baseURL:process.env.REACT_APP_APIURL_OFFSALE})
    //         // console.log('getAttributes',getAttributes)


            // const updateSortableAttributes = await _apiPUT('/indexes/offsales/settings/sortable-attributes', [
            //     "absolute_change_30",
            //     "absolute_change_daily",
            //     "absolute_change_weekly",
            //     "capacity_remaining",
            //     "delta_avg_change_3",
            //     "delta_avg_change_7",
            //     "event_date_time",
            //     "event_date_time_2",
            //     "favorite",
            //     "follower_cap_ratio",
            //     "is_sold_out",
            //     "min_GA_price",
            //     "name",
            //     "pace_10",
            //     "pace_15",
            //     "pace_30",
            //     "pace_5",
            //     "pace_7",
            //     "pace_percent_30",
            //     "pace_percent_7",
            //     "percent_capacity_change_7",
            //     "percent_capity_change_1",
            //     "percent_capity_change_14",
            //     "percent_capity_change_30",
            //     "percent_capity_change_7",
            //     "ppi_weekly",
            //     "public_sale_date_time",
            //     "sold_out_GA",
            //     "spotify_30day_growth",
            //     "spotify_90day_growth",
            //     "spotify_total_followers",
            //     "total_GA_remaining",
            //     "total_general_floor_remaining",
            //     "total_general_lawn_remaining",
            //     "total_general_pit_remaining",
            //     "total_remaining",
            //     "total_reserved_remaining",
            //     "venue_capacity",
            //     "venue_city",
            //     "venue_name",
            //     "venue_state",
            //     "last_offsale_pull"
            // ], false, {'Authorization':'Bearer '+process.env.REACT_APP_APIKEY_OFFSALE}, {baseURL:process.env.REACT_APP_APIURL_OFFSALE})
            // // console.log('updateSortableAttributes',updateSortableAttributes)
            // const getSortableAttributes = await _apiGETone('/indexes/offsales/settings/sortable-attributes', {'Authorization':'Bearer '+process.env.REACT_APP_APIKEY_OFFSALE,'Content-Type':'application/json'}, false, {baseURL:process.env.REACT_APP_APIURL_OFFSALE})
            // // console.log('getSortableAttributes',getSortableAttributes)


    //     }
    //     _init()
    // },[])



    //
    //
    return (
        <Fragment>
        <Helmet>
            <title>{`OffSale | ${process.env.REACT_APP_SITENAME}`}</title>
        </Helmet>

        <Layout>

            <div className='wrapper'>
                <Header
                    mobileStateAction={setMobileFilterState}
                >
                    <div className={`mob ${!mobileFilterState ? 'flex h-auto' : 'flex h-0 opacity-0'}`}>
                        <div className='w-full lg:max-w-[200px]'>
                            <div className={`switch mt-7`}>
                                <input type="checkbox" name="showFavorites" onChange={()=>_showFavoritesOnly(!showFavorites)}/>
                                <label><span>Watchlist</span></label>
                            </div>
                            <div className={`switch mt-5`}>
                                <input type="checkbox" name="showSoldOuts" onChange={()=>_showSoldOuts(!showSoldOuts)}/>
                                <label><span>Show Sold Out</span></label>
                            </div>
                            <div className={`switch mt-5`}>
                                <input type="checkbox" name="showSoldOutsGA" onChange={()=>_showSoldOutsGA(!showSoldOutsGA)}/>
                                <label><span>Zero GA Counts</span></label>
                            </div>
                            <div className={`switch mt-5`}>
                                <input type="checkbox" name="showPastEvents" onChange={()=>_showPastEvents({status: !showPastEvents.status, date: !showPastEvents.status ? dayjs().endOf('day') : showPastEvents.date})}/>
                                <label><span>Show Past Events</span></label>
                            </div>
                        </div>
                        <div className='w-full lg:max-w-[200px]'>
                            <div className='formGroup'>
                                <label>Search</label> 
                                <div>
                                    <input 
                                        type="text"
                                        name="search"
                                        onChange={(e)=>setRequestSearch(e.target.value)}
                                        value={requestSearch}
                                        className='formControl'
                                        autoComplete='off'
                                    />
                                    {_isFieldUsed('search') && <button className='reset' onClick={()=>_resetFields('search')}><X size={18} color="#fff"/></button>}
                                </div>
                            </div>
                            <div className='formGroup mt-1'>
                                <label>Venue capacity</label> 
                                <div className='w-full'>
                                    <div className='w-full flex flex-row justify-start items-center'>
                                        <input
                                            type="number"
                                            name="venue_capacty_min"
                                            step="100"
                                            min="0"
                                            max={venueCapacityMaxValue}
                                            className='formControl rounded-none'
                                            value={venueCapacityMin}
                                            onChange={(e)=>_updateRequestByVenueCapacityMin(e.target.value)}
                                        />
                                        <div className='formControl w-7 rounded-none px-0'>
                                            <svg className="DateRangePickerInput_arrow_svg DateRangePickerInput_arrow_svg_1" focusable="false" viewBox="0 0 1000 1000"><path d="M694 242l249 250c12 11 12 21 1 32L694 773c-5 5-10 7-16 7s-11-2-16-7c-11-11-11-21 0-32l210-210H68c-13 0-23-10-23-23s10-23 23-23h806L662 275c-21-22 11-54 32-33z"></path></svg>
                                        </div>
                                        <input
                                            type="number"
                                            name="venue_capacty_max"
                                            step="100"
                                            min="0"
                                            max={venueCapacityMaxValue}
                                            className='formControl rounded-none'
                                            value={venueCapacityMax}
                                            onChange={(e)=>_updateRequestByVenueCapacityMax(e.target.value)}
                                        />
                                    </div>
                                    {_isFieldUsed('venue_capacity') && <button className='reset' onClick={()=>_resetFields('venue_capacity')}><X size={18} color="#fff"/></button>}
                                </div>
                            </div>
                            <div className='formGroup mt-1'>
                                <label>Days Out</label> 
                                <div className='w-full'>
                                    <div className='w-full flex flex-row justify-start items-center'>
                                        <input
                                            type="number"
                                            name="sold_out_min"
                                            step="1"
                                            min="0"
                                            max={1000}
                                            className='formControl rounded-none'
                                            value={soldOutMin}
                                            onChange={(e)=>_updateRequestBySoldOutMin(e.target.value)}
                                        />
                                        <div className='formControl w-7 rounded-none px-0'>
                                            <svg className="DateRangePickerInput_arrow_svg DateRangePickerInput_arrow_svg_1" focusable="false" viewBox="0 0 1000 1000"><path d="M694 242l249 250c12 11 12 21 1 32L694 773c-5 5-10 7-16 7s-11-2-16-7c-11-11-11-21 0-32l210-210H68c-13 0-23-10-23-23s10-23 23-23h806L662 275c-21-22 11-54 32-33z"></path></svg>
                                        </div>
                                        <input
                                            type="number"
                                            name="sold_out_max"
                                            step="1"
                                            min="0"
                                            max={1000}
                                            className='formControl rounded-none'
                                            value={soldOutMax}
                                            onChange={(e)=>_updateRequestBySoldOutMax(e.target.value)}
                                        />
                                    </div>
                                    {_isFieldUsed('event_date_time_2') && <button className='reset' onClick={()=>_resetFields('event_date_time_2')}><X size={18} color="#fff"/></button>}
                                </div>
                            </div>
                            <div className='formGroup mt-1'>
                                <label>Cap remaining</label> 
                                <div className='w-full'>
                                    <div className='w-full flex flex-row justify-start items-center'>
                                        <input
                                            type="number"
                                            name="capacity_remaining"
                                            step="0.1"
                                            min="0"
                                            max={1}
                                            className='formControl rounded-none'
                                            value={capRemainingMin}
                                            onChange={(e)=>_updateRequestByCapRemainingMin(e.target.value)}
                                        />
                                        <div className='formControl w-7 rounded-none px-0'>
                                            <svg className="DateRangePickerInput_arrow_svg DateRangePickerInput_arrow_svg_1" focusable="false" viewBox="0 0 1000 1000"><path d="M694 242l249 250c12 11 12 21 1 32L694 773c-5 5-10 7-16 7s-11-2-16-7c-11-11-11-21 0-32l210-210H68c-13 0-23-10-23-23s10-23 23-23h806L662 275c-21-22 11-54 32-33z"></path></svg>
                                        </div>
                                        <input
                                            type="number"
                                            name="capacity_remaining"
                                            step="0.1"
                                            min="0"
                                            max={1}
                                            className='formControl rounded-none'
                                            value={capRemainingMax}
                                            onChange={(e)=>_updateRequestByCapRemainingMax(e.target.value)}
                                        />
                                    </div>
                                    {_isFieldUsed('capacity_remaining') && <button className='reset' onClick={()=>_resetFields('capacity_remaining')}><X size={18} color="#fff"/></button>}
                                </div>
                            </div>
                        </div>
                        <div>
                            <FormMultiSelect
                                label="Venue name"
                                field={'venue_name'}
                                request={requestData}
                                data={filterData.venue_name}
                                counts={filterDataCounts.venue_name}
                                isUsed={()=>_isFieldUsed('venue_name')}
                                onChange={(item)=>_onFieldChange('venue_name', item)}
                                onReset={()=>_resetFields('venue_name')}
                            />
                        </div>
                        <div className='w-full lg:max-w-[170px]'>
                            <FormMultiSelect
                                label="Venue city"
                                field={'venue_city'}
                                request={requestData}
                                data={filterData.venue_city}
                                counts={filterDataCounts.venue_city}
                                isUsed={()=>_isFieldUsed('venue_city')}
                                onChange={(item)=>_onFieldChange('venue_city', item)}
                                onReset={()=>_resetFields('venue_city')}
                            />
                        </div>
                        <div className='w-full lg:max-w-[170px]'>
                            <div className='formGroup'>
                                <label>CapΔ 1</label> 
                                <div className='w-full'>
                                    <div className='w-full flex flex-row justify-start items-center'>
                                        <input
                                            type="number"
                                            name="percent_capity_change_1_min"
                                            step="0.1"
                                            min="-1"
                                            max={1}
                                            className='formControl rounded-none'
                                            value={capChange1Min}
                                            onChange={(e)=>_updateRequestByCapChange1Min(e.target.value)}
                                        />
                                        <div className='formControl w-7 rounded-none px-0'>
                                            <svg className="DateRangePickerInput_arrow_svg DateRangePickerInput_arrow_svg_1" focusable="false" viewBox="0 0 1000 1000"><path d="M694 242l249 250c12 11 12 21 1 32L694 773c-5 5-10 7-16 7s-11-2-16-7c-11-11-11-21 0-32l210-210H68c-13 0-23-10-23-23s10-23 23-23h806L662 275c-21-22 11-54 32-33z"></path></svg>
                                        </div>
                                        <input
                                            type="number"
                                            name="percent_capity_change_1_max"
                                            step="0.1"
                                            min="-1"
                                            max={1}
                                            className='formControl rounded-none'
                                            value={capChange1Max}
                                            onChange={(e)=>_updateRequestByCapChange1Max(e.target.value)}
                                        />
                                    </div>
                                    {_isFieldUsed('percent_capity_change_1') && <button className='reset' onClick={()=>_resetFields('percent_capity_change_1')}><X size={18} color="#fff"/></button>}
                                </div>
                            </div>
                            <div className='formGroup mt-1'>
                                <label>CapΔ 7</label> 
                                <div className='w-full'>
                                    <div className='w-full flex flex-row justify-start items-center'>
                                        <input
                                            type="number"
                                            name="percent_capity_change_7_min"
                                            step="0.1"
                                            min="-1"
                                            max={1}
                                            className='formControl rounded-none'
                                            value={capChange7Min}
                                            onChange={(e)=>_updateRequestByCapChange7Min(e.target.value)}
                                        />
                                        <div className='formControl w-7 rounded-none px-0'>
                                            <svg className="DateRangePickerInput_arrow_svg DateRangePickerInput_arrow_svg_1" focusable="false" viewBox="0 0 1000 1000"><path d="M694 242l249 250c12 11 12 21 1 32L694 773c-5 5-10 7-16 7s-11-2-16-7c-11-11-11-21 0-32l210-210H68c-13 0-23-10-23-23s10-23 23-23h806L662 275c-21-22 11-54 32-33z"></path></svg>
                                        </div>
                                        <input
                                            type="number"
                                            name="percent_capity_change_7_max"
                                            step="0.1"
                                            min="-1"
                                            max={1}
                                            className='formControl rounded-none'
                                            value={capChange7Max}
                                            onChange={(e)=>_updateRequestByCapChange7Max(e.target.value)}
                                        />
                                    </div>
                                    {_isFieldUsed('percent_capity_change_7') && <button className='reset' onClick={()=>_resetFields('percent_capity_change_7')}><X size={18} color="#fff"/></button>}
                                </div>
                            </div>
                            <div className='formGroup mt-1'>
                                <label>CapΔ 14</label> 
                                <div className='w-full'>
                                    <div className='w-full flex flex-row justify-start items-center'>
                                        <input
                                            type="number"
                                            name="percent_capity_change_14_min"
                                            step="0.1"
                                            min="-1"
                                            max={1}
                                            className='formControl rounded-none'
                                            value={capChange14Min}
                                            onChange={(e)=>_updateRequestByCapChange14Min(e.target.value)}
                                        />
                                        <div className='formControl w-7 rounded-none px-0'>
                                            <svg className="DateRangePickerInput_arrow_svg DateRangePickerInput_arrow_svg_1" focusable="false" viewBox="0 0 1000 1000"><path d="M694 242l249 250c12 11 12 21 1 32L694 773c-5 5-10 7-16 7s-11-2-16-7c-11-11-11-21 0-32l210-210H68c-13 0-23-10-23-23s10-23 23-23h806L662 275c-21-22 11-54 32-33z"></path></svg>
                                        </div>
                                        <input
                                            type="number"
                                            name="percent_capity_change_14_max"
                                            step="0.1"
                                            min="-1"
                                            max={1}
                                            className='formControl rounded-none'
                                            value={capChange14Max}
                                            onChange={(e)=>_updateRequestByCapChange14Max(e.target.value)}
                                        />
                                    </div>
                                    {_isFieldUsed('percent_capity_change_14') && <button className='reset' onClick={()=>_resetFields('percent_capity_change_14')}><X size={18} color="#fff"/></button>}
                                </div>
                            </div>
                            <div className='formGroup mt-1'>
                                <label>CapΔ 30</label> 
                                <div className='w-full'>
                                    <div className='w-full flex flex-row justify-start items-center'>
                                        <input
                                            type="number"
                                            name="percent_capity_change_30_min"
                                            step="0.1"
                                            min="-1"
                                            max={1}
                                            className='formControl rounded-none'
                                            value={capChange30Min}
                                            onChange={(e)=>_updateRequestByCapChange30Min(e.target.value)}
                                        />
                                        <div className='formControl w-7 rounded-none px-0'>
                                            <svg className="DateRangePickerInput_arrow_svg DateRangePickerInput_arrow_svg_1" focusable="false" viewBox="0 0 1000 1000"><path d="M694 242l249 250c12 11 12 21 1 32L694 773c-5 5-10 7-16 7s-11-2-16-7c-11-11-11-21 0-32l210-210H68c-13 0-23-10-23-23s10-23 23-23h806L662 275c-21-22 11-54 32-33z"></path></svg>
                                        </div>
                                        <input
                                            type="number"
                                            name="percent_capity_change_30_max"
                                            step="0.1"
                                            min="-1"
                                            max={1}
                                            className='formControl rounded-none'
                                            value={capChange30Max}
                                            onChange={(e)=>_updateRequestByCapChange30Max(e.target.value)}
                                        />
                                    </div>
                                    {_isFieldUsed('percent_capity_change_30') && <button className='reset' onClick={()=>_resetFields('percent_capity_change_30')}><X size={18} color="#fff"/></button>}
                                </div>
                            </div>
                        </div>
                        <div className='w-full lg:max-w-[170px]'>
                            <div className='formGroup'>
                                <label>5d Pace</label> 
                                <div className='w-full'>
                                    <div className='w-full flex flex-row justify-start items-center'>
                                        <input
                                            type="number"
                                            name="pace_5_min"
                                            step="0.1"
                                            min="-1"
                                            max={1}
                                            className='formControl rounded-none'
                                            value={pace5Min}
                                            onChange={(e)=>_updateRequestByPace5Min(e.target.value)}
                                        />
                                        <div className='formControl w-7 rounded-none px-0'>
                                            <svg className="DateRangePickerInput_arrow_svg DateRangePickerInput_arrow_svg_1" focusable="false" viewBox="0 0 1000 1000"><path d="M694 242l249 250c12 11 12 21 1 32L694 773c-5 5-10 7-16 7s-11-2-16-7c-11-11-11-21 0-32l210-210H68c-13 0-23-10-23-23s10-23 23-23h806L662 275c-21-22 11-54 32-33z"></path></svg>
                                        </div>
                                        <input
                                            type="number"
                                            name="pace_5_1_max"
                                            step="0.1"
                                            min="-1"
                                            max={1}
                                            className='formControl rounded-none'
                                            value={pace5Max}
                                            onChange={(e)=>_updateRequestByPace5Max(e.target.value)}
                                        />
                                    </div>
                                    {_isFieldUsed('pace_5') && <button className='reset' onClick={()=>_resetFields('pace_5')}><X size={18} color="#fff"/></button>}
                                </div>
                            </div>
                            <div className='formGroup mt-1'>
                                <label>7d Pace</label> 
                                <div className='w-full'>
                                    <div className='w-full flex flex-row justify-start items-center'>
                                        <input
                                            type="number"
                                            name="pace_7_min"
                                            step="0.1"
                                            min="-1"
                                            max={1}
                                            className='formControl rounded-none'
                                            value={pace7Min}
                                            onChange={(e)=>_updateRequestByPace7Min(e.target.value)}
                                        />
                                        <div className='formControl w-7 rounded-none px-0'>
                                            <svg className="DateRangePickerInput_arrow_svg DateRangePickerInput_arrow_svg_1" focusable="false" viewBox="0 0 1000 1000"><path d="M694 242l249 250c12 11 12 21 1 32L694 773c-5 5-10 7-16 7s-11-2-16-7c-11-11-11-21 0-32l210-210H68c-13 0-23-10-23-23s10-23 23-23h806L662 275c-21-22 11-54 32-33z"></path></svg>
                                        </div>
                                        <input
                                            type="number"
                                            name="pace_7_max"
                                            step="0.1"
                                            min="-1"
                                            max={1}
                                            className='formControl rounded-none'
                                            value={pace7Max}
                                            onChange={(e)=>_updateRequestByPace7Max(e.target.value)}
                                        />
                                    </div>
                                    {_isFieldUsed('pace_7') && <button className='reset' onClick={()=>_resetFields('pace_7')}><X size={18} color="#fff"/></button>}
                                </div>
                            </div>
                            <div className='formGroup mt-1'>
                                <label>10d Pace</label> 
                                <div className='w-full'>
                                    <div className='w-full flex flex-row justify-start items-center'>
                                        <input
                                            type="number"
                                            name="pace_10_min"
                                            step="0.1"
                                            min="-1"
                                            max={1}
                                            className='formControl rounded-none'
                                            value={pace10Min}
                                            onChange={(e)=>_updateRequestByPace10Min(e.target.value)}
                                        />
                                        <div className='formControl w-7 rounded-none px-0'>
                                            <svg className="DateRangePickerInput_arrow_svg DateRangePickerInput_arrow_svg_1" focusable="false" viewBox="0 0 1000 1000"><path d="M694 242l249 250c12 11 12 21 1 32L694 773c-5 5-10 7-16 7s-11-2-16-7c-11-11-11-21 0-32l210-210H68c-13 0-23-10-23-23s10-23 23-23h806L662 275c-21-22 11-54 32-33z"></path></svg>
                                        </div>
                                        <input
                                            type="number"
                                            name="pace_10_max"
                                            step="0.1"
                                            min="-1"
                                            max={1}
                                            className='formControl rounded-none'
                                            value={pace10Max}
                                            onChange={(e)=>_updateRequestByPace10Max(e.target.value)}
                                        />
                                    </div>
                                    {_isFieldUsed('pace_10') && <button className='reset' onClick={()=>_resetFields('pace_10')}><X size={18} color="#fff"/></button>}
                                </div>
                            </div>
                            <div className='formGroup mt-1'>
                                <label>30d Pace</label> 
                                <div className='w-full'>
                                    <div className='w-full flex flex-row justify-start items-center'>
                                        <input
                                            type="number"
                                            name="pace_30_min"
                                            step="0.1"
                                            min="-1"
                                            max={1}
                                            className='formControl rounded-none'
                                            value={pace30Min}
                                            onChange={(e)=>_updateRequestByPace30Min(e.target.value)}
                                        />
                                        <div className='formControl w-7 rounded-none px-0'>
                                            <svg className="DateRangePickerInput_arrow_svg DateRangePickerInput_arrow_svg_1" focusable="false" viewBox="0 0 1000 1000"><path d="M694 242l249 250c12 11 12 21 1 32L694 773c-5 5-10 7-16 7s-11-2-16-7c-11-11-11-21 0-32l210-210H68c-13 0-23-10-23-23s10-23 23-23h806L662 275c-21-22 11-54 32-33z"></path></svg>
                                        </div>
                                        <input
                                            type="number"
                                            name="pace_30_max"
                                            step="0.1"
                                            min="-1"
                                            max={1}
                                            className='formControl rounded-none'
                                            value={pace30Max}
                                            onChange={(e)=>_updateRequestByPace30Max(e.target.value)}
                                        />
                                    </div>
                                    {_isFieldUsed('pace_30') && <button className='reset' onClick={()=>_resetFields('pace_30')}><X size={18} color="#fff"/></button>}
                                </div>
                            </div>
                        </div>
                        <div className='w-full lg:max-w-[170px]'>
                            <div className='formGroup'>
                                <label>Spotify Followers</label> 
                                <div className='w-full'>
                                    <div className='w-full flex flex-row justify-start items-center'>
                                        <input
                                            type="number"
                                            name="spotify_followers_min"
                                            step="100"
                                            min="0"
                                            max={venueCapacityMaxValue}
                                            className='formControl rounded-none'
                                            value={spotifyFollowersMin}
                                            onChange={(e)=>_updateRequestBySpotifyFollowersMin(e.target.value)}
                                        />
                                        <div className='formControl w-7 rounded-none px-0'>
                                            <svg className="DateRangePickerInput_arrow_svg DateRangePickerInput_arrow_svg_1" focusable="false" viewBox="0 0 1000 1000"><path d="M694 242l249 250c12 11 12 21 1 32L694 773c-5 5-10 7-16 7s-11-2-16-7c-11-11-11-21 0-32l210-210H68c-13 0-23-10-23-23s10-23 23-23h806L662 275c-21-22 11-54 32-33z"></path></svg>
                                        </div>
                                        <input
                                            type="number"
                                            name="spotify_followers_max"
                                            step="100"
                                            min="0"
                                            max={venueCapacityMaxValue}
                                            className='formControl rounded-none'
                                            value={spotifyFollowersMax  }
                                            onChange={(e)=>_updateRequestBySpotifyFollowersMax(e.target.value)}
                                        />
                                    </div>
                                    {_isFieldUsed('spotify_total_followers') && <button className='reset' onClick={()=>_resetFields('spotify_total_followers')}><X size={18} color="#fff"/></button>}
                                </div>
                            </div>
                            <div className='formGroup mt-1'>
                                <label>Spotify 30d</label> 
                                <div className='w-full'>
                                    <div className='w-full flex flex-row justify-start items-center'>
                                        <input
                                            type="number"
                                            name="spotify_30day_growth_min"
                                            step="0.1"
                                            min="-1"
                                            max={1}
                                            className='formControl rounded-none'
                                            value={spotify30dMin}
                                            onChange={(e)=>_updateRequestBySpotify30dMin(e.target.value)}
                                        />
                                        <div className='formControl w-7 rounded-none px-0'>
                                            <svg className="DateRangePickerInput_arrow_svg DateRangePickerInput_arrow_svg_1" focusable="false" viewBox="0 0 1000 1000"><path d="M694 242l249 250c12 11 12 21 1 32L694 773c-5 5-10 7-16 7s-11-2-16-7c-11-11-11-21 0-32l210-210H68c-13 0-23-10-23-23s10-23 23-23h806L662 275c-21-22 11-54 32-33z"></path></svg>
                                        </div>
                                        <input
                                            type="number"
                                            name="spotify_30day_growth_max"
                                            step="0.1"
                                            min="-1"
                                            max={1}
                                            className='formControl rounded-none'
                                            value={spotify30dMax}
                                            onChange={(e)=>_updateRequestBySpotify30dMax(e.target.value)}
                                        />
                                    </div>
                                    {_isFieldUsed('spotify_30day_growth') && <button className='reset' onClick={()=>_resetFields('spotify_30day_growth')}><X size={18} color="#fff"/></button>}
                                </div>
                            </div>
                            <div className='formGroup mt-1'>
                                <label>Spotify 90d</label> 
                                <div className='w-full'>
                                    <div className='w-full flex flex-row justify-start items-center'>
                                        <input
                                            type="number"
                                            name="spotify_90day_growth_min"
                                            step="0.1"
                                            min="-1"
                                            max={1}
                                            className='formControl rounded-none'
                                            value={spotify90dMin}
                                            onChange={(e)=>_updateRequestBySpotify90dMin(e.target.value)}
                                        />
                                        <div className='formControl w-7 rounded-none px-0'>
                                            <svg className="DateRangePickerInput_arrow_svg DateRangePickerInput_arrow_svg_1" focusable="false" viewBox="0 0 1000 1000"><path d="M694 242l249 250c12 11 12 21 1 32L694 773c-5 5-10 7-16 7s-11-2-16-7c-11-11-11-21 0-32l210-210H68c-13 0-23-10-23-23s10-23 23-23h806L662 275c-21-22 11-54 32-33z"></path></svg>
                                        </div>
                                        <input
                                            type="number"
                                            name="spotify_90day_growth_max"
                                            step="0.1"
                                            min="-1"
                                            max={1}
                                            className='formControl rounded-none'
                                            value={spotify90dMax}
                                            onChange={(e)=>_updateRequestBySpotify90dMax(e.target.value)}
                                        />
                                    </div>
                                    {_isFieldUsed('spotify_90day_growth') && <button className='reset' onClick={()=>_resetFields('spotify_90day_growth')}><X size={18} color="#fff"/></button>}
                                </div>
                            </div>
                        </div>
                        <div className='w-full lg:max-w-[300px]'>
                            <Settings
                                currentRequest={requestData}
                                currentTable={columns}
                                loadRequest={(r)=> setRequestData(r)}
                            />
                            <div className='formGroup mt-8'>
                                {_isResetVisible() && <button className='button primary w-full' onClick={()=>_resetFields('all')}><X size={18} color="#fff"/> Clear all</button>}
                            </div>
                        </div>
                    </div>
                </Header>

                <div className="main tableRef offsales">
                    <DataTable
                        columns={columns}
                        data={responseData}
                        fixedHeader
                        onSort={_handleSort}
                        onSelectedRowsChange={updateState}
                        sortServer
                        pagination
                        paginationServer
                        paginationDefaultPage={page}
                        paginationTotalRows={totalResults}
                        paginationPerPage={requestData.limit}
                        onChangeRowsPerPage={_handlePerRowsChange}
                        onChangePage={_handlePageChange}
                        paginationRowsPerPageOptions={[20,50,100,200]}
                        expandableRows 
                        expandableRowsComponent={ExpandedComponent}
                        keyField={'sale_id'}
                    />
                </div>

                {isLoading && 
                    <div className='loading'>
                        <span className="icon">
                            <Loader size={20} className="animate-spin"/>
                        </span>
                        <span className='text'>Loading...</span>
                    </div>
                }
            </div>


            <Modal
                status={isDetailsModalOpen ? true : false}
                showClose={true}
                close={()=>setDetailsModalIsOpen(null)}
                size="large"
            >
                {isDetailsModalOpen && <DetailsOnSale data={isDetailsModalOpen}/>}

                <Hotkeys 
                    keyName="esc" 
                    filter={(event) => {
                        return true;
                    }}
                    onKeyDown={null}
                    onKeyUp={onKeyUp}
                />
            </Modal>


            <Hotkeys 
                keyName="alt+right,alt+left" 
                filter={(event) => {
                    return true;
                }}
                onKeyDown={null}
                onKeyUp={onKeyUp}
            />
            
        </Layout>
            
        </Fragment>
    );

}
