import {toast} from 'react-toastify'
import { ArrowUp, ArrowDown } from 'react-feather'



//
//
export const _log = (title, message, type='log') => {
  if(process.env.REACT_APP_DEBUG==='true'){
    console[type](title, message)
  }
}



//
//
export const _timeout = function(ms) {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  });
}


//
//
export const _goBack = (location) => {
  if(location.state && location.state !== ''){
    return {pathname:location.state.pathname, state: location.state}
  }else{
    return false;
  }
}



//
//
export const inArray = (needle, haystack) => {
    var length = haystack.length;
    for(var i = 0; i < length; i++) {
        if(haystack[i] === needle) return true;
    }
    return false;
}


//
//
export const _toaster = (title=null, message=null, type) => {

  let cssClass = 'csBackground'+type.charAt(0).toUpperCase() + type.slice(1)

  toast[type](
    <>
        {title && <strong className="mb-2 block text-sm">{title}</strong>}
        {message && <p className="text-sm text-opacity-75">{message}</p>}
    </>, 
    {className: cssClass}
  )

}


//
//
export const _isActivePage = (path, location) => {
  if(path===location.pathname){
    return true
  }else{
    return false
  }
}


//
//
export const _shortenAddress = (address) => {
  return address.substr(0,6)+'...'+address.substr(-4)
}


//
//
export const _copy = (text, notification=true) => {

  const el = document.createElement('textarea');
  el.value = text;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  if(notification){
    _toaster('Copied', false, 'success')
  }
}


//
//
export const _groupBy = (xs, key) => {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};


// 
// add leading zero
export const _pad = (num, size) => {
  num = num.toString();
  while (num.length < size) num = "0" + num;
  return num;
}



// 
// 
Number.prototype.format = function(n, x) {
  var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
  return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
};


//
//
export const _calcChangeValue = (value) => {
  if(value>0){
      return <span className={`flex flex-row items-center text-green-500`}><ArrowUp size={18} className="mr-1"/>{value.format()}</span>
  }
  if(value<0){
      return <span className={`flex flex-row items-center text-red-500`}><ArrowDown size={18} className="mr-1"/>{value.format()}</span>
  }
  if(value===0){
      return <span className={`flex flex-row items-center`}>0</span>
  }

  return <span className={`flex flex-row items-center`}></span>
}


//
//
export const _calcChangePercent = (value) => {

  if(value>0){
      return <span className={`flex flex-row items-center text-green-500`}><ArrowUp size={18} className="mr-1"/>{Math.round(value*100)}%</span>
  }
  if(value<0){
      return <span className={`flex flex-row items-center text-red-500`}><ArrowDown size={18} className="mr-1"/>{Math.round(value*100)}%</span>
  }
  if(value===0){
      return <span className={`flex flex-row items-center`}>0</span>
  }

  return <span className={`flex flex-row items-center`}></span>
}


// 
// 
export const _rank = (data) => {

  if(parseInt(data.total_remaining)<1){
      return parseInt(0)
  }

  const capacity_released = (data.total_release ? parseInt(data.total_release) : 100) / parseInt(data.venue_capacity)
  const percent_remaining = (1 - (parseInt(data.total_remaining) / parseInt(data.venue_capacity)))
  const percent_change_daily = parseInt(data.absolute_change_daily) / parseInt(data.venue_capacity)

  return parseFloat(capacity_released + 2*percent_remaining -2*percent_change_daily).toFixed(2)

}


// 
// 
export const _updateMin = (field, value, max, requestData) => {

  let newData = {...requestData}
  let range = []

  if(value){
      range.push(field+' >= '+value)
  }
  
  if(max !== ''){
      range.push(field+' <= '+max)
  }

  
  const filteredDatefield = newData.filter ? newData.filter.filter(x => x.search(field) === -1) : []
  if(range.length>0){
      newData.filter = newData.filter ? [...filteredDatefield, ...range] : [...range]
  }else{
      newData.filter = newData.filter ? [...filteredDatefield] : []
  }
  
  
  return newData

}


// 
// 
export const _updateMax = (field, value, min, requestData) => {

  let newData = {...requestData}
  let range = []

  if(value){
      range.push(field+' <= '+value)
  }

  if(min !== ''){
      range.push(field+' >= '+min)
  }

  
  const filteredDatefield = newData.filter ? newData.filter.filter(x => x.search(field) === -1) : []
  if(range.length>0){
      newData.filter = newData.filter ? [...filteredDatefield, ...range] : [...range]
  }else{
      newData.filter = newData.filter ? [...filteredDatefield] : []
  }
  
  
  return newData

}