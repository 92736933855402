import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async'
import { useNavigate } from "react-router-dom";
import dayjs from 'dayjs';
import { X, Loader, ExternalLink, MoreVertical, Menu, ArrowUp, ArrowDown, ArrowUpRight, Star } from 'react-feather'
import DataTable from 'react-data-table-component';
import 'react-dates/initialize'
import { DateRangePicker } from 'react-dates';
import Hotkeys from 'react-hot-keys'
import { MeiliSearch } from 'meilisearch'
import moment from 'moment';


// controllers
import useQuery from '../controllers/hooks/useQuery';
import { _apiPUT, _apiPOST } from '../controllers/Api'
import { _toaster, _pad, _timeout, _calcChangeValue, _calcChangePercent, _rank } from '../controllers/Helpers'


// components
import Layout from '../components/Layout'
import Header from '../components/Header'
import Modal from '../components/Modal'
import DetailsOnSale from '../components/Details/onSale';
import FormMultiSelect from '../components/Forms/multiSelect';
import Settings from '../components/Settings/onsale';


// dayjs
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
const weekday = require('dayjs/plugin/weekday')
dayjs.extend(weekday)
const advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)


export default function Home(props){

    const venueCapacityMaxValue = 250000
    const [ initDate, setInitDate ] = React.useState(dayjs()) 

    const [ reload,setReload ] = React.useState(1)

    const defaultRequestData = {
        attributesToHighlight: ['*'],
        facetsDistribution: ["venue_name","venue_city","venue_state","onsale_hours"],
        limit:20,
        offset:0,
        // sort: ['sale_start_date_time:asc']
    }
    const query = useQuery();
    const navigate = useNavigate()
    const mounted1 = React.useRef(null);
    const [ requestInit1, setRequestInit1 ] = React.useState(0);

    const [ isLoading, setLoading ] = React.useState(false)
    const [ mobileFilterState, setMobileFilterState ] = React.useState(false)

    const [ requestSearch, setRequestSearch ] = React.useState('')
    const [ requestData, setRequestData ] = React.useState(defaultRequestData)

    const [ responseData, setResponseData ] = React.useState([]);
    const [ totalResults, setTotalResults ] = React.useState(0)
    const [ filterData, setFilterData] = React.useState({})
    const [ filterDataCounts, setFilterDataCounts] = React.useState({})

    const [ page, setPage ] = React.useState(1);

    const [ isDetailsModalOpen, setDetailsModalIsOpen ] = React.useState(null)

    const [ dateStart, setDateStart ] = React.useState(null)
    const [ dateEnd, setDateEnd ] = React.useState(null)
    const [ focusedDateInput, setFocusedDateInput ] = React.useState(null)

    const [ venueCapacityMin, setVenueCapacityMin ] = React.useState('')
    const [ venueCapacityMax, setVenueCapacityMax ] = React.useState('')

    const [ showFavorites, setShowFavorites ] = React.useState(false);


    const client = new MeiliSearch({
        host: process.env.REACT_APP_APIURL_ONSALE,
        apiKey: process.env.REACT_APP_APIKEY_ONSALE
    })



    //
    // 
    const ExpandedComponent = ({ data }) => {
        return (
            <div className='bg-black bg-opacity-10'>
            <div className='ml-12 py-4'>{data.sales_name}</div>
            <ul className='flex flex-row justify-start items-center gap-5 ml-12'>
                <li>
                    <button className='text-xs py-4 text-gray-500 hover:text-purple-500 uppercase flex items-center' onClick={()=>setDetailsModalIsOpen(data)}><MoreVertical size={16} className="mr-1"/>Details</button>
                </li>
                <li>
                    <button type="button" className='text-xs py-4 text-gray-500 hover:text-purple-500 uppercase flex items-center'><Star size={16} className="mr-1"/>{data.favorite ? 'Remove from favourite' : 'Add to favourite'}</button>
                </li>
                <li>
                    <a href={data.url} target="_blank" rel="noreferrer" className='text-xs py-4 text-gray-500 hover:text-purple-500 uppercase flex items-center'><ExternalLink size={16} className='mr-1'/>Open link</a>
                </li>
            </ul>
            </div>
        )
    }


    // 
    // 
    const updateState = React.useCallback(state => console.log(state), [responseData]);


    // 
    // table configuration
    const columns = React.useMemo(() => {


        // 
        // 
        const _favouriteAdd = async(event_id, sale_id) => {

            setLoading(true)

            await client.index('events').updateDocuments([{
                sale_id: sale_id,
                favorite: true
            }])

            const add = await _apiPOST('/toggle_event_favourite', {event_id:event_id})
            if(add.error){
                _toaster('Error', 'Detected error in your request, try again later.', 'error')
                // return
            }

            await _timeout(1500)

            _toaster('Success', 'Event added to favorites successfully', 'success')

            // let newArray = [...responseData]
            // const index = newArray.findIndex(x => x.event_id===id)
            // if(index !== -1){
            //     newArray[index].favorite = true
            // }
            // setResponseData(newArray)

            setReload(x => x+1)
            return
        }


        // 
        // 
        const _favouriteDelete = async(event_id, sale_id) => {

            setLoading(true)

            await client.index('events').updateDocuments([{
                sale_id: sale_id,
                favorite: false
            }])

            const remove = await _apiPOST('/toggle_event_favourite', {event_id:event_id})
            if(remove.error){
                _toaster('Error', 'Detected error in your request, try again later.', 'error')
                // return
            }

            await _timeout(1500)

            _toaster('Success', 'Event removed from favorites successfully', 'success')

            // let newArray = [...responseData]
            // const index = newArray.findIndex(x => x.event_id===id)
            // if(index !== -1){
            //     newArray[index].favorite = false
            // }
            // setResponseData(newArray)

            setReload(x => x+1)
            return
        }


        // 
        // 
        const _favoriteButton = (event_id, sale_id, hasFavorite) => {
            return <button className={`w-8 h-8 flex justify-center items-center rounded-full ${!hasFavorite ? 'text-gray-300 ' : 'text-purple-500'}`} onClick={()=> hasFavorite ? _favouriteDelete(event_id, sale_id) : _favouriteAdd(event_id, sale_id)}><Star size={20}/></button>
        }


        //
        //
        Number.prototype.format = function(n, x) {
            var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
            return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
        };


        return [
            {
                name: <Star size={20} className=""/>,
                selector: row => _favoriteButton(row.event_id, row.sale_id, row.favorite),
                field: 'favorite',
                sortable: false,
                reorder: false,
                button: true,
                minWidth:'50px',
                maxWidth:'50px',
                style: {position:'sticky', left:'0', background:'#fff', zIndex:8}
            },
            {
                name: 'OnSale Date',
                selector: row => dayjs.unix(row.sale_start_date_time).format('MM/DD/YYYY hh:mm A'),
                field: 'sale_start_date_time',
                sortField: 'sale_start_date_time',
                sortable: true,
                reorder: false,
                minWidth:'170px',
                maxWidth:'170px'
            },
            {
                name: 'Time',
                selector: row => dayjs('2000-01-01 '+_pad(row.onsale_hours, 2)+':00').format('h A'),
                field: 'onsale_hours',
                sortable: true,
                reorder: false,
                minWidth:'150px',
                maxWidth:'150px'
            },
            {
                name: 'Event',
                selector: row => <button className='font-medium cursor-pointer text-purple-500' onClick={()=>setDetailsModalIsOpen(row)}>{row.name}</button>,
                field: 'name',
                sortable: true,
                reorder: false,
                minWidth:'400px',
                maxWidth:'400px',
                style: {position:'sticky', left:'50px', background:'#fff', zIndex:8}
            },
            {
                name: 'Venue',
                selector: row => <a href={'/?filter='+JSON.stringify({...requestData, filter:['venue_name="'+row.venue_name+'"']})} target="_blank" rel='noreferrer' className='flex flex-row items-center '>{row.venue_name} <ArrowUpRight size={16} className='text-purple-500 ml-1'/></a>,
                field: 'venue_name',
                sortable: true,
                reorder: true,
                minWidth:'240px',
                maxWidth:'240px',
                style: {position:'sticky', left:'400px', background:'#fff', zIndex:8}
            },
            {
                name: 'Rank',
                selector: row => row.rank,
                field: 'rank',
                reorder: true,
                maxWidth:'70px',
                minWidth:'70px',
                conditionalCellStyles: [
                    {
                        when: row => row.rank < 1,
                        style: {
                            backgroundColor: 'rgba(34,139,34, .1)',
                        }
                    },
                    {
                        when: row => row.rank >= 1 && row.rank < 2,
                        style: {
                            backgroundColor: 'rgba(34,139,34, .2)',
                        }
                    },
                    {
                        when: row => row.rank >= 2 && row.rank < 3,
                        style: {
                            backgroundColor: 'rgba(34,139,34, .3)',
                        }
                    },
                    {
                        when: row => row.rank >= 3 && row.rank < 4,
                        style: {
                            backgroundColor: 'rgba(34,139,34, .4)',
                        }
                    },
                    {
                        when: row => row.rank >= 4 && row.rank < 5,
                        style: {
                            backgroundColor: 'rgba(34,139,34, .5)',
                        }
                    },
                    {
                        when: row => row.rank >= 5 && row.rank < 6,
                        style: {
                            backgroundColor: 'rgba(34,139,34, .6)',
                        }
                    },
                    {
                        when: row => row.rank >= 6 && row.rank < 7,
                        style: {
                            backgroundColor: 'rgba(34,139,34, .7)',
                        }
                    },
                    {
                        when: row => row.rank >= 7 && row.rank < 8,
                        style: {
                            backgroundColor: 'rgba(34,139,34, .8)',
                        }
                    },
                    {
                        when: row => row.rank >= 8 && row.rank < 9,
                        style: {
                            backgroundColor: 'rgba(34,139,34, .9)',
                        }
                    },
                    {
                        when: row => row.rank >= 9,
                        style: {
                            backgroundColor: 'rgba(34,139,34, 1)',
                        },
                    }
                ]
            },
            {
                name: 'Event date',
                selector: row => dayjs.unix(row.date_time).format('MM/DD/YYYY hh:mm'),
                field: 'date_time',
                sortable: true,
                reorder: true,
                minWidth:'150px',
                maxWidth:'150px'
            },
            {
                name: 'City',
                selector: row => row.venue_city,
                field: 'venue_city',
                sortable: true,
                reorder: true,
                minWidth:'150px',
                maxWidth:'150px'
            },
            {
                name: 'State',
                selector: row => row.venue_state,
                field: 'venue_state',
                sortable: true,
                reorder: true,
                minWidth:'80px',
                maxWidth:'80px'
            },
            {
                name: 'Days',
                selector: row => <>{dayjs.unix(row.date_time).diff(dayjs(), 'day')}</>,
                field: 'date_time',
                sortable: true,
                reorder: true,
                minWidth:'100px',
                maxWidth:'100px'
            },
            {
                name: 'Cap',
                selector: row => row.venue_capacity ? row.venue_capacity.format() : 0,
                field: 'venue_capacity',
                sortable: true,
                reorder: true,
                minWidth:'100px',
                maxWidth:'100px'
            },
            {
                name: 'Cap Release',
                selector: row => {
                    const value = parseFloat(row.percent_capity_released)
                    if(value){
                        return <span>{Math.round(value*100)}%</span>
                    }
                    return ''
                },
                field: 'percent_capity_released',
                sortable: true,
                reorder: true,
                minWidth:'120px',
                maxWidth:'120px'
            },
            {
                name: 'Cap Remaining',
                selector: row => {
                    const value = parseFloat(row.capacity_remaining)
                    if(value){
                        return <span>{Math.round(value*100)}%</span>
                    }
                    return ''
                },
                field: 'capacity_remaining',
                sortable: true,
                reorder: true,
                minWidth:'150px',
                maxWidth:'150px'
            },
            {
                name: '+CapΔ Day',
                selector: row => {
                    const value = parseFloat(row.spotify_30day_growth)
                    return _calcChangePercent(value)
                },
                field: 'spotify_30day_growth',
                sortable: true,
                reorder: true,
                minWidth:'120px',
                maxWidth:'120px'
            },
            {
                name: '|Day Δ|',
                selector: row => {
                    const value = parseInt(row.absolute_change_daily)
                    return _calcChangeValue(value)
                },
                field: 'absolute_change_daily',
                sortable: true,
                reorder: true,
                minWidth:'100px',
                maxWidth:'100px'
            },
            {
                name: 'Day Cap Δ',
                selector: row => {
                    const value = parseFloat(row.percent_capity_change_daily)
                    return _calcChangePercent(value)
                },
                field: 'percent_capity_change_daily',
                sortable: true,
                reorder: true,
                minWidth:'120px',
                maxWidth:'120px'
            },
            {
                name: '|GA Day Δ|',
                selector: row => {
                    const value = parseInt(row.absolute_change_general_daily)
                    return _calcChangeValue(value)
                },
                field: 'absolute_change_general_daily',
                sortable: true,
                reorder: true,
                minWidth:'120px',
                maxWidth:'120px'
            },
            {
                name: '|Res Day Δ|',
                selector: row => {
                    const value = parseInt(row.absolute_change_reserved_daily)
                    return _calcChangeValue(value)
                },
                field: 'absolute_change_reserved_daily',
                sortable: true,
                reorder: true,
                minWidth:'120px',
                maxWidth:'120px'
            },
            {
                name: '|Wk Δ|',
                selector: row => {
                    const value = parseInt(row.absolute_change_weekly)
                    return _calcChangeValue(value)
                },
                field: 'absolute_change_weekly',
                sortable: true,
                reorder: true,
                minWidth:'100px',
                maxWidth:'100px'
            },
            {
                name: 'Wk Cap Δ',
                selector: row => {
                    const value = parseFloat(row.percent_capity_change_weekly)
                    return _calcChangePercent(value)
                },
                field: 'percent_capity_change_weekly',
                sortable: true,
                reorder: true,
                minWidth:'120px',
                maxWidth:'120px'
            },
            {
                name: '|GA Wk Δ|',
                selector: row => {
                    const value = parseInt(row.absolute_change_general_weekly)
                    return _calcChangeValue(value)
                },
                field: 'absolute_change_general_weekly',
                sortable: true,
                reorder: true,
                minWidth:'120px',
                maxWidth:'120px'
            },
            {
                name: '|Res Wk Δ|',
                selector: row => {
                    const value = parseInt(row.absolute_change_reserved_weekly)
                    return _calcChangeValue(value)
                },
                field: 'absolute_change_reserved_weekly',
                sortable: true,
                reorder: true,
                minWidth:'120px',
                maxWidth:'120px'
            },
            {
                name: 'Σ Left',
                selector: row => row.total_remaining,
                field: 'total_remaining',
                sortable: true,
                reorder: true,
                minWidth:'100px',
                maxWidth:'100px'
            },
            {
                name: 'Σ GA',
                selector: row => row.total_GA_remaining,
                field: 'total_GA_remaining',
                sortable: true,
                reorder: true,
                minWidth:'100px',
                maxWidth:'100px'
            },
            {
                name: 'Σ RES',
                selector: row => row.total_reserved_remaining,
                field: 'total_reserved_remaining',
                sortable: true,
                reorder: true,
                minWidth:'100px',
                maxWidth:'100px'
            },
            {
                name: 'Σ GA BALC',
                selector: row => row.total_general_balcony_remaining,
                field: 'total_general_balcony_remaining',
                sortable: true,
                reorder: true,
                minWidth:'120px',
                maxWidth:'120px'
            },
            {
                name: 'Σ LAWN',
                selector: row => row.total_general_lawn_remaining,
                field: 'total_general_lawn_remaining',
                sortable: true,
                reorder: true,
                minWidth:'100px',
                maxWidth:'100px'
            },
            {
                name: 'Σ PIT',
                selector: row => row.total_general_pit_remaining,
                field: 'total_general_pit_remaining',
                sortable: true,
                reorder: true,
                minWidth:'100px',
                maxWidth:'100px'
            },
            // {
            //     name: 'SPY 30 Δ',
            //     selector: row => row.spotify_30day_growth,
            //     field: 'spotify_30day_growth',
            //     sortable: true,
            //     reorder: true,
            //     minWidth:'100px',
            //     maxWidth:'100px'
            // },
            // {
            //     name: 'SPY 90 Δ',
            //     selector: row => row.spotify_90day_growth,
            //     field: 'spotify_90day_growth',
            //     sortable: true,
            //     reorder: true,
            //     minWidth:'100px',
            //     maxWidth:'100px'
            // },
            {
                name: '$∆Day',
                selector: row => {
                    const value = parseFloat(row.ppi_daily)
                    return _calcChangePercent(value)
                },
                field: 'ppi_daily',
                sortable: true,
                reorder: true,
                minWidth:'120px',
                maxWidth:'120px'
            },
            {
                name: '$∆Wk',
                selector: row => {
                    const value = parseFloat(row.ppi_weekly)
                    return _calcChangePercent(value)
                },
                field: 'ppi_weekly',
                sortable: true,
                reorder: true,
                minWidth:'100px',
                maxWidth:'100px'
            },
            {
                name: 'FCR',
                selector: row => {
                    const value = parseFloat(row.follower_cap_ratio)
                    return value ? value.format(2) : ''
                },
                field: 'follower_cap_ratio',
                sortable: true,
                reorder: true,
                minWidth:'120px',
                maxWidth:'120px'
            },
            {
                name: 'Σ Spot',
                selector: row => {
                    const value = parseInt(row.spotify_total_followers)
                    return value ? value.format() : ''
                },
                field: 'spotify_total_followers',
                sortable: true,
                reorder: true,
                minWidth:'120px',
                maxWidth:'120px'
            },
            {
                name: '30d Spot',
                selector: row => {
                    const value = parseFloat(row.spotify_30day_growth)
                    return _calcChangePercent(value)
                },
                field: 'spotify_30day_growth',
                sortable: true,
                reorder: true,
                minWidth:'100px',
                maxWidth:'100px'
            },
            {
                name: '90d Spot',
                selector: row => {
                    const value = parseFloat(row.spotify_90day_growth)
                    return _calcChangePercent(value)
                },
                field: 'spotify_90day_growth',
                sortable: true,
                reorder: true,
                minWidth:'100px',
                maxWidth:'100px'
            },
        ]
    }, []);


    // 
    // 
    const onKeyUp = (keyName, e, handle) => {

        const htmlElement = document.getElementsByClassName('tableRef')[0].children[0].children[0];

        if(keyName==='alt+right' && htmlElement){
            htmlElement.scrollIntoView({behavior:'smooth', block: "nearest", inline: "end"})
        }
        if(keyName==='alt+left' && htmlElement){
            htmlElement.scrollIntoView({behavior:'smooth', block: "nearest", inline: "start"})
        }
        if(keyName==='esc'){
            setDetailsModalIsOpen(null)
        }

    }


    //
    // update state on change value in field
    const _onFieldChange = (field, value) => {
        if(value==='none'){
            _resetFields(field)
            return
        }

        let newData = {...requestData}
        let filter = newData.filter
        let newFields = []


        if(!filter || filter === []){

            newFields.push(field+'="'+value+'"')
            newData.filter = newFields
            newData.offset = parseInt(0)
            setPage(1)
            setRequestData(newData)
            return

        }


        if(filter && filter !== []){

            newFields = filter
            const findIndex = newFields.findIndex(x => x.indexOf(field) !== -1)
            if(findIndex !== -1){

                const isEqual = newFields.find(x => x.indexOf(field+'="'+value+'"') !== -1)
                if(isEqual){

                    // remove if is equal to current selection
                    let newFieldsOR = newFields[findIndex].indexOf(' OR ') !== -1 ? newFields[findIndex].split(' OR ') : [filter[findIndex]]
                    const isEqualOR = newFieldsOR.find(x => x.indexOf(field+'="'+value+'"') !== -1)
                    if(isEqualOR){

                        // remove if is equal to current selection in same fineld name
                        newFields = newFields.filter(x => x.indexOf(field) === -1)
                        newFieldsOR = newFieldsOR.filter(x => x.indexOf(field+'="'+value+'"') === -1)
                        if(newFieldsOR.length>0){
                            newFields.push(newFieldsOR.join(' OR '))
                        }

                    }else{

                        // add new to OR array
                        newFields = newFields.filter(x => x.indexOf(field) === -1)
                        newFieldsOR.push(field+'="'+value+'"')
                        newFields.push(newFieldsOR.join(' OR '))

                    }

                }else{

                    let newFieldsOR = newFields[findIndex].indexOf(' OR ') !== -1 ? newFields[findIndex].split(' OR ') : [filter[findIndex]]
                    const isEqualOR = newFieldsOR.find(x => x.indexOf(field+'="'+value+'"') !== -1)
                    if(isEqualOR){

                        // remove if is equal to current selection in same fineld name
                        newFieldsOR = newFieldsOR.filter(x => x.indexOf(field+'="'+value+'"') === -1)

                    }else{

                        // add new to OR array
                        newFields = newFields.filter(x => x.indexOf(field) === -1)
                        newFieldsOR.push(field+'="'+value+'"')
                        newFields.push(newFieldsOR.join(' OR '))

                    }

                }

            }else{

                // if field not fount in current filter
                newFields.push(field+'="'+value+'"')

            }

            // return....
            if(newFields.length>0){
                newData.filter = [...newFields]
            }else{
                delete newData.filter
            }
            newData.offset = parseInt(0)
            setPage(1)
            setRequestData(newData)
            return

        }

    }


    //
    // check is field used in filter
    const _isFieldUsed = (field) => {

        if(field==='search'){
            return requestSearch==='' ? false : true
        }

        if(field==='dateStart'){
            return dateStart ? true : false
        }

        if(field==='dateEnd'){
            return dateEnd ? true : false
        }

        if(!requestData.filter && requestData.filter !== []){
            return false;
        }
        
        const find = requestData.filter.filter(x => x.search(field) !== -1)
        if(find.length===0){
            return false;
        }

        return true;
    }


    //
    // reset fields by all or specific field
    const _resetFields = (field) => {

        let newData = {...requestData}
        
        if(field==='all'){
            // delete newData.filter
            // delete newData.sort
            // setRequestData(newData)
            // setRequestSearch('')
            // setDateStart(null)
            // setDateEnd(null)
            // // setVenueCapacity([0,venueCapacityMax])
            // setVenueCapacityMin('')
            // setVenueCapacityMax('')
            window.location.reload(false)
            return
        }

        if(field==='search'){
            setRequestSearch('')
        }

        if(field==='sale_start_date_time'){
            setDateStart(null)
            setDateEnd(null)
        }

        if(field==='venue_capacity'){
            setVenueCapacityMin('')
            setVenueCapacityMax('')
        }

        const find = newData.filter.filter(x => { if(x.search(field) === -1){ return x }})
        newData.filter = find
        newData.offset = parseInt(0)
        setPage(1) 
        setRequestData(newData)
        
    }


    //
    //  sorting action
    const _handleSort = (column, sortDirection) => {
        let newData = {...requestData}
        newData.sort = [columns[column.id-1].field+':'+sortDirection]
        setRequestData(newData)
    }

    //
    // pagination action
    const _handlePageChange = (page, totalRows) => {
      let newRequest = {...requestData}
          newRequest.offset = (page-1)*requestData.limit
      setRequestData(newRequest);
      setPage(page)
    };


    //
    // changing limit per page action
	const _handlePerRowsChange = async (newPerPage, page) => {
		let newRequest = {...requestData}
        newRequest.limit = newPerPage
        newRequest.offset = 0
		setRequestData(newRequest);
	};


    //
    //
    const _isResetVisible = () => {
        if(requestData.filter && requestData.filter.length>0){
            return true
        }
        if(requestSearch !== ''){
            return true
        }
        return false
    }


    //
    //
    const _updateRequestByVenueCapacityMin = (value) => {

        let newData = {...requestData}
        let range = []

        if(value){
            setVenueCapacityMin(value)
            range.push('venue_capacity >= '+value)
        }else{
            setVenueCapacityMin('')
        }
        
        if(venueCapacityMax !== ''){
            range.push('venue_capacity <= '+venueCapacityMax)
        }

        
        const filteredDatefield = newData.filter ? newData.filter.filter(x => x.search('venue_capacity') === -1) : []
        if(range.length>0){
            newData.filter = newData.filter ? [...filteredDatefield, ...range] : [...range]
        }else{
            newData.filter = newData.filter ? [...filteredDatefield] : []
        }
        newData.offset = parseInt(0)
        setPage(1) 
        setRequestData(newData)

    }


    //
    //
    const _updateRequestByVenueCapacityMax = (value) => {

        let newData = {...requestData}
        let range = []

        if(value){
            setVenueCapacityMax(value)
            range.push('venue_capacity <= '+value)
        }else{
            setVenueCapacityMax('')
        }

        if(venueCapacityMin !== ''){
            range.push('venue_capacity >= '+venueCapacityMin)
        }

        
        const filteredDatefield = newData.filter ? newData.filter.filter(x => x.search('venue_capacity') === -1) : []
        if(range.length>0){
            newData.filter = newData.filter ? [...filteredDatefield, ...range] : [...range]
        }else{
            newData.filter = newData.filter ? [...filteredDatefield] : []
        }
        newData.offset = parseInt(0)
        setPage(1) 
        setRequestData(newData)

    }


    //
    //
    const _showFavoritesOnly = (state) => {
        let newData = {...requestData}
        if(state===true){
            newData.filter = newData.filter ? [...newData.filter, 'favorite = "true"'] : ['favorite = "true"']
        }else{
            newData.filter = newData.filter.filter(x => x.search('favorite = "true"') === -1)
        }
        setShowFavorites(state);
        setRequestData(newData)
    }


    //
    // on change date  -> formating and update state
    React.useEffect(()=>{

        let newData = {...requestData}
        let range = []

        if(dateStart){
            range.push('sale_start_date_time >= '+dayjs(dateStart).startOf('day').unix())
        }
        if(dateEnd){
            range.push('sale_start_date_time <= '+dayjs(dateEnd).endOf('day').unix())
        }

        if(!dateStart && !dateEnd && !mounted1.current){
            let fromDate = dayjs().startOf('day')
            let toDate = dayjs().endOf('day')
            range.push('sale_start_date_time >= '+dayjs(fromDate).unix())
            range.push('sale_start_date_time <= '+dayjs(toDate).unix())
            setDateStart(moment(fromDate.format())); 
            setDateEnd(moment(toDate.format()))
        }

        if(range.length>0){
            const filteredDatefield = newData.filter ? newData.filter.filter(x => x.search('sale_start_date_time') === -1) : []
            // console.log('filteredDatefield',filteredDatefield)
            newData.filter = newData.filter ? [...filteredDatefield, ...range] : [...range]
            newData.offset = parseInt(0)
            setPage(1) 
            setRequestData(newData)
        }
        
        return () => mounted1.current = true;
    },[dateStart, dateEnd])



    // 
    // 
    React.useEffect(()=>{
        const isFitlerParams = query.get("filter") && query.get("filter") !== '' ? true : false
        if(isFitlerParams){
            setRequestData(JSON.parse(query.get("filter")))
            navigate({
                filter: '',
                replace: true
            })
        }
    },[])



    //
    // fetching data on load and on filter change
    React.useEffect(()=>{
        const _fetching = async() => {

            // console.log('REQUEST', requestSearch, requestData)

            setLoading(true)

            const index = client.index('events')
            const search = await index.search(requestSearch, requestData)


            let reindexData = [...search.hits]
            for (let index = 0; index < search.hits.length; index++) {
                const item = search.hits[index];
                item.rank = _rank(item)
            }

            if(requestData.sort){
                setResponseData(reindexData)
            }else{
                setResponseData(reindexData.sort((a,b) => b.rank - a.rank ))
            }
            setFilterDataCounts(search.facetsDistribution)
            setTotalResults(search.nbHits)
            setInitDate(dayjs())

            // console.log('RESPONSE', search)

            setLoading(false)
        }
        if(requestInit1>0){
         _fetching()
        }
        return () => setRequestInit1(requestInit1+1)
    },[requestSearch, requestData, reload])



    //
    // fetching filter options on load and on filter change
    React.useEffect(()=>{
        const _fetching = async() => {
            const index = client.index('events')
            let newRequestData = {...defaultRequestData}
            if(requestData.filter){
                const find = requestData.filter.filter(x => { if(x.search('sale_start_date_time') !== -1){ return x }})
                if(find.length>0){
                    newRequestData = {...newRequestData, filter:find}
                }
            }

            const search = await index.search(requestSearch, newRequestData)
            setFilterData(search.facetsDistribution)
        }
        if(requestInit1>0){
         _fetching()
        }
        return () => setRequestInit1(requestInit1+1)
    },[requestSearch, requestData])


    // load venue capacity from saved search
    React.useEffect(()=>{
        if(!venueCapacityMax && !venueCapacityMax && requestData.filter){
            
            const findMin = requestData.filter.filter(x => x.search('venue_capacity >=') !== -1)
            const findMax = requestData.filter.filter(x => x.search('venue_capacity <=') !== -1)
            if(findMin.length>0){
                const _readMin = findMin[0].split(' >= ')[1]
                setVenueCapacityMin(parseInt(_readMin))
            }

            if(findMax.length>0){
                const _readMax = findMax[0].split(' <= ')[1]
                setVenueCapacityMax(parseInt(_readMax))
            }
            
        }else{

            if(requestData.filter){
                const findMin = requestData.filter.filter(x => x.search('venue_capacity >=') !== -1)
                const findMax = requestData.filter.filter(x => x.search('venue_capacity <=') !== -1)
                if(findMin.length>0){
                    const _readMin = findMin[0].split(' >= ')[1]
                    setVenueCapacityMin(parseInt(_readMin))
                }else{
                    setVenueCapacityMin('')
                }

                if(findMax.length>0){
                    const _readMax = findMax[0].split(' <= ')[1]
                    setVenueCapacityMax(parseInt(_readMax))
                }else{
                    setVenueCapacityMax('')
                }
            }else{
                setVenueCapacityMin('')
                setVenueCapacityMax('')
            }
        }
    },[requestData])



    //
    // set / get fitlerable attributes
    // React.useEffect(()=>{
    //     const _init = async() => {
    //         // await client.index('artists').updateSearchableAttributes([
    //         //     'name'
    //         // ])
    //         // await client.index('artists').updateFilterableAttributes([
    //         //     'num_followers',
    //         //     'favorite'
    //         // ])
    //         await client.index('artists').updateSortableAttributes([
    //             'follower_cap_ratio'
    //         ])
    //         const index1 = await client.index('artists').getFilterableAttributes()
    //         const index2 = await client.index('artists').getSearchableAttributes()
    //         const index3 = await client.index('artists').getSortableAttributes()
    //         console.log('getFilterableAttributes', index1)
    //         console.log('getSearchableAttributes', index2)
    //         console.log('getSearchableAttributes', index3)
    //     }
    //     _init()
    // },[])



    //
    //
    return (
        <Fragment>
        <Helmet>
            <title>{`OnSale | ${process.env.REACT_APP_SITENAME}`}</title>
        </Helmet>

        <Layout>

            <div className='wrapper'>
                <Header
                    switchComponent={
                        <>
                            <input type="checkbox" name="showFavorites" onChange={()=>_showFavoritesOnly(!showFavorites)}/>
                            <label><span>Watchlist</span></label>
                        </>
                    }
                    mobileStateAction={setMobileFilterState}
                >
                    <div className={`mob ${!mobileFilterState ? 'flex h-auto' : 'flex h-0 opacity-0'}`}>
                        <div>
                            <div className='formGroup mb-9'>
                                <label>Search</label> 
                                <div>
                                    <input 
                                        type="text"
                                        name="search"
                                        onChange={(e)=>setRequestSearch(e.target.value)}
                                        value={requestSearch}
                                        className='formControl'
                                        autoComplete='off'
                                    />
                                    {_isFieldUsed('search') && <button className='reset' onClick={()=>_resetFields('search')}><X size={18} color="#fff"/></button>}
                                </div>
                            </div>
                            <div className='formGroup mb-3'> 
                                <label>Date</label> 
                                <div>
                                <DateRangePicker
                                    startDate={dateStart}
                                    startDateId="your_unique_start_date_id"
                                    endDate={dateEnd}
                                    endDateId="your_unique_end_date_id"
                                    onDatesChange={({ startDate, endDate }) => { setDateStart(startDate); setDateEnd(endDate) }}
                                    focusedInput={focusedDateInput}
                                    onFocusChange={focusedInput => setFocusedDateInput(focusedInput)}
                                    isOutsideRange={() => false}
                                    minimumNights={0}
                                    // minDate={moment(dayjs().subtract(3, 'month').format())}
                                    // maxDate={moment(dayjs().add(3, 'month').format())}
                                />
                                {_isFieldUsed('date_time') && <button className='reset' onClick={()=>_resetFields('date_time')}><X size={18} color="#fff"/></button>}
                                </div>
                            </div>
                            <div className='formGroup'>
                                <label>Venue capacity</label> 
                                <div className='w-full'>
                                    <div className='w-full flex flex-row justify-start items-center'>
                                        <input
                                            type="number"
                                            name="venue_capacty_min"
                                            step="100"
                                            min="0"
                                            max={venueCapacityMaxValue}
                                            className='formControl rounded-none'
                                            value={venueCapacityMin}
                                            onChange={(e)=>_updateRequestByVenueCapacityMin(e.target.value)}
                                        />
                                        <div className='formControl w-7 rounded-none px-0'>
                                            <svg className="DateRangePickerInput_arrow_svg DateRangePickerInput_arrow_svg_1" focusable="false" viewBox="0 0 1000 1000"><path d="M694 242l249 250c12 11 12 21 1 32L694 773c-5 5-10 7-16 7s-11-2-16-7c-11-11-11-21 0-32l210-210H68c-13 0-23-10-23-23s10-23 23-23h806L662 275c-21-22 11-54 32-33z"></path></svg>
                                        </div>
                                        <input
                                            type="number"
                                            name="venue_capacty_max"
                                            step="100"
                                            min="0"
                                            max={venueCapacityMaxValue}
                                            className='formControl rounded-none'
                                            value={venueCapacityMax}
                                            onChange={(e)=>_updateRequestByVenueCapacityMax(e.target.value)}
                                        />
                                    </div>
                                    {_isFieldUsed('venue_capacity') && <button className='reset' onClick={()=>_resetFields('venue_capacity')}><X size={18} color="#fff"/></button>}
                                </div>
                            </div>
                        </div>
                        <div className='w-full lg:max-w-[200px]'>
                            <FormMultiSelect
                                label="On sale hours"
                                field={'onsale_hours'}
                                request={requestData}
                                data={filterData.onsale_hours}
                                counts={filterDataCounts.onsale_hours}
                                isUsed={()=>_isFieldUsed('onsale_hours')}
                                onChange={(item)=>_onFieldChange('onsale_hours', item)}
                                onReset={()=>_resetFields('onsale_hours')}
                            />
                        </div>
                        <div>
                            <FormMultiSelect
                                label="Venue name"
                                field={'venue_name'}
                                request={requestData}
                                data={filterData.venue_name}
                                counts={filterDataCounts.venue_name}
                                isUsed={()=>_isFieldUsed('venue_name')}
                                onChange={(item)=>_onFieldChange('venue_name', item)}
                                onReset={()=>_resetFields('venue_name')}
                            />
                        </div>
                        <div>
                            <FormMultiSelect
                                label="Venue city"
                                field={'venue_city'}
                                request={requestData}
                                data={filterData.venue_city}
                                counts={filterDataCounts.venue_city}
                                isUsed={()=>_isFieldUsed('venue_city')}
                                onChange={(item)=>_onFieldChange('venue_city', item)}
                                onReset={()=>_resetFields('venue_city')}
                            />
                        </div>
                        <div className='w-full lg:max-w-[300px]'>
                            <Settings
                                currentRequest={requestData}
                                currentTable={columns}
                                loadRequest={(r)=> setRequestData(r)}
                            />
                            <div className='formGroup mt-3'>
                                {_isResetVisible() && <button className='button primary w-full' onClick={()=>_resetFields('all')}><X size={18} color="#fff"/> Clear all</button>}
                            </div>
                        </div>
                    </div>
                </Header>

                <div className="main tableRef onsales">
                    <DataTable
                        columns={columns}
                        data={responseData}
                        fixedHeader
                        onSort={_handleSort}
                        onSelectedRowsChange={updateState}
                        sortServer
                        pagination
                        paginationServer
                        paginationDefaultPage={page}
                        paginationTotalRows={totalResults}
                        paginationPerPage={requestData.limit}
                        onChangeRowsPerPage={_handlePerRowsChange}
                        onChangePage={_handlePageChange}
                        paginationRowsPerPageOptions={[20,50,100,200]}
                        expandableRows 
                        expandableRowsComponent={ExpandedComponent}
                        keyField={'sale_id'}
                    />
                </div>

                {isLoading && 
                    <div className='loading'>
                        <span className="icon">
                            <Loader size={20} className="animate-spin"/>
                        </span>
                        <span className='text'>Loading...</span>
                    </div>
                }
            </div>


            <Modal
                status={isDetailsModalOpen ? true : false}
                showClose={true}
                close={()=>setDetailsModalIsOpen(null)}
                size="large"
            >
                {isDetailsModalOpen && <DetailsOnSale data={isDetailsModalOpen}/>}

                <Hotkeys 
                    keyName="esc" 
                    filter={(event) => {
                        return true;
                    }}
                    onKeyDown={null}
                    onKeyUp={onKeyUp}
                />
            </Modal>


            <Hotkeys 
                keyName="alt+right,alt+left" 
                filter={(event) => {
                    return true;
                }}
                onKeyDown={null}
                onKeyUp={onKeyUp}
            />
            
        </Layout>
            
        </Fragment>
    );

}
