import React, { Fragment } from 'react'
import { XCircle, CheckSquare, Square, X } from 'react-feather';
import dayjs from 'dayjs';


// controllers
import { _pad } from '../../controllers/Helpers'


//
//
export default function FormMultiSelect(props) {

    const [ subSearch, setSubSearch ] = React.useState('')
    const [ whichCount, setWhichcount ] = React.useState(true)


    //
    // verify is checkbox checked
    const _isChecked = (value) => {
        if(!props.request.filter){
            return false;
        }
        const find = props.request.filter.filter(x => x.indexOf([props.field]+'="'+value+'"') !== -1)
        // const find = props.request.filter.filter(x => {
            
        //     // if(props.field==='venue_name'){
        //     //     console.log('_isChecked', [props.field]+'="'+value+'"',x, [props.field]+'="'+value+'"'===x)
        //     // }
        //     // return x.search([props.field]+'="'+value+'"') !== -1
        //     return x.indexOf([props.field]+'="'+value+'"') !== -1
        //     // return [props.field]+'="'+value+'"'===x
        // })
        
        if(find.length>0){
            return true;
        }
        
        return false;
    }


    //
    //
    const _whichCount = () => {

        if(props.request.filter){

            if(Object.keys(props.request.filter).length>1){
                setWhichcount(false)
            }else{
                const isCurrentField = Object.values(props.request.filter).find(x => { return x.search(props.field) !== -1 ? x : null })
                if(isCurrentField){
                    setWhichcount(true)
                }else{
                    setWhichcount(false)
                }
            }

        }

    }


    //
    //
    const _count = (value) => {

        if(Object.keys(props.data).length===0){
            return
        }

        const findIndex = props.counts ? Object.keys(props.counts).findIndex(x => x===value) : -1
        if(findIndex !== -1){
            return Object.values(props.counts)[findIndex]
        }

        return 0
    }


    // 
    //  
    const _formatData = (rawData) => {

        let newData = []
        Object.keys(rawData).map((item, i)=>{
            const count = whichCount ? Object.values(rawData)[i] : _count(item)
            newData.push({
                name:item,
                count: count ? count : parseInt(0),
                checked: _isChecked(item)
            })
        })
        return newData.sort((a,b) => b.count - a.count ).sort((a,b) => b.checked - a.checked )
    }


    //
    //
    React.useEffect(()=>{
        _whichCount()
    },[props.request])


    //
    //
    return (
        <Fragment>
            <div className='formGroup'>
                
                {props.label && <label>{props.label}</label>}
                
                <div>
                    <div className='multiSelect'>

                        <div className='subFilter'>
                            <input type="text" name="venue_name_filter" placeholder="Type to filter..." value={subSearch} onChange={(e)=>setSubSearch(e.target.value)} autoComplete="off"/>
                            {subSearch.length>0 && <button onClick={()=>setSubSearch('')}><XCircle size={18}/></button>}
                        </div>
                        {props.data && _formatData(props.data).map((item, i)=>{
                            
                            const search = item.name.toLowerCase().indexOf(subSearch.toLowerCase())
                            if(subSearch.length>0 && search === -1){
                                return null;
                            }
                            return(
                                <div className='relative item' key={i}>
                                    <input type="checkbox" name={'venue_name'+i} onChange={()=>props.onChange(item.name)}/>
                                    {item.checked ? <CheckSquare size={28} className='text-purple-500'/> : <Square size={28} className='text-gray-500'/>}
                                    <div>
                                        {props.field==='onsale_hours' ? dayjs('2000-01-01 '+_pad(item.name, 2)+':00').format('h A') : item.name}<span className={!item.count ? 'opacity-50' : ''}>{item.count ? item.count : '0'}</span>
                                    </div>
                                </div>
                            )
                        
                        })}
                    
                    </div>
                    {props.isUsed() && <button className='reset' onClick={()=>props.onReset()}><X size={18} color="#fff"/></button>}
                
                </div>
            </div>
        </Fragment>
    );
};
