import React, { Fragment } from 'react'
import{ Loader, MoreHorizontal, X, Edit } from 'react-feather'
import dayjs from 'dayjs'
import DataTable from 'react-data-table-component';
import { Form } from '@unform/web'
import * as Yup from 'yup';
import Hotkeys from 'react-hot-keys'


// controllers
import { _apiGETone, _apiPOST, _apiDELETE, _apiPUT } from '../../controllers/Api';
import { _toaster } from '../../controllers/Helpers'


// components
import Modal from '../Modal';
import InputText from '../Forms/InputText';


// dayjs
const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)


  
//
export default function SettingsOffsale(props) {

    const formRefSave = React.useRef(null);
    const formRefUpdate = React.useRef(null);

    const [ isLoading, setLoading ] = React.useState(false)

    const [ savedList, setSavedList ] = React.useState([])
    const [ savedListFiltered, setSavedListFiltered ] = React.useState([])
    const [ selected, setSelected ] = React.useState(null)

    const [ modalSelect, setModalSelect ] = React.useState(false)
    const [ modalSave, setModalSave ] = React.useState(false)
    const [ modalUpdate, setModalUpdate ] = React.useState(false)

    // form inputs
    const [ search, setSearch ] = React.useState('')
    // const [ opt1, setOpt1 ] = React.useState(false)


    // 
    // 
    const _actionLoad = (data) => {
        props.loadRequest(data.criteria.request)
        setSelected(data)
        setModalSelect(false)
    }


    //
    //
    const _actionReset = () => {
        setSelected(null)
    }


    // 
    // 
    const _actionButtons = (data) => {
        return(
            <div className='flex justify-end items-center gap-2'>
                <button 
                    className='w-10 h-10 flex justify-center items-center rounded-full bg-transparent transition ease-in-out hover:bg-black hover:text-white'
                    type='button'
                    onClick={()=>_loadModalUpdate(data)}
                >
                    <Edit size={15} />
                </button>
                <button 
                    className='w-10 h-10 flex justify-center items-center rounded-full text-red-500 bg-transparent transition ease-in-out hover:bg-black hover:text-white'
                    type='button'
                    onClick={()=>_delete(data.id)}
                >
                    <X size={20} />
                </button>
            </div>
        )
    }


    // 
    // table configuration
    const columns = [
        {
            name: 'Title',
            selector: row => <button onClick={()=>_actionLoad(row)}>{row.name}</button>,
            field: 'name',
            sortable: true,
            minWidth:'50%'
        },
        {
            name: 'Modified',
            selector: row => dayjs(row.modified).format('MM/DD/YYYY hh:mm A'),
            field: 'modified',
            sortable: true,
        },
        {
            name: 'Action',
            selector: row => _actionButtons(row),
            field: 'actions',
            right:true
        }
    ]



    //
    //
    const handleSubmitSave = async(data) => {

        try {

            // Remove all previous errors
            formRefSave.current.setErrors({});
            const schema = Yup.object().shape({
                name: Yup.string().required(),
            });
            await schema.validate(data, {
              abortEarly: false,
            });
      
      
            // Validation passed
            setLoading(true)
             
            let newRequest = {...props.currentRequest}
            newRequest.filter = newRequest.filter.filter(x => x.search('sale_start_date_time') === -1)
            newRequest.offset = 0

            const newData = {
                name: data.name,
                criteria: {
                    request:newRequest
                }
            }

            const _post = await _apiPOST('/search-criteria-offsales', newData)
            if(_post.error){
                _toaster('Error', 'Detected error in your request, try again later.', 'error')
                setLoading(false)
                return
            }

            _toaster('Success', '"'+data.name+'" has been saved successfully.', 'success')
            setModalSave(false)
            setSelected(_post.search_criteria)
            await _get()
      
        } catch (err) {
      
            // console.log('err',err)
            const validationErrors = {};
            if (err instanceof Yup.ValidationError) {
              err.inner.forEach(error => {
                validationErrors[error.path] = error.message;
              });
              formRefSave.current.setErrors(validationErrors);
            }
      
        }
        
    }



    //
    //
    const handleSubmitUpdate = async(data) => {

        try {

            // Remove all previous errors
            formRefUpdate.current.setErrors({});
            const schema = Yup.object().shape({
                name: Yup.string().required(),
            });
            await schema.validate(data, {
              abortEarly: false,
            });
      
      
            // Validation passed
            setLoading(true)
             
            let newRequest = {...props.currentRequest}
            newRequest.filter = newRequest.filter.filter(x => x.search('sale_start_date_time') === -1)
            newRequest.offset = 0

            const newData = {
                search_id: modalUpdate.id,
                name: data.name,
                criteria: {
                    request:newRequest
                }
            }

            const _post = await _apiPUT('/update-search-criteria', newData)
            if(_post.error){
                _toaster('Error', 'Detected error in your request, try again later.', 'error')
                setLoading(false)
                return
            }

            if(selected && selected.id===modalUpdate.id){
                setSelected({...selected, name:data.name})
            }

            _toaster('Success', '"'+modalUpdate.name+'" has been updated successfully.', 'success')
            setModalUpdate(false)
            await _get()
      
        } catch (err) {
      
            // console.log('err',err)
            const validationErrors = {};
            if (err instanceof Yup.ValidationError) {
              err.inner.forEach(error => {
                validationErrors[error.path] = error.message;
              });
              formRefUpdate.current.setErrors(validationErrors);
            }
      
        }
        
    }



    //
    //
    const handleOverwrite = async() => {

        setLoading(true)
             
        let newRequest = {...props.currentRequest}
        newRequest.filter = newRequest.filter.filter(x => x.search('sale_start_date_time') === -1)
        newRequest.offset = 0

        const newData = {
            search_id: selected.id,
            name: selected.name,
            criteria: {
                request:newRequest
            }
        }

        const _post = await _apiPUT('/update-search-criteria', newData)
        if(_post.error){
            _toaster('Error', 'Detected error in your request, try again later.', 'error')
            setLoading(false)
            return
        }

        _toaster('Success', '"'+selected.name+'" has been updated successfully.', 'success')
        setLoading(false)
        
    }


    // 
    // 
    const _get = async() => {

        setLoading(true)
        const get = await _apiGETone('/searches_offsales')
        if(get.error){
            setLoading(false)
            _toaster('Error', 'Detected error in your request, try again later.', 'error')
            return
        }
        setSavedList(get.data)
        setSavedListFiltered(get.data)
        setLoading(false)
    }


    // 
    // 
    const _delete = async(id) => {

        setLoading(true)
        const get = await _apiDELETE('/delete-search-criteria', {data:{search_id:id}})
        if(get.error){
            setLoading(false)
            _toaster('Error', 'Detected error in your request, try again later.', 'error')
            return
        }
        await _get()
        setLoading(false)
    }


    // 
    // 
    const _loadModalSave = () => {
        // setOpt1(false)
        setModalSave(true)
    }


    // 
    // 
    const _loadModalUpdate = (data) => {
        setModalSelect(false)
        // setOpt1(data.criteria.table ? true : false)
        setModalUpdate(data)
        setTimeout(()=>{
            formRefUpdate.current.setFieldValue('name', data.name)
        },200)
    }


    // 
    // 
    const onKeyUp = (keyName, e, handle) => {
        // console.log(keyName, e)
        if(keyName==='esc'){
            setModalUpdate(false)
            setModalSave(false)
            setModalSelect(false)
        }

    }


    //
    //  sorting action
    const _handleSort = (column, sortDirection) => {
        if(sortDirection==='desc'){
            setSavedListFiltered(savedList.sort((a,b) => a[column.field].localeCompare(b[column.field]) ))
        }else{
            setSavedListFiltered(savedList.sort((a,b) => b[column.field].localeCompare(a[column.field]) ))
        }
    }


    // 
    // 
    React.useEffect(()=>{

        let newList = [...savedList]
        // console.log(newList.filter(x => x.name.search(search) !== -1))
        setSavedListFiltered(newList.filter(x => x.name.toLowerCase().search(search.toLowerCase()) !== -1))

    },[search])


    // 
    // 
    React.useEffect(()=>{
        const init = async() => {
            await _get()
        }

        if(modalSelect){
            init()
        }
    },[modalSelect])


    // 
    // 
    return(
        <Fragment>
            <div className='relative max-w-full'>

                <div className={`min-h-[164px] ${isLoading ? 'opacity-50' : ''}`}>
                    <div className='formGroup mb-2'>
                        <label>Saved search</label> 
                        <button className='button black flex-col items-start w-full' type='button' onClick={()=>setModalSelect(true)}>
                            <small>Selected...</small>
                            {
                                !selected ? 
                                <span>none</span>
                                :
                                <p className='w-full text-left truncate ...'>{selected.name}</p>
                            }
                        </button>
                    </div>
                    {selected && 
                    <div className='formGroup flex justify-end w-full'>
                        <button className='text-xs py-1 text-white hover:text-purple-500 flex flex-row items-center' onClick={()=>_actionReset()}>
                            <X size={14} className="mr-1"/>clear
                        </button>
                    </div>
                    }
                    {selected && 
                    <div className='formGroup flex justify-end w-full'>
                        <button className='text-xs py-1 text-white hover:text-purple-500 flex flex-row items-center' onClick={()=>handleOverwrite()}>
                            <MoreHorizontal size={14} className="mr-1"/>overwrite saved search
                        </button>
                    </div>
                    }
                    <div className='formGroup flex justify-end w-full'>
                        <button className='text-xs py-1 text-white hover:text-purple-500 flex flex-row items-center' type='button' onClick={()=>_loadModalSave()}>
                            <MoreHorizontal size={14} className="mr-1"/>save as  new
                        </button>
                    </div>
                </div>

                {isLoading && 
                <div className='loading bg-transparent shadow-none'>
                    <span className="text-purple-500">
                        <Loader size={30} className="animate-spin"/>
                    </span>
                </div>
                }

            </div>

            <Modal
                status={modalUpdate ? true : false}
                showClose={true}
                close={()=>setModalUpdate(null)}
                size="small"
            >
                <Form ref={formRefUpdate} onSubmit={handleSubmitUpdate}>
                    <h4 className='pl-4 -mt-1'>Update selected search settigns</h4>
                    <hr className='my-4'/>
                    <div className='px-4'>
                        <InputText name="name" placeholder="Name your save slot..." className='light'/>
                    </div>
                    <hr className='mt-6'/>
                    <div className='p-4 flex justify-between w-full gap-2'>
                        <button className='button gray w-20' type='button' onClick={()=>setModalUpdate(false)}>
                            Cancel
                        </button>
                        <button className='button primary w-20' type='submit'>
                            Save
                        </button>
                    </div>
                </Form>
                {isLoading && 
                    <div className='loading'>
                        <span className="icon">
                            <Loader size={20} className="animate-spin"/>
                        </span>
                        <span className='text'>Loading...</span>
                    </div>
                }

                <Hotkeys 
                    keyName="esc" 
                    filter={(event) => {
                        return true;
                    }}
                    onKeyDown={null}
                    onKeyUp={onKeyUp}
                />
            </Modal>

            <Modal
                status={modalSave ? true : false}
                showClose={true}
                close={()=>setModalSave(null)}
                size="small"
            >
                <Form ref={formRefSave} onSubmit={handleSubmitSave}>
                    <h4 className='pl-4 -mt-1'>Save current search settigns</h4>
                    <hr className='my-4'/>
                    <div className='px-4'>
                        <InputText name="name" placeholder="Name your save slot..." className='light'/>
                    </div>
                    <hr className='mt-6'/>
                    <div className='p-4 flex justify-between w-full gap-2'>
                        <button className='button gray w-20' type='button' onClick={()=>setModalSave(false)}>
                            Cancel
                        </button>
                        <button className='button primary w-20' type='submit'>
                            Save
                        </button>
                    </div>
                </Form>
                {isLoading && 
                    <div className='loading'>
                        <span className="icon">
                            <Loader size={20} className="animate-spin"/>
                        </span>
                        <span className='text'>Loading...</span>
                    </div>
                }

                <Hotkeys 
                    keyName="esc" 
                    filter={(event) => {
                        return true;
                    }}
                    onKeyDown={null}
                    onKeyUp={onKeyUp}
                />
            </Modal>

            <Modal
                status={modalSelect ? true : false}
                showClose={true}
                close={()=>setModalSelect(null)}
                size="large"
            >
                <div className='min-h-[200px]'>
                {savedList.length>0 && 
                    <>
                    <div className='-mt-7 p-4 flex justify-start items-center'>
                        <div className='min-w-sm min-w-[240px] max-w-md'>
                            <input 
                                onChange={(e)=>setSearch(e.target.value)} 
                                value={search} 
                                type="text" 
                                className={`bg-white h-10 w-full border-2 border-gray-100 px-3 rounded-sm text-sm outline-none focus:border-purple-500 font-light`}
                                placeholder="Search saved list..."
                            />
                        </div>
                        {search !== '' &&
                            <button 
                                className='icon p-2 bg-gray-100'
                                type='button'
                                onClick={()=>setSearch('')}
                            >
                                <X size={24} />
                            </button>
                        }
                    </div>
                    <div className='w-full flex flex-col flex-1 min-h-[700px] max-h-[700px] overflow-y-auto shadow-md'>
                        <DataTable
                            columns={columns}
                            data={savedListFiltered}
                            highlightOnHover
                            fixedHeader
                            onSort={_handleSort}
                        />
                    </div>
                    </>
                }
                {!isLoading && savedList.length===0 && <p className='px-6 py-10 w-full text-center'>There are no saved records to display</p>}
                </div>
                {isLoading && 
                    <div className='loading'>
                        <span className="icon">
                            <Loader size={20} className="animate-spin"/>
                        </span>
                        <span className='text'>Loading...</span>
                    </div>
                }

                <Hotkeys 
                    keyName="esc" 
                    filter={(event) => {
                        return true;
                    }}
                    onKeyDown={null}
                    onKeyUp={onKeyUp}
                />
            </Modal>

        </Fragment>
    )

}