import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async'
import { Form } from '@unform/web'
import * as Yup from 'yup';
import { Loader } from 'react-feather'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';


// controllers
import _actions from '../controllers/redux/actions';
import { _toaster } from '../controllers/Helpers'


// components 
import Layout from '../components/Layout'
import InputText from '../components/Forms/InputText';


export default function PageLogin(props){

    const formRef = React.useRef(null);
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [ isLoading, setLoading ] = React.useState(false)



    //
    //
    const handleSubmit = async(data) => {

        try {

            // Remove all previous errors
            formRef.current.setErrors({});
            const schema = Yup.object().shape({
                username: Yup.string().required(),
                password: Yup.string().required(),
            });
            await schema.validate(data, {
              abortEarly: false,
            });

            setLoading(true)
      
            if(data.username==='rocket88' && data.password==='C4hdsoUrGdnofA'){
                await dispatch(_actions.auth.authUpdate({token:'sjkdbak;jr3894t397'}))
                navigate('/', { replace: true })
            }else{
                _toaster('Error', 'Invalid username or password, try again.', 'error')
            }
      
            // // Validation passed
            // setLoading(true)
            // const create = await _createCustomer(data.walletAddress, data.parentToken)
            // if(create){
            //     setLoading(false)
            //     _log('handleSubmit',create)
            //     let connect = await _connectionStatus()
            //     if(connect.status){
            //         const date = dayjs().format('YYYY-MM-DD HH:mm:ss')
            //         const sendToApi = await _apiPOST('/store-token', { token_id:parseInt(connect.tokenId), privacy_consent_date:date})
            //         const balances = await _getBalance(connect.tokenId)
            //         const commissions = await _getCommissions(connect.tokenId)
            //         await dispatch(_actions.auth.authUpdate({ 
            //             ...connect,
            //             balance: balances ? balances : null,
            //             commission: commissions ? commissions : 0
            //         }))
            //     }else{
            //         await dispatch(_actions.auth.authUpdate({ 
            //             ...connect
            //         }))
            //     }
            // }else{
            //     setLoading(false)
            // }

            setLoading(false)
      
        } catch (err) {
      
            // _log('err',err)
            const validationErrors = {};
            if (err instanceof Yup.ValidationError) {
              err.inner.forEach(error => {
                validationErrors[error.path] = error.message;
                // _log(error.path,error.message)
              });
              formRef.current.setErrors(validationErrors);
            }
      
        }
        
    }


  //
  //
  return (
    <Fragment>
        <Helmet>
            <title>{`Login | ${process.env.REACT_APP_SITENAME}`}</title>
        </Helmet>

        <Layout>
            <div className='bgLogin'>
            <div className="container mx-auto h-full flex justify-center items-center">
                <div className="box bg-white rounded-md mx-auto p-8 w-5/6 md:w-1/2 lg:w-[458px]">

                <div className='text-2xl text-center'>Rocket<strong className='text-purple-500'>88</strong> <span className='font-thin'>| Login</span></div>
                <hr className='my-6'/>

                <Form ref={formRef} onSubmit={handleSubmit}>
                    <div className='mb-3'>
                        <InputText name="username" placeholder="Username" />
                    </div>
                    <div className='mb-3'>
                        <InputText name="password" placeholder="password" type="password" />
                    </div>
                    <div className="text-center">
                        <button className="button primary w-full">Login</button>
                    </div>
                </Form>

                </div>
            </div>
            </div>

            {isLoading && 
                    <div className='loading'>
                        <span className="icon">
                            <Loader size={20} className="animate-spin"/>
                        </span>
                        <span className='text'>authenticating...</span>
                    </div>
            }
        </Layout>
        
    </Fragment>
  );

}
