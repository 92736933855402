import React, { Fragment } from 'react'
import { X } from 'react-feather'


export default function Modal(props) {

    return (
        props.status ? 
        <Fragment>
            <div className={`modal ${props.size ? props.size : ''} ${props.className ? props.className : ''}`}>
                <div>
                    {props.showClose && <button type="button" className="close" onClick={()=>props.close()}><X /></button>}
                    {props.children}
                </div>
            </div>
        </Fragment>
        : 
        false
    );
};
