import React, { Fragment } from 'react'


//
//
export default function Layout(props) {


  //
  //
  return (
    <Fragment>
      <div className='layout'>
        {props.children}
      </div>
    </Fragment>
  );
};
