import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async'
import { useNavigate } from "react-router-dom";
import dayjs from 'dayjs';
import { X, Loader, ExternalLink, MoreVertical, Menu, ArrowUp, ArrowDown, ArrowUpRight, Star } from 'react-feather'
import DataTable from 'react-data-table-component';
import 'react-dates/initialize'
import { DateRangePicker } from 'react-dates';
import Hotkeys from 'react-hot-keys'
import { MeiliSearch } from 'meilisearch'
import moment from 'moment';


// controllers
import useQuery from '../controllers/hooks/useQuery';
import { _apiPUT, _apiPOST, _apiGETone, _apiPATCH } from '../controllers/Api'
import { _toaster, _pad, _timeout, _calcChangeValue, _calcChangePercent, _rank } from '../controllers/Helpers'


// components
import Layout from '../components/Layout'
import Header from '../components/Header'
import Modal from '../components/Modal'
import DetailsSpotify from '../components/Details/spotify';


// dayjs
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
const weekday = require('dayjs/plugin/weekday')
dayjs.extend(weekday)
const advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)


export default function Spotify(props){

    const venueCapacityMaxValue = 250000
    const [ initDate, setInitDate ] = React.useState(dayjs()) 

    const [ reload,setReload ] = React.useState(1)

    const defaultRequestData = {
        attributesToHighlight: ['*'],
        facets: [],
        limit:20,
        offset:0,
    }
    const query = useQuery();
    const navigate = useNavigate()
    const mounted1 = React.useRef(null);
    const [ requestInit1, setRequestInit1 ] = React.useState(0);

    const [ isLoading, setLoading ] = React.useState(false)
    const [ mobileFilterState, setMobileFilterState ] = React.useState(false)

    const [ requestSearch, setRequestSearch ] = React.useState('')
    const [ requestData, setRequestData ] = React.useState(defaultRequestData)

    const [ responseData, setResponseData ] = React.useState([]);
    const [ totalResults, setTotalResults ] = React.useState(0)
    const [ filterData, setFilterData] = React.useState({})
    const [ filterDataCounts, setFilterDataCounts] = React.useState({})

    const [ page, setPage ] = React.useState(1);

    const [ isDetailsModalOpen, setDetailsModalIsOpen ] = React.useState(null)

    const [ dateStart, setDateStart ] = React.useState(null)
    const [ dateEnd, setDateEnd ] = React.useState(null)
    const [ focusedDateInput, setFocusedDateInput ] = React.useState(null)

    const [ venueCapacityMin, setVenueCapacityMin ] = React.useState('')
    const [ venueCapacityMax, setVenueCapacityMax ] = React.useState('')

    const [ growth_30Min, setGrowth_30Min ] = React.useState('')
    const [ growth_30Max, setGrowth_30Max ] = React.useState('')

    const [ growth_90Min, setGrowth_90Min ] = React.useState('')
    const [ growth_90Max, setGrowth_90Max ] = React.useState('')

    const [ showFavorites, setShowFavorites ] = React.useState(false);


    const client = new MeiliSearch({
        host: process.env.REACT_APP_APIURL_SPOTIFY,
        apiKey: process.env.REACT_APP_APIKEY_SPOTIFY
    })


    // 
    // 
    const updateState = React.useCallback(state => console.log(state), [responseData]);


    // 
    // table configuration
    const columns = React.useMemo(() => {


        // 
        // 
        const _favouriteAdd = async(artist_id) => {

            setLoading(true)

            await client.index('artists').updateDocuments([{
                artist_id: artist_id,
                favorite: true
            }])

            const add = await _apiPOST('/artists/toggle_artist_favorite', {artist_id:artist_id}, false, {}, {baseURL:'https://spot88.herokuapp.com'})
            if(add.error){
                _toaster('Error', 'Detected error in your request, try again later.', 'error')
                // return
            }

            await _timeout(1500)

            _toaster('Success', 'Artist added to favorites successfully', 'success')

            setReload(x => x+1)
            return
        }


        // 
        // 
        const _favouriteDelete = async(artist_id) => {

            setLoading(true)

            await client.index('artists').updateDocuments([{
                artist_id: artist_id,
                favorite: false
            }])

            const remove = await _apiPOST('/artists/toggle_artist_favorite', {artist_id:artist_id}, false, {}, {baseURL:'https://spot88.herokuapp.com'})
            if(remove.error){
                _toaster('Error', 'Detected error in your request, try again later.', 'error')
                // return
            }

            await _timeout(1500)

            _toaster('Success', 'Artist removed from favorites successfully', 'success')

            setReload(x => x+1)
            return
        }


        // 
        // 
        const _favoriteButton = (artist_id, hasFavorite) => {
            return <button className={`w-8 h-8 flex justify-center items-center rounded-full ${!hasFavorite ? 'text-gray-300 ' : 'text-purple-500'}`} onClick={()=> hasFavorite ? _favouriteDelete(artist_id) : _favouriteAdd(artist_id)}><Star size={20}/></button>
        }


        //
        //
        Number.prototype.format = function(n, x) {
            var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
            return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
        };


        return [
            {
                name: <Star size={20} className=""/>,
                selector: row => _favoriteButton(row.artist_id, row.favorite),
                field: 'favorite',
                sortable: false,
                reorder: false,
                button: true,
                minWidth:'50px',
                maxWidth:'50px'
            },
            {
                name: 'Artist Name ',
                selector: row => <button className='font-medium cursor-pointer text-purple-500' onClick={()=>setDetailsModalIsOpen(row)}>{row.name}</button>,
                field: 'name',
                sortable: true,
                reorder: false,
                style: {position:'sticky', left:0, background:'#fff', zIndex:8}
            },
            {
                name: 'Σ Spot',
                selector: row => {
                    // return _calcChangeValue(row.num_followers)
                    const value = parseInt(row.num_followers)
                    return value ? value.format() : ''
                },
                field: 'num_followers',
                sortable: true,
                reorder: true,
                maxWidth:'400px',
            },
            {
                name: '30d Spot',
                selector: row => {
                    const value = parseFloat(row.growth_30)
                    return _calcChangePercent(value)
                },
                field: 'growth_30',
                sortable: true,
                reorder: true,
                maxWidth:'400px',
            },
            {
                name: '90d Spot',
                selector: row => {
                    const value = parseFloat(row.growth_90)
                    return _calcChangePercent(value)
                },
                field: 'growth_90',
                sortable: true,
                reorder: true,
                maxWidth:'400px',
            },
            {
                name: '∆ 30d Spot',
                selector: row => {
                    const value = parseFloat(row.ch_growth_30)
                    return _calcChangePercent(value)
                },
                field: 'ch_growth_30',
                sortable: true,
                reorder: true,
                maxWidth:'400px',
            },
            {
                name: '∆ 90d Spot',
                selector: row => {
                    const value = parseFloat(row.ch_growth_90)
                    return _calcChangePercent(value)
                },
                field: 'ch_growth_90',
                sortable: true,
                reorder: true,
                maxWidth:'400px',
            },
        ]
    }, []);


    // 
    // 
    const onKeyUp = (keyName, e, handle) => {

        const htmlElement = document.getElementsByClassName('tableRef')[0].children[0].children[0];

        if(keyName==='alt+right' && htmlElement){
            htmlElement.scrollIntoView({behavior:'smooth', block: "nearest", inline: "end"})
        }
        if(keyName==='alt+left' && htmlElement){
            htmlElement.scrollIntoView({behavior:'smooth', block: "nearest", inline: "start"})
        }
        if(keyName==='esc'){
            setDetailsModalIsOpen(null)
        }

    }


    //
    // update state on change value in field
    const _onFieldChange = (field, value) => {
        if(value==='none'){
            _resetFields(field)
            return
        }

        let newData = {...requestData}
        let filter = newData.filter
        let newFields = []


        if(!filter || filter === []){

            newFields.push(field+'="'+value+'"')
            newData.filter = newFields
            newData.offset = parseInt(0)
            setPage(1)
            setRequestData(newData)
            return

        }


        if(filter && filter !== []){

            newFields = filter
            const findIndex = newFields.findIndex(x => x.indexOf(field) !== -1)
            if(findIndex !== -1){

                const isEqual = newFields.find(x => x.indexOf(field+'="'+value+'"') !== -1)
                if(isEqual){

                    // remove if is equal to current selection
                    let newFieldsOR = newFields[findIndex].indexOf(' OR ') !== -1 ? newFields[findIndex].split(' OR ') : [filter[findIndex]]
                    const isEqualOR = newFieldsOR.find(x => x.indexOf(field+'="'+value+'"') !== -1)
                    if(isEqualOR){

                        // remove if is equal to current selection in same fineld name
                        newFields = newFields.filter(x => x.indexOf(field) === -1)
                        newFieldsOR = newFieldsOR.filter(x => x.indexOf(field+'="'+value+'"') === -1)
                        if(newFieldsOR.length>0){
                            newFields.push(newFieldsOR.join(' OR '))
                        }

                    }else{

                        // add new to OR array
                        newFields = newFields.filter(x => x.indexOf(field) === -1)
                        newFieldsOR.push(field+'="'+value+'"')
                        newFields.push(newFieldsOR.join(' OR '))

                    }

                }else{

                    let newFieldsOR = newFields[findIndex].indexOf(' OR ') !== -1 ? newFields[findIndex].split(' OR ') : [filter[findIndex]]
                    const isEqualOR = newFieldsOR.find(x => x.indexOf(field+'="'+value+'"') !== -1)
                    if(isEqualOR){

                        // remove if is equal to current selection in same fineld name
                        newFieldsOR = newFieldsOR.filter(x => x.indexOf(field+'="'+value+'"') === -1)

                    }else{

                        // add new to OR array
                        newFields = newFields.filter(x => x.indexOf(field) === -1)
                        newFieldsOR.push(field+'="'+value+'"')
                        newFields.push(newFieldsOR.join(' OR '))

                    }

                }

            }else{

                // if field not fount in current filter
                newFields.push(field+'="'+value+'"')

            }

            // return....
            if(newFields.length>0){
                newData.filter = [...newFields]
            }else{
                delete newData.filter
            }
            newData.offset = parseInt(0)
            setPage(1)
            setRequestData(newData)
            return

        }

    }


    //
    // check is field used in filter
    const _isFieldUsed = (field) => {

        if(field==='search'){
            return requestSearch==='' ? false : true
        }

        if(!requestData.filter && requestData.filter !== []){
            return false;
        }
        
        const find = requestData.filter.filter(x => x.search(field) !== -1)
        if(find.length===0){
            return false;
        }

        return true;
    }


    //
    // reset fields by all or specific field
    const _resetFields = (field) => {

        let newData = {...requestData}
        
        if(field==='all'){
            window.location.reload(false)
            return
        }

        if(field==='search'){
            setRequestSearch('')
        }

        if(field==='num_followers'){
            setVenueCapacityMin('')
            setVenueCapacityMax('')
        }

        if(field==='growth_30'){
            setGrowth_30Min('')
            setGrowth_30Max('')
        }

        if(field==='growth_90'){
            setGrowth_90Min('')
            setGrowth_90Max('')
        }

        const find = newData.filter.filter(x => { if(x.search(field) === -1){ return x }})
        newData.filter = find
        newData.offset = parseInt(0)
        setPage(1) 
        setRequestData(newData)
        
    }


    //
    //  sorting action
    const _handleSort = (column, sortDirection) => {
        let newData = {...requestData}
        newData.sort = [columns[column.id-1].field+':'+sortDirection]
        setRequestData(newData)
    }

    //
    // pagination action
    const _handlePageChange = (page, totalRows) => {
      let newRequest = {...requestData}
          newRequest.offset = (page-1)*requestData.limit
      setRequestData(newRequest);
      setPage(page)
    };


    //
    // changing limit per page action
	const _handlePerRowsChange = async (newPerPage, page) => {
		let newRequest = {...requestData}
        newRequest.limit = newPerPage
        newRequest.offset = 0
		setRequestData(newRequest);
	};


    //
    //
    const _isResetVisible = () => {
        if(requestData.filter && requestData.filter.length>0){
            return true
        }
        if(requestSearch !== ''){
            return true
        }
        return false
    }


    //
    //
    const _updateRequestByVenueCapacityMin = (value) => {

        let newData = {...requestData}
        let range = []

        if(value){
            setVenueCapacityMin(value)
            range.push('num_followers >= '+value)
        }else{
            setVenueCapacityMin('')
        }
        
        if(venueCapacityMax !== ''){
            range.push('num_followers <= '+venueCapacityMax)
        }

        
        const filteredDatefield = newData.filter ? newData.filter.filter(x => x.search('num_followers') === -1) : []
        if(range.length>0){
            newData.filter = newData.filter ? [...filteredDatefield, ...range] : [...range]
        }else{
            newData.filter = newData.filter ? [...filteredDatefield] : []
        }
        newData.offset = parseInt(0)
        setPage(1) 
        setRequestData(newData)

    }


    //
    //
    const _updateRequestByVenueCapacityMax = (value) => {

        let newData = {...requestData}
        let range = []

        if(value){
            setVenueCapacityMax(value)
            range.push('num_followers <= '+value)
        }else{
            setVenueCapacityMax('')
        }

        if(venueCapacityMin !== ''){
            range.push('num_followers >= '+venueCapacityMin)
        }

        
        const filteredDatefield = newData.filter ? newData.filter.filter(x => x.search('num_followers') === -1) : []
        if(range.length>0){
            newData.filter = newData.filter ? [...filteredDatefield, ...range] : [...range]
        }else{
            newData.filter = newData.filter ? [...filteredDatefield] : []
        }
        newData.offset = parseInt(0)
        setPage(1) 
        setRequestData(newData)

    }


    //
    //
    const _updateRequestByGrow30Min = (value) => {

        let newData = {...requestData}
        let range = []

        if(value){
            setGrowth_30Min(value)
            range.push('growth_30 >= '+value)
        }else{
            setGrowth_30Min('')
        }

        if(growth_30Max !== ''){
            range.push('growth_30 <= '+growth_30Max)
        }

        
        const filteredDatefield = newData.filter ? newData.filter.filter(x => x.search('growth_30') === -1) : []
        if(range.length>0){
            newData.filter = newData.filter ? [...filteredDatefield, ...range] : [...range]
        }else{
            newData.filter = newData.filter ? [...filteredDatefield] : []
        }
        newData.offset = parseInt(0)
        setPage(1) 
        setRequestData(newData)

    }


    //
    //
    const _updateRequestByGrow30Max = (value) => {

        let newData = {...requestData}
        let range = []

        if(value){
            setGrowth_30Max(value)
            range.push('growth_30 <= '+value)
        }else{
            setGrowth_30Max('')
        }

        if(growth_30Min !== ''){
            range.push('growth_30 >= '+growth_30Min)
        }

        
        const filteredDatefield = newData.filter ? newData.filter.filter(x => x.search('growth_30') === -1) : []
        if(range.length>0){
            newData.filter = newData.filter ? [...filteredDatefield, ...range] : [...range]
        }else{
            newData.filter = newData.filter ? [...filteredDatefield] : []
        }
        newData.offset = parseInt(0)
        setPage(1) 
        setRequestData(newData)

    }


    //
    //
    const _updateRequestByGrow90Min = (value) => {

        let newData = {...requestData}
        let range = []

        if(value){
            setGrowth_90Min(value)
            range.push('growth_90 >= '+value)
        }else{
            setGrowth_90Min('')
        }

        if(growth_90Max !== ''){
            range.push('growth_30 <= '+growth_90Max)
        }

        
        const filteredDatefield = newData.filter ? newData.filter.filter(x => x.search('growth_90') === -1) : []
        if(range.length>0){
            newData.filter = newData.filter ? [...filteredDatefield, ...range] : [...range]
        }else{
            newData.filter = newData.filter ? [...filteredDatefield] : []
        }
        newData.offset = parseInt(0)
        setPage(1) 
        setRequestData(newData)

    }


    //
    //
    const _updateRequestByGrow90Max = (value) => {

        let newData = {...requestData}
        let range = []

        if(value){
            setGrowth_90Max(value)
            range.push('growth_90 <= '+value)
        }else{
            setGrowth_90Max('')
        }

        if(growth_90Min !== ''){
            range.push('growth_90 >= '+growth_90Min)
        }

        
        const filteredDatefield = newData.filter ? newData.filter.filter(x => x.search('growth_90') === -1) : []
        if(range.length>0){
            newData.filter = newData.filter ? [...filteredDatefield, ...range] : [...range]
        }else{
            newData.filter = newData.filter ? [...filteredDatefield] : []
        }
        newData.offset = parseInt(0)
        setPage(1) 
        setRequestData(newData)

    }


    //
    //
    const _showFavoritesOnly = (state) => {
        let newData = {...requestData}
        if(state===true){
            newData.filter = newData.filter ? [...newData.filter, 'favorite = "true"'] : ['favorite = "true"']
        }else{
            newData.filter = newData.filter.filter(x => x.search('favorite = "true"') === -1)
        }
        setShowFavorites(state);
        setRequestData(newData)
    }



    //
    // fetching data on load and on filter change
    React.useEffect(()=>{
        const _fetching = async() => {

            // console.log('REQUEST', requestSearch, requestData)

            setLoading(true)

            const index = client.index('artists')
            const search = await index.search(requestSearch, requestData)


            let reindexData = [...search.hits]

            if(requestData.sort){
                setResponseData(reindexData)
            }else{
                setResponseData(reindexData.sort((a,b) => b.rank - a.rank ))
            }
            setFilterDataCounts(search.facetsDistribution)
            setTotalResults(search.estimatedTotalHits)
            setInitDate(dayjs())

            // console.log('RESPONSE', search)

            setLoading(false)
        }
        // if(requestInit1>0){
        //  _fetching()
        // }
        _fetching()

        return () => setRequestInit1(requestInit1+1)
    },[requestSearch, requestData, reload])



    //
    // fetching filter options on load and on filter change
    React.useEffect(()=>{
        const _fetching = async() => {
            const index = client.index('artists')
            let newRequestData = {...defaultRequestData}

            const search = await index.search(requestSearch, newRequestData)
            setFilterData(search.facetsDistribution)
        }
        // if(requestInit1>0){
        //  _fetching()
        // }
        _fetching()
        return () => setRequestInit1(requestInit1+1)
    },[requestSearch, requestData])


    // 
    // load venue capacity from saved search
    // React.useEffect(()=>{
    //     if(!venueCapacityMax && !venueCapacityMax && requestData.filter){
            
    //         const findMin = requestData.filter.filter(x => x.search('num_followers >=') !== -1)
    //         const findMax = requestData.filter.filter(x => x.search('num_followers <=') !== -1)
    //         if(findMin.length>0){
    //             const _readMin = findMin[0].split(' >= ')[1]
    //             setVenueCapacityMin(parseInt(_readMin))
    //         }

    //         if(findMax.length>0){
    //             const _readMax = findMax[0].split(' <= ')[1]
    //             setVenueCapacityMax(parseInt(_readMax))
    //         }
            
    //     }else{

    //         if(requestData.filter){
    //             const findMin = requestData.filter.filter(x => x.search('num_followers >=') !== -1)
    //             const findMax = requestData.filter.filter(x => x.search('num_followers <=') !== -1)
    //             if(findMin.length>0){
    //                 const _readMin = findMin[0].split(' >= ')[1]
    //                 setVenueCapacityMin(parseInt(_readMin))
    //             }else{
    //                 setVenueCapacityMin('')
    //             }

    //             if(findMax.length>0){
    //                 const _readMax = findMax[0].split(' <= ')[1]
    //                 setVenueCapacityMax(parseInt(_readMax))
    //             }else{
    //                 setVenueCapacityMax('')
    //             }
    //         }else{
    //             setVenueCapacityMin('')
    //             setVenueCapacityMax('')
    //         }
    //     }
    // },[requestData])



    //
    // set / get fitlerable attributes
    // React.useEffect(()=>{
    //     const _init = async() => {

    //         // const updateSettings = await _apiPATCH('/indexes/artists/settings/faceting', {"maxValuesPerFacet": 2000}, false, {'Authorization':'Bearer '+process.env.REACT_APP_APIKEY_SPOTIFY,'Content-Type':'application/json'}, {baseURL:process.env.REACT_APP_APIURL_SPOTIFY})
            
    //         // const searchableAttributes = await _apiPUT('/indexes/artists/settings/searchable-attributes', ["name"], false, {'Authorization':'Bearer '+process.env.REACT_APP_APIKEY_SPOTIFY,'Content-Type':'application/json'}, {baseURL:process.env.REACT_APP_APIURL_SPOTIFY})
    //         // const getSearchableAttributes = await _apiGETone('/indexes/artists/settings/searchable-attributes', {'Authorization':'Bearer '+process.env.REACT_APP_APIKEY_SPOTIFY,'Content-Type':'application/json'}, false, {baseURL:process.env.REACT_APP_APIURL_SPOTIFY})
    //         // console.log('getSearchableAttributes',getSearchableAttributes)


    //         // const updateAttributes = await _apiPUT('/indexes/artists/settings/filterable-attributes', [
    //         //     'growth_30',
    //         //     'growth_90',
    //         //     'favorite',
    //         //     'num_followers',
    //         //     'ch_growth_30',
    //         //     'ch_growth_90'
    //         // ], false, {'Authorization':'Bearer '+process.env.REACT_APP_APIKEY_SPOTIFY}, {baseURL:process.env.REACT_APP_APIURL_SPOTIFY})
    //         // const getAttributes = await _apiGETone('/indexes/artists/settings/filterable-attributes', {'Authorization':'Bearer '+process.env.REACT_APP_APIKEY_SPOTIFY,'Content-Type':'application/json'}, false, {baseURL:process.env.REACT_APP_APIURL_SPOTIFY})
    //         // console.log('getFitlerableAttributes',getAttributes)


    //         // const updateSortableAttributes = await _apiPUT('/indexes/artists/settings/sortable-attributes', [
    //         //     'name',
    //         //     'growth_30',
    //         //     'growth_90',
    //         //     'favorite',
	// 	    //     'num_followers',
    //         //     'ch_growth_30',
    //         //     'ch_growth_90'
    //         // ], false, {'Authorization':'Bearer '+process.env.REACT_APP_APIKEY_SPOTIFY}, {baseURL:process.env.REACT_APP_APIURL_SPOTIFY})
    //         // const getSortableAttributes = await _apiGETone('/indexes/artists/settings/sortable-attributes', {'Authorization':'Bearer '+process.env.REACT_APP_APIKEY_SPOTIFY,'Content-Type':'application/json'}, false, {baseURL:process.env.REACT_APP_APIURL_SPOTIFY})
    //         // console.log('getSortableAttributes',getSortableAttributes)


    //     }
    //     _init()
    // },[])



    //
    //
    return (
        <Fragment>
        <Helmet>
            <title>{`Spotify | ${process.env.REACT_APP_SITENAME}`}</title>
        </Helmet>

        <Layout>

            <div className='wrapper'>
                <Header
                    switchComponent={
                        <>
                            <input type="checkbox" name="showFavorites" onChange={()=>_showFavoritesOnly(!showFavorites)}/>
                            <label><span>Watchlist</span></label>
                        </>
                    }
                    mobileStateAction={setMobileFilterState}
                >
                    <div className={`mob ${!mobileFilterState ? 'flex h-auto' : 'flex h-0 opacity-0'}`}>
                        <div className='pt-0 lg:pt-6'>
                            <div className='formGroup mb-9'>
                                <label>Search</label> 
                                <div>
                                    <input 
                                        type="text"
                                        name="search"
                                        onChange={(e)=>setRequestSearch(e.target.value)}
                                        value={requestSearch}
                                        className='formControl'
                                        autoComplete='off'
                                    />
                                    {_isFieldUsed('search') && <button className='reset' onClick={()=>_resetFields('search')}><X size={18} color="#fff"/></button>}
                                </div>
                            </div>
                        </div>
                        <div className='pt-0 lg:pt-6'>
                            <div className='formGroup'>
                                <label>Followers</label> 
                                <div className='w-full'>
                                    <div className='w-full flex flex-row justify-start items-center'>
                                        <input
                                            type="number"
                                            name="venue_capacty_min"
                                            step="100"
                                            min="0"
                                            max={venueCapacityMaxValue}
                                            className='formControl rounded-none'
                                            value={venueCapacityMin}
                                            onChange={(e)=>_updateRequestByVenueCapacityMin(e.target.value)}
                                        />
                                        <div className='formControl w-7 rounded-none px-0'>
                                            <svg className="DateRangePickerInput_arrow_svg DateRangePickerInput_arrow_svg_1" focusable="false" viewBox="0 0 1000 1000"><path d="M694 242l249 250c12 11 12 21 1 32L694 773c-5 5-10 7-16 7s-11-2-16-7c-11-11-11-21 0-32l210-210H68c-13 0-23-10-23-23s10-23 23-23h806L662 275c-21-22 11-54 32-33z"></path></svg>
                                        </div>
                                        <input
                                            type="number"
                                            name="venue_capacty_max"
                                            step="100"
                                            min="0"
                                            max={venueCapacityMaxValue}
                                            className='formControl rounded-none'
                                            value={venueCapacityMax}
                                            onChange={(e)=>_updateRequestByVenueCapacityMax(e.target.value)}
                                        />
                                    </div>
                                    {_isFieldUsed('num_followers') && <button className='reset' onClick={()=>_resetFields('num_followers')}><X size={18} color="#fff"/></button>}
                                </div>
                            </div>
                        </div>
                        <div className='pt-0 lg:pt-6'>
                            <div className='formGroup'>
                                <label>30d Spot</label> 
                                <div className='w-full'>
                                    <div className='w-full flex flex-row justify-start items-center'>
                                        <input
                                            type="number"
                                            name="growth_30_min"
                                            step="0.1"
                                            min="0"
                                            max={venueCapacityMaxValue}
                                            className='formControl rounded-none'
                                            value={growth_30Min}
                                            onChange={(e)=>_updateRequestByGrow30Min(e.target.value)}
                                        />
                                        <div className='formControl w-7 rounded-none px-0'>
                                            <svg className="DateRangePickerInput_arrow_svg DateRangePickerInput_arrow_svg_1" focusable="false" viewBox="0 0 1000 1000"><path d="M694 242l249 250c12 11 12 21 1 32L694 773c-5 5-10 7-16 7s-11-2-16-7c-11-11-11-21 0-32l210-210H68c-13 0-23-10-23-23s10-23 23-23h806L662 275c-21-22 11-54 32-33z"></path></svg>
                                        </div>
                                        <input
                                            type="number"
                                            name="growth_30_max"
                                            step="0.1"
                                            min="0"
                                            max={venueCapacityMaxValue}
                                            className='formControl rounded-none'
                                            value={growth_30Max}
                                            onChange={(e)=>_updateRequestByGrow30Max(e.target.value)}
                                        />
                                    </div>
                                    {_isFieldUsed('growth_30') && <button className='reset' onClick={()=>_resetFields('growth_30')}><X size={18} color="#fff"/></button>}
                                </div>
                            </div>
                        </div>
                        <div className='pt-0 lg:pt-6'>
                            <div className='formGroup'>
                                <label>90d Spot</label> 
                                <div className='w-full'>
                                    <div className='w-full flex flex-row justify-start items-center'>
                                        <input
                                            type="number"
                                            name="growth_90_min"
                                            step="0.1"
                                            min="0"
                                            max={venueCapacityMaxValue}
                                            className='formControl rounded-none'
                                            value={growth_90Min}
                                            onChange={(e)=>_updateRequestByGrow90Min(e.target.value)}
                                        />
                                        <div className='formControl w-7 rounded-none px-0'>
                                            <svg className="DateRangePickerInput_arrow_svg DateRangePickerInput_arrow_svg_1" focusable="false" viewBox="0 0 1000 1000"><path d="M694 242l249 250c12 11 12 21 1 32L694 773c-5 5-10 7-16 7s-11-2-16-7c-11-11-11-21 0-32l210-210H68c-13 0-23-10-23-23s10-23 23-23h806L662 275c-21-22 11-54 32-33z"></path></svg>
                                        </div>
                                        <input
                                            type="number"
                                            name="growth_90_max"
                                            step="0.1"
                                            min="0"
                                            max={venueCapacityMaxValue}
                                            className='formControl rounded-none'
                                            value={growth_90Max}
                                            onChange={(e)=>_updateRequestByGrow90Max(e.target.value)}
                                        />
                                    </div>
                                    {_isFieldUsed('growth_90') && <button className='reset' onClick={()=>_resetFields('growth_90')}><X size={18} color="#fff"/></button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </Header>

                <div className="main tableRef spotify">
                    <DataTable
                        columns={columns}
                        data={responseData}
                        fixedHeader
                        onSort={_handleSort}
                        onSelectedRowsChange={updateState}
                        sortServer
                        pagination
                        paginationServer
                        paginationDefaultPage={page}
                        paginationTotalRows={totalResults}
                        paginationPerPage={requestData.limit}
                        onChangeRowsPerPage={_handlePerRowsChange}
                        onChangePage={_handlePageChange}
                        paginationRowsPerPageOptions={[20,50,100,200]}
                        keyField={'sale_id'}
                    />
                </div>

                {isLoading && 
                    <div className='loading'>
                        <span className="icon">
                            <Loader size={20} className="animate-spin"/>
                        </span>
                        <span className='text'>Loading...</span>
                    </div>
                }
            </div>


            <Modal
                status={isDetailsModalOpen ? true : false}
                showClose={true}
                close={()=>setDetailsModalIsOpen(null)}
                size="large"
            >
                {isDetailsModalOpen && <DetailsSpotify data={isDetailsModalOpen}/>}

                <Hotkeys 
                    keyName="esc" 
                    filter={(event) => {
                        return true;
                    }}
                    onKeyDown={null}
                    onKeyUp={onKeyUp}
                />
            </Modal>


            <Hotkeys 
                keyName="alt+right,alt+left" 
                filter={(event) => {
                    return true;
                }}
                onKeyDown={null}
                onKeyUp={onKeyUp}
            />
            
        </Layout>
            
        </Fragment>
    );

}
