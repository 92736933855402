import React, { Fragment } from 'react'
import{ ExternalLink, Loader } from 'react-feather'
import { ResponsiveLine } from '@nivo/line'
import { ResponsiveBar } from '@nivo/bar'
import dayjs from 'dayjs'
import DataTable from 'react-data-table-component';
import slugify from 'slugify'

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


// controllers
import { _apiGETone, _apiPOST } from '../../controllers/Api';
import { _calcChangePercent, _calcChangeValue, _timeout } from '../../controllers/Helpers'


// dayjs
const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)


  
//
export default function DetailsSpotify(props) {

    const details = props.data
    const refCarousel = React.useRef()
    const [ isLoading, setLoading ] = React.useState(true)
    const [ isLoadingT3, setLoadingT3 ] = React.useState(true)
    const [ activeTab, setActiveTab ] = React.useState('t1')

    const [ dataT3, setDataT3 ] = React.useState([])
    const [ dataT4, setDataT4 ] = React.useState([])

    const [ spotifyToken, setSpotifyToken ] = React.useState(null)
    const [ spotifyRequest, setSpotifyRequest ] = React.useState({total:0, offset:0})
    const [ activeAlbum, setActiveAlbum ] = React.useState(null);
    const [ albums, setAlbums ] = React.useState(null)
    const [ tracks, setTracks ] = React.useState(null)
    const [ artistImage, setArtistImage ] = React.useState(null)
    const [ artistName, setArtistName ] = React.useState(null)


    const carouselSettings = {
        dots: false,
        arrows:true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1
    }


    // 
    // 
    Number.prototype.format = function(n, x) {
        var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
        return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
    };



    //
    //
    const _authorizeSpotify = async() => {
        const get = await _apiPOST('/artists/get_spotify_token', {"api_key" : "8db33250-bfda-437f-a52c-a03b4f92d6fa"}, true, {}, { baseURL:'https://spot88.herokuapp.com'})
        if(!get.error){
            setSpotifyToken(get.token)
            const albumImg = await _apiGETone('/artists/'+details.spotify_id, {"Authorization" : "Bearer "+get.token}, true, { baseURL:'https://api.spotify.com/v1'})
            if(!albumImg.error && albumImg.images && albumImg.images.length>0){
                setArtistImage(albumImg.images[2].url)
                setArtistName(albumImg.name)
            }
        }
        setLoading(false)
    }



    //
    //
    const _getAlbumsSpotify = async(offset) => {
        setLoading(true)
        const get = await _apiGETone('/artists/'+details.spotify_id+'/albums?limit=20&offset='+offset, {"Authorization" : "Bearer "+spotifyToken}, true, { baseURL:'https://api.spotify.com/v1'})
        if(!get.error){
            setSpotifyRequest({total:get.total, offset:offset+20})
            setAlbums(get.items);
            setActiveAlbum(get.items[0].id)
        }
        setLoading(false)
    }



    //
    //
    const _getTracksSpotify = async(id) => {
        setLoading(true)
        const get = await _apiGETone('/albums/'+id+'/tracks/?limit=50', {"Authorization" : "Bearer "+spotifyToken}, true, { baseURL:'https://api.spotify.com/v1'})
        if(!get.error){

            let trackIds = [];
            if(get.items && get.items.length>0){
                for (let index = 0; index < get.items.length; index++) {
                    const track = get.items[index];
                    trackIds.push(track.id)
                }

                let popularity = []
                const getTrackInfo = await _apiGETone('/tracks/?ids='+trackIds.join(',')+'&limit=50', {"Authorization" : "Bearer "+spotifyToken}, true, { baseURL:'https://api.spotify.com/v1'})
                for (let index = 0; index < getTrackInfo.tracks.length; index++) {
                    const item = getTrackInfo.tracks[index];
                    popularity.push({index:index+1, score:item.popularity})
                }

                setTracks(popularity)
            }
        }
        setLoading(false)
    }



    //
    //
    const _getTab3data = async() => {
        setLoadingT3(true)
        // const get = await _apiGETone('/events?performers.id='+details.artist_id+'&per_page=5000&sort=datetime_utc.asc&client_id='+process.env.REACT_APP_SEATGEAK_API+'&client_secret='+process.env.REACT_APP_SEATGEAK_SECRET, {}, false, {baseURL:'https://api.seatgeek.com/2'})
        const get = await _apiGETone('/events?performers.slug='+slugify(details.name)+'&per_page=5000&sort=datetime_utc.asc&client_id='+process.env.REACT_APP_SEATGEAK_API+'&client_secret='+process.env.REACT_APP_SEATGEAK_SECRET, {}, false, {baseURL:'https://api.seatgeek.com/2'})
        // const get = await _apiGETone('/event-sections?event_id=617')
        if(!get.error){
            setDataT3(get.events)
        }
        setLoadingT3(false)
    }



    //
    //
    const _getTab4data = async(id) => {
        const get = await _apiGETone('/artists/get_spotify_counts?artist_id='+id, {}, true, { baseURL:'https://spot88.herokuapp.com'})
        if(!get.error){
            setDataT4([get.data.graph_data_x, get.data.graph_data_y])
        }
        setLoading(false)
    }


    // 
    // table configuration
    const columnsT3 = [
        {
            name: 'Date',
            selector: row => dayjs(row.datetime_utc).format('MM/DD/YYYY hh:mm A'),
            field: 'datetime_utc',
            minWidth:'20%',
            maxWidth:'20%'
        },
        {
            name: 'Event Name',
            selector: row => <a href={row.url} target="_blank" rel="norefferer" className='text-purple-500 flex'><ExternalLink size={14} className='mr-1'/>{row.title}</a>,
            field: 'title',
            minWidth:'35%',
            maxWidth:'35%'
        },
        {
            name: 'Venue Name',
            selector: row => row.venue.name,
            field: 'venue.name'
        }
    ]


    //
    //
    const _minYaxis = (data) => {
        let newData = data.sort((a,b) => a.y + b.y)

        // console.log('min',newData[0][0], newData[0][0].y-(newData[0][0].y/100)*15)
        return newData[0][0].y-(newData[0][0].y/100)*15
    }


    //
    //
    const _maxYaxis = (data) => {
        let newData = data.sort((a,b) => a.y + b.y)

        newData = newData[0][newData[0].length - 1]
        // console.log('max',newData, newData.y+(newData.y/100)*15)
        return newData.y+(newData.y/100)*15
    }


    // 
    // 
    React.useEffect(()=>{

        const _load = async() => {
            await _authorizeSpotify()
            await _getTab4data(details.artist_id)
        }

        if(details){
            _load()
        }

    },[details])


    // 
    // 
    React.useEffect(()=>{

        const _loadT2 = async() => {
            await _getAlbumsSpotify(0)
        }
        const _loadT3 = async() => {
            await _getTab3data(details.artist_id)
        }

        if(activeTab==='t3'){
            _loadT3()
        }
        if(activeTab==='t2'){
            _loadT2()
        }

    },[activeTab])


    // 
    // 
    React.useEffect(()=>{

        const _load = async() => {
            await _getTracksSpotify(activeAlbum)
        }

        if(activeAlbum){
            _load()
        }

    },[activeAlbum])


    //
    //
    return (
        <Fragment>
            <div className='flex justify-start items-center'>
                <div className='mb-4 w-[100px] h-[100px] bg-gray-100'>
                {artistImage && <img src={artistImage} alt="" className='min-h-[100px] min-h-[100px] max-w-[100px] max-h-[100px]'/>}
                </div>
                <div>
                    <h2 className='px-8 text-xl font-bold flex items-center'>
                        {details.name}
                        <a href={details.url} target="_blank" rel='noreferrer' className='text-purple-500'><ExternalLink size={22} className='ml-2'/></a>
                    </h2>
                    <div className='px-8 pb-8 pt-4'>
                        <div className='text-sm flex items-center gap-4 opacity-70'>
                            <div className='flex gap-2'>Σ Spot Flws: {details.num_followers ? _calcChangeValue(details.num_followers) : <div>N/A</div>}</div>
                            <div className='flex gap-2'>30d Spot: {_calcChangePercent(parseFloat(details.growth_30))}</div>
                            <div className='flex gap-2'>90d Spot: {_calcChangePercent(parseFloat(details.growth_90))}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='tabs'>

                
                <div className='tabsNav'>
                    <button className={activeTab==='t1' ? 'active' : ''} onClick={()=>setActiveTab('t1')}>Followers</button>
                    <button className={activeTab==='t2' ? 'active' : ''} onClick={()=>setActiveTab('t2')}>Album Tracks</button>
                    <button className={activeTab==='t3' ? 'active' : ''} onClick={()=>setActiveTab('t3')}>SG Shows</button>
                </div>


                {/* TAB 2 */}
                {activeTab==='t2' && 
                <div className='tabContent'>
                    <div className='w-full flex flex-col  flex-1 max-h-[700px]'>
                        
                        {albums && 
                        <div className='text-right pt-4 pb-3 px-2'>
                            showing albums: {spotifyRequest.offset>spotifyRequest.total ? spotifyRequest.total : spotifyRequest.offset} / {spotifyRequest.total}
                            <button className={`ml-4 uppercase text-sm ${spotifyRequest.offset>20 ? 'text-purple-500' : 'text-gray-300'}`} type="button" onClick={()=>spotifyRequest.offset>20 ? _getAlbumsSpotify(spotifyRequest.offset-40) : null}>prev</button>
                            <button className={`ml-4 uppercase text-sm ${spotifyRequest.offset<spotifyRequest.total ? 'text-purple-500' : 'text-gray-300'}`} type="button" onClick={()=>spotifyRequest.offset<spotifyRequest.total ? _getAlbumsSpotify(spotifyRequest.offset) : null}>next</button>
                        </div>
                        }
                        <Slider {...carouselSettings} className="mb-10 px-1" ref={refCarousel}>
                            {albums && albums.map((item,index)=>{
                                return(
                                    <button type="button" key={index} className={`p-1 rounded ${item.id===activeAlbum ? 'bg-purple-500' : ''}`} onClick={()=>setActiveAlbum(item.id)}>
                                        <img src={item.images[1].url} alt="" className={`${item.id===activeAlbum ? 'opacity-50' : ''}`}/>
                                    </button>
                                )
                            })}
                        </Slider>
                        {/* {console.log('Tracks',tracks)} */}
                        {tracks &&
                        <div className='w-full h-96'>
                            <ResponsiveBar
                                data={tracks}
                                keys={[
                                    'score'
                                ]}
                                indexBy="index"
                                margin={{ top: 10, right: 10, bottom: 70, left: 30 }}
                                padding={0.3}
                                // groupMode="grouped"
                                valueScale={{ type: 'linear' }}
                                indexScale={{ type: 'band', round: true }}
                                colors={["rgb(200 200 200)"]}
                                maxValue="100"
                                yScale={{
                                    type: 'linear',
                                    min: 0,
                                    max: 100,
                                    stacked: false,
                                    reverse: false
                                }}
                                axisTop={null}
                                axisRight={null}
                                axisBottom={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: 'Tracks',
                                    legendPosition: 'middle',
                                    legendOffset: 32
                                }}
                                axisLeft={{}}
                                labelSkipWidth={12}
                                labelSkipHeight={12}
                                labelTextColor={{
                                    from: 'color',
                                    modifiers: [
                                        [
                                            'darker',
                                            1.6
                                        ]
                                    ]
                                }}
                                legends={[]}
                                barAriaLabel={function(e){return e.id+": "+e.formattedValue+" in country: "+e.indexValue}}
                            />
                        </div>
                        }
                        
                    </div>
                </div>
                }


                {/* TAB 3 */}
                {activeTab==='t3' && 
                <div className='tabContent'>
                    <div className='w-full flex flex-col  flex-1 max-h-[700px]'>
                        <h3 className='pt-8 px-4 pb-2 text-xl font-bold'>SG Shows</h3>
                        <DataTable
                            columns={columnsT3}
                            data={dataT3}
                            highlightOnHover
                            fixedHeader
                        />
                    </div>
                    {isLoadingT3 &&
                        <div className='loading'>
                            <span className="icon">
                                <Loader size={20} className="animate-spin"/>
                            </span>
                            <span className='text'>Loading...</span>
                        </div>
                    }
                </div>
                }


                {/* TAB 1 */}
                {activeTab==='t1' && 
                <div className='tabContent p-8'>
                    {dataT4 && dataT4.length && dataT4[0].length>0 && dataT4[1].length>0 ? 
                    <div className='w-full h-96'>
                        <ResponsiveLine
                            data={[
                                {
                                    "id": 'test',
                                    "data": dataT4[0].map((x,i) => { 
                                        x.y = dataT4[1][i].y
                                        return x
                                    })
                                }
                            ]}
                            margin={{ top: 10, right: 10, bottom: 70, left: 70 }}
                            colors={["rgb(168 85 247)"]}
                            xScale={{ 
                                type: 'time',
                                format: '%Y-%m-%dT%H:%M:%S%Z',
                                useUTC: false,
                                precision: 'minute',
                             }}
                            // xFormat="time:%Y-%m-%dT%H:%M:%S%Z"
                            yScale={{
                                type: 'linear',
                                min: _minYaxis(dataT4),
                                max: _maxYaxis(dataT4),
                                stacked: false,
                                reverse: false
                            }}
                            curve="monotoneX"
                            axisTop={null}
                            axisRight={null}
                            axisLeft={{}}
                            axisBottom={{
                                format: '%H:%M | %b %d',
                                tickValues: 'every 2 day',
                                tickRotation: -55
                            }}
                            enablePointLabel={false}
                            pointLabelYOffset={12}
                            tooltip={({point}) =>{ return( <span className='bg-white text-xxs p-2 shadow-md'>{dayjs(point.data.x).format('MM/DD/YYYY hh:mm')}: {point.data.y}</span>) }}
                            useMesh={true}
                            legends={[]}
                        />
                    </div>
                        : 
                        details.num_followers===0 && !isLoading ? <p className='text-center -my-2'>Unable to render graph. Venue capacity is currently not available.</p> : <p className='text-center -my-2'>There are no records to display</p>
                    }
                </div>
                }


            </div>

            {isLoading && 
                <div className='loading'>
                    <span className="icon">
                        <Loader size={20} className="animate-spin"/>
                    </span>
                    <span className='text'>Loading...</span>
                </div>
            }
        </Fragment>
    );
};
