import React, { Fragment } from 'react'
import{ ExternalLink, Loader } from 'react-feather'
import { ResponsiveLine } from '@nivo/line'
import dayjs from 'dayjs'
import DataTable from 'react-data-table-component';


// controllers
import { _apiGETone, _apiPOST } from '../../controllers/Api';


// dayjs
const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)


  
//
export default function DetailsOnSale(props) {

    const details = props.data
    const [ isLoading, setLoading ] = React.useState(true)
    const [ isLoadingT3, setLoadingT3 ] = React.useState(true)
    const [ isLoadingT4, setLoadingT4 ] = React.useState(true)
    const [ activeTab, setActiveTab ] = React.useState('t1')

    const [ dataT1, setDataT1 ] = React.useState([])
    const [ dataT2, setDataT2 ] = React.useState([])
    const [ dataT3, setDataT3 ] = React.useState([])
    const [ dataT4, setDataT4 ] = React.useState([])
    const [ dataT3selectedSections, setDataT3selectedSections ] = React.useState('')
    const [ spotifyToken, setSpotifyToken ] = React.useState(null)
    const [ artistImage, setArtistImage ] = React.useState(null)
    const [ artistName, setArtistName ] = React.useState(null)

    
    const t3List = [
        {
            id: 'ada',
            name: 'Ada'
        },
        {
            id: 'balcony',
            name: 'Balcony'
        },
        {
            id: 'floor',
            name: 'Floor'
        },
        {
            id: 'general_admission',
            name: 'GA'
        },
        {
            id: 'lawn',
            name: 'Lawn'
        },
        {
            id: 'pit',
            name: 'Pit'
        },
        {
            id: 'platinum',
            name: 'Platinum'
        },
        {
            id: 'reserved',
            name: 'Reserved'
        },
        {
            id: 'vip',
            name: 'VIP'
        }
    ]


    // 
    // 
    Number.prototype.format = function(n, x) {
        var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
        return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
    };



    //
    //
    const _authorizeSpotify = async() => {
        const get = await _apiPOST('/artists/get_spotify_token', {"api_key" : "8db33250-bfda-437f-a52c-a03b4f92d6fa"}, true, {}, { baseURL:'https://spot88.herokuapp.com'})
        if(!get.error){
            setSpotifyToken(get.token)
            const albumImg = await _apiGETone('/artists/'+details.spotify_id, {"Authorization" : "Bearer "+get.token}, true, { baseURL:'https://api.spotify.com/v1'})
            if(!albumImg.error && albumImg.images && albumImg.images.length>0){
                setArtistImage(albumImg.images[2].url)
                setArtistName(albumImg.name)
            }
        }
        setLoading(false)
    }


    //
    //
    const _getEvent = async(id) => {
        const _get = await _apiGETone('/analytics_flat?event_id='+id)
        // const get = await _apiGETone('/analytics?event_id=617')
        if(!_get.error){
            setDataT1([_get.data.graph_data_x, _get.data.graph_data_y])
            setDataT2(_get.data.event_data)
        }
        setLoading(false)
    }



    //
    //
    const _getTab3data = async(section, id) => {
        setLoadingT3(true)
        const get = await _apiGETone('/event_section_data_flat?event_id='+id+'&key='+section)
        // const get = await _apiGETone('/event-sections?event_id=617')
        if(!get.error){
            setDataT3(get.data.event_section_data)
        }
        setLoadingT3(false)
    }



    //
    //
    const _getTab4data = async(id) => {
        setLoadingT4(true)
        const get = await _apiGETone('/artists/get_spotify_counts?artist_id='+id, {}, true, { baseURL:'https://spot88.herokuapp.com'})
        if(!get.error){
            // console.log(get.data)
            setDataT4([get.data.graph_data_x, get.data.graph_data_y])
            // setDataT3(get.data.event_section_data)
        }
        setLoadingT4(false)
    }


    // 
    // table configuration
    const columnsT2 = [
        {
            name: 'Date',
            selector: row => dayjs(row.modified).format('MM/DD/YYYY hh:mm A'),
            field: 'modified'
        },
        {
            name: 'Total',
            selector: row => row.sum.format(),
            field: 'sum',
            right: true
        }
    ]


    // 
    // table configuration
    const columnsT3 = [
        {
            name: 'Date',
            selector: row => dayjs(row.modified).format('MM/DD/YYYY hh:mm A'),
            field: 'modified',
        },
        {
            name: 'Total',
            selector: row => row.sum.format(),
            field: 'sum',
            right: true
        }
    ]


    //
    //
    const _minYaxis = (data, capacity) => {
        let newData = data.sort((a,b) => b.y - a.y)

        // console.log(newData, capacity)
        return newData[0][0].y>capacity ? (newData[0][0].y+(capacity/10)) : capacity
    }


    //
    //
    const _maxYaxis = (data, capacity) => {
        let newData = data.sort((a,b) => b.y - a.y)

        // console.log(newData, capacity)
        return newData[0][0].y>capacity ? (newData[0][0].y+(capacity/10)) : capacity
    }


    //
    //
    const _minYaxis2 = (data) => {
        let newData = data.sort((a,b) => a.y + b.y)

        // console.log('min',newData[0][0], newData[0][0].y-(newData[0][0].y/100)*15)
        return newData[0][0].y-(newData[0][0].y/100)*15
    }


    //
    //
    const _maxYaxis2 = (data) => {
        let newData = data.sort((a,b) => a.y + b.y)

        newData = newData[0][newData[0].length - 1]
        // console.log('max',newData, newData.y+(newData.y/100)*15)
        return newData.y+(newData.y/100)*15
    }


    // 
    // 
    React.useEffect(()=>{
        if(details){
            if(details.artist_id){
                _authorizeSpotify()
            }
            _getEvent(details.event_id)
        }
    },[details])


    // 
    // 
    React.useEffect(()=>{

        const _load = async() => {
            if(details.artist_id){
                await _getTab4data(details.artist_id)
            }
            setLoadingT4(false)
        }

        setLoadingT3(false)
        if(activeTab==='t4'){
            _load()
        }
    },[activeTab])


    // 
    // 
    React.useEffect(()=>{
        if(dataT3selectedSections !== ''){
            _getTab3data(dataT3selectedSections, details.event_id)
        }
    },[dataT3selectedSections, details.event_id])


    //
    //
    return (
        <Fragment>
            <div className='flex justify-start items-center'>
                <div className='mb-4 w-[100px] h-[100px] bg-gray-100'>
                {artistImage && <img src={artistImage} alt="" className='min-h-[100px] min-h-[100px] max-w-[100px] max-h-[100px]'/>}
                </div>
                <div>
                    <h2 className='px-8 text-xl font-bold flex items-center'>
                        {details.name}
                        <a href={details.url} target="_blank" rel='noreferrer' className='text-purple-500'><ExternalLink size={22} className='ml-2'/></a>
                    </h2>
                    <div className='px-8 pb-8'>
                        <p>{details.venue_name}, {details.venue_city} {details.venue_state}</p>
                        <div className='text-sm flex items-center gap-3 opacity-70'>
                            <div>Event date: {dayjs.unix(details.date_time).format('MM/DD/YYYY hh:mm A')}</div>
                            <div>Genre: {details.event_genre}</div>
                            <div>Segment: {details.event_segment}</div>
                        </div>
                        <div className='text-sm flex items-center gap-3 opacity-70'>
                            <div>Spotify Artist Name: {artistName}</div>
                        </div>
                    </div>
                </div>
            </div>
            
            {!isLoading && 
            <div className='tabs'>
                
                <div className='tabsNav'>
                    <button className={activeTab==='t1' ? 'active' : ''} onClick={()=>setActiveTab('t1')}>Graph</button>
                    <button className={activeTab==='t2' ? 'active' : ''} onClick={()=>setActiveTab('t2')}>Total counts</button>
                    <button className={activeTab==='t3' ? 'active' : ''} onClick={()=>setActiveTab('t3')}>Section counts</button>
                    <button className={activeTab==='t4' ? 'active' : ''} onClick={()=>setActiveTab('t4')}>Spotify</button>
                </div>

                {activeTab==='t1' && 
                <div className='tabContent p-8'>
                    {dataT1 && dataT1[0].length>0 && dataT1[1].length>0 && details.venue_capacity>0 ? 
                    <div className='w-full h-96'>
                        <ResponsiveLine
                            data={[
                                {
                                    "id": 'test',
                                    "data": dataT1[0].map((x,i) => { 
                                        x.y = dataT1[1][i].y
                                        return x
                                    })
                                }
                            ]}
                            margin={{ top: 10, right: 10, bottom: 70, left: 40 }}
                            colors={["rgb(168 85 247)"]}
                            xScale={{ 
                                type: 'time',
                                format: '%Y-%m-%dT%H:%M:%S%Z',
                                useUTC: false,
                                precision: 'minute',
                             }}
                            // xFormat="time:%Y-%m-%dT%H:%M:%S%Z"
                            yScale={{
                                type: 'linear',
                                min: 0,
                                max: _maxYaxis(dataT1, details.venue_capacity),
                                stacked: false,
                                reverse: false
                            }}
                            curve="monotoneX"
                            axisTop={null}
                            axisRight={null}
                            axisLeft={{}}
                            axisBottom={{
                                format: '%H:%M | %b %d',
                                tickValues: 'every 2 day',
                                tickRotation: -55
                            }}
                            enablePointLabel={false}
                            pointLabelYOffset={12}
                            tooltip={({point}) =>{ return( <span className='bg-white text-xxs p-2 shadow-md'>{dayjs(point.data.x).format('MM/DD/YYYY hh:mm')}: {point.data.y}</span>) }}
                            useMesh={true}
                            legends={[]}
                        />
                    </div>
                        : 
                        details.venue_capacity===0 && !isLoading ? <p className='text-center -my-2'>Unable to render graph. Venue capacity is currently not available.</p> : <p className='text-center -my-2'>There are no records to display</p>
                    }
                </div>
                }

                {activeTab==='t2' && 
                <div className='tabContent'>
                    <div className='w-full flex flex-col  flex-1 max-h-[700px]'>
                        <h3 className='pt-8 px-4 pb-2 text-xl font-bold'>Total counts</h3>
                        <DataTable
                            columns={columnsT2}
                            data={dataT2}
                            highlightOnHover
                            fixedHeader
                        />
                    </div>
                </div>
                }

                {activeTab==='t3' && 
                <div className='tabContent'>
                    <div className='flex flex-1 flex-row'>
                        <div className='w-1/5 flex flex-col h-full min-h-[700px] max-h-[700px] border-r-[1px] border-r-gray-100 overflow-y-auto bg-black bg-opacity-5'>
                            {
                                t3List.map((item,index)=>{
                                    return (
                                        <button 
                                            key={index}
                                            className={`p-3 px-4 w-full text-left border-b-[1px] border-b-gray-100 hover:bg-gray-50 hover:text-black ${dataT3selectedSections===item.id ? 'bg-purple-500 text-white' : ''}`}
                                            onClick={()=> setDataT3selectedSections(item.id)}
                                        >
                                            {item.name}
                                        </button>
                                    )
                                })
                            }
                        </div>
                        <div className='w-full flex flex-col flex-1 min-h-[700px] max-h-[700px] overflow-y-auto shadow-md'>
                            <div>
                            {Object.values(dataT3).length>0 && 
                                <DataTable
                                    columns={columnsT3}
                                    data={dataT3}
                                    highlightOnHover
                                    fixedHeader
                                />
                            }
                            {!isLoadingT3 && Object.values(dataT3).length===0 && dataT3selectedSections==='' && <p className='p-6'>Select section in the left sidebar to display data.</p>}
                            {!isLoadingT3 && Object.values(dataT3).length===0 && dataT3selectedSections !== '' && <p className='p-6'>There are no records to display</p>}
                            {isLoadingT3 &&
                                <div className='loading'>
                                    <span className="icon">
                                        <Loader size={20} className="animate-spin"/>
                                    </span>
                                    <span className='text'>Loading...</span>
                                </div>
                            }
                            </div>
                        </div>
                    </div>
                </div>
                }

                {activeTab==='t4' && 
                <div className='tabContent p-8'>
                    {dataT4 && dataT4.length && dataT4[0].length>0 && dataT4[1].length>0 && details.venue_capacity>0 ? 
                    <div className='w-full h-96'>
                        <ResponsiveLine
                            data={[
                                {
                                    "id": 'test',
                                    "data": dataT4[0].map((x,i) => { 
                                        x.y = dataT4[1][i].y
                                        return x
                                    })
                                }
                            ]}
                            margin={{ top: 10, right: 10, bottom: 70, left: 50 }}
                            colors={["rgb(168 85 247)"]}
                            xScale={{ 
                                type: 'time',
                                format: '%Y-%m-%dT%H:%M:%S%Z',
                                useUTC: false,
                                precision: 'minute',
                             }}
                            // xFormat="time:%Y-%m-%dT%H:%M:%S%Z"
                            yScale={{
                                type: 'linear',
                                min: _minYaxis2(dataT4),
                                max: _maxYaxis2(dataT4),
                                stacked: false,
                                reverse: false
                            }}
                            curve="monotoneX"
                            axisTop={null}
                            axisRight={null}
                            axisLeft={{}}
                            axisBottom={{
                                format: '%H:%M | %b %d',
                                tickValues: 'every 2 day',
                                tickRotation: -55
                            }}
                            enablePointLabel={false}
                            pointLabelYOffset={12}
                            tooltip={({point}) =>{ return( <span className='bg-white text-xxs p-2 shadow-md'>{dayjs(point.data.x).format('MM/DD/YYYY hh:mm')}: {point.data.y}</span>) }}
                            useMesh={true}
                            legends={[]}
                        />
                    </div>
                        : 
                        details.venue_capacity===0 && !isLoadingT4 ? <p className='text-center -my-2'>Unable to render graph. Venue capacity is currently not available.</p> : <p className='text-center -my-2'>There are no records to display</p>
                    }
                    {isLoadingT4 &&
                        <div className='loading'>
                            <span className="icon">
                                <Loader size={20} className="animate-spin"/>
                            </span>
                            <span className='text'>Loading...</span>
                        </div>
                    }
                </div>
                }

            </div>
            }

            {isLoading && 
                <div className='loading'>
                    <span className="icon">
                        <Loader size={20} className="animate-spin"/>
                    </span>
                    <span className='text'>Loading...</span>
                </div>
            }
        </Fragment>
    );
};
