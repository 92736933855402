import React, { Fragment } from 'react'
import { useField } from '@unform/core'


//
export default function InputText({name, label, className, ...props}) {

    const inputRef = React.useRef(null);
    const { fieldName, defaultValue, registerField, error, clearError } = useField(name)
  
    const handleChange = (e) => {
        // console.log('changed');
        if(props.onChange){
            props.onChange(e.target.value)
        }
    }

    React.useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef,
            getValue: ref => {
                return ref.current.value
            },
            setValue: (ref, value) => {
                ref.current.value = value
            },
            clearValue: ref => {
                ref.current.value = ''
            },
        })
    }, [fieldName, registerField])


    return (
        <Fragment>
            <div className="formGroup">
                {label && <label>{label}</label>}
                <input onChange={handleChange} value={props.presetValue ? props.presetValue : undefined} readOnly={props.test || !!props.presetValue} type="text" className={`bg-white h-10 w-full border-2 border-gray-100 px-3 rounded-sm text-sm outline-none focus:border-purple-500 font-light ${error ? 'border-red-500' : ''} ${className && className}`} ref={inputRef} defaultValue={defaultValue} onFocus={clearError} {...props}/>
                { error && <span className="text-xs text-red-500 py-1">{error}</span> }
            </div>
        </Fragment>
    );
};